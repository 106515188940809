import {getResizeData, getResizeDispatcher, ResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';

type Options = {
  // показывать ли хедер при скролле страницы в самом конце
  lastScreen?: boolean;
  // возвращаем ли мы видимость хедера при обратном скролле
  backScroll?: boolean;
  // блоки, при заходе на которые хедер перекрашивается
  repaint?: HTMLElement[];
}

type ChangeHandler = (isHidden: boolean, hasBg: boolean) => void;

export class HeaderHideInstance {
  private readonly _element: HTMLElement;
  private readonly _onChange: ChangeHandler;
  private readonly _options: Options;

  private _isVisible: boolean;
  private _hasBg: boolean;
  private _isHidden: boolean;

  private _activeRepaint: string;
  private _repaintBlocks: {
    el: HTMLElement;
    class: string;
    top: number;
    bottom: number;
    height: number;
  }[];

  private _backScrollHeight: number;
  private _lastScrolled: number;
  private _scrollPoint?: number;

  private _vw: number;
  private _vh: number;
  private _height: number;
  private _docHeight: number;

  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = () => this._scroll();
  private readonly _resizeDispatcher: ResizeDispatcher;

  constructor(element: HTMLElement, onChange: ChangeHandler = () => {
  }, options: Options = {}) {
    this._element = element;
    this._onChange = onChange;
    this._options = {
      lastScreen: false,
      backScroll: false,
      repaint: [],
      ...options
    }
    this._hasBg = false;
    this._isHidden = false;

    this._isVisible = true;

    this._activeRepaint = '';
    this._repaintBlocks = [];

    if (this._options.repaint) {
      // TODO: доделать repaint блоки
      // this._repaintBlocks = Array.from(document.documentElement.querySelectorAll<HTMLElement>('[data-headerRepaint]'))
      //   .map((el, i ) => ({
      //     el,
      //     class: el.dataset.headerrepaint as string,
      //     top: i,
      //     bottom: 1 + i,
      //     height: 1 + i
      //   }))
    }

    this._backScrollHeight = 0;
    this._lastScrolled = 0;

    this._vw = 1;
    this._vh = 1;
    this._height = 1;
    this._docHeight = 1;

    this._resizeDispatcher = getResizeDispatcher();
  }

  public init() {
    this._lastScrolled = window.scrollY;
    this._resizeDispatcher.addListener(this._onResize);
    window.addEventListener('scroll', this._onScroll);

    this._resize(getResizeData());
  }

  public destroy() {
    this._resizeDispatcher.removeListener(this._onResize);
    window.removeEventListener('scroll', this._onScroll);
  }

  private _resize({vw, vh}: ResizeEvent) {
    const scrolled = window.scrollY;

    this._vw = vw;
    this._vh = vh;
    this._docHeight = document.documentElement.scrollHeight;

    if (this._vw < 740) {
      this._backScrollHeight = 60;
    } else {
      this._backScrollHeight = 101;
    }

    if (this._options.repaint) {
      this._height = this._element.getBoundingClientRect().height;

      this._repaintBlocks.forEach(block => {
        block.top = block.el.getBoundingClientRect().top + scrolled;
        block.height = block.el.offsetHeight;
        block.bottom = block.top + block.height;
      })
    }

    this._onScroll()
  }


  private _scroll() {
    const HTML = document.documentElement;
    const scrolled = window.scrollY;

    this._hasBg = window.scrollY > 10;

    if (scrolled > this._lastScrolled) {
      if (!this._isVisible && scrolled < this._backScrollHeight ||
        scrolled < this._backScrollHeight ||
        (this._options.lastScreen && scrolled + this._vh + 50 >= this._docHeight)) {
        HTML.classList.remove('headerHidden');
        // this._isHidden = false;
        this._isVisible = true;
      } else if (this._isVisible && scrolled >= this._backScrollHeight) {
        HTML.classList.add('headerHidden');
        // this._isHidden = true;
        this._isVisible = false;
      }
      this._scrollPoint = undefined;
    } else {
      if (!this._scrollPoint) this._scrollPoint = scrolled;

      if ((this._options.backScroll && (!this._isVisible && (this._scrollPoint - scrolled >= this._backScrollHeight) || scrolled <= this._backScrollHeight)) ||
        scrolled < this._backScrollHeight) {
        HTML.classList.remove('headerHidden');
        //this._isHidden = false
        this._isVisible = true;
        // TODO: Переделать обратный скролл
        // } else if (this._isVisible && scrolled >= this._backScrollHeight) {
        //   HTML.classList.add('headerHidden');
        //   this._isVisible = false;
      }
    }
    this._onChange(!this._isVisible, this._hasBg);

    if (this._options.repaint) {
      let className = '';
      const stick = -this._height;

      this._repaintBlocks.forEach(block => {
        if (block.top + stick <= scrolled && block.bottom - stick > scrolled) {
          className = block.class;
        }
      });

      if (className !== this._activeRepaint) {
        if (this._activeRepaint) this._element.classList.remove(this._activeRepaint);
        if (className) this._element.classList.add(className);
        this._activeRepaint = className;
      }
    }

    this._lastScrolled = scrolled;
  }
}
