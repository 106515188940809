import React, {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import Link, {LinkProps} from 'next/link';
import {classNames} from 'a-utils';

export type NavLinkProps = {
  pathname: string[];
  className?: string;
  text: string;
  defaultName?: string;
  activeClassName?: string;
  onActiveChange?: (isActive: boolean) => void;
}

type Props = React.PropsWithChildren<LinkProps & NavLinkProps>;

const NavLinkName = ({pathname, children, text, defaultName, activeClassName = 'active', onActiveChange, className, ...props}: Props) => {
  const {asPath, isReady} = useRouter();
  const [currentClassName, setCurrentClassName] = useState(className);

  useEffect(() => {
    if (isReady) {
      let isActive = pathname.indexOf(text) !== -1;
      const activePathname = new URL(asPath, location.href).pathname;
      const pathArray = activePathname.split('/')

      if (pathArray.length === 2) {
        if (text === 'en' || text === 'EN') {
          isActive = true;
        }
      }

      const newClassName =
        isActive
          ? classNames(className, activeClassName)
          : className;

      onActiveChange && onActiveChange(isActive);

      if (newClassName !== currentClassName) {
        setCurrentClassName(newClassName);
      }
    }
  }, [text, defaultName, isReady, className, activeClassName, setCurrentClassName, currentClassName, onActiveChange, pathname, asPath]);

  return (
    <Link {...props} className={currentClassName}>
      {children}
    </Link>
  )
}

export default NavLinkName;
