import React, {useEffect, useRef, useState} from 'react';
import css from './ContactsMap.module.scss';
import Image from 'next/image';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import {JSInstance} from 'COMPONENTS/pages-components/contacts/ContactsMap/JSInstance';
import {classNames} from 'a-utils';
import Sticker from 'COMPONENTS/pages-components/contacts/ContactsMap/Sticker/Sticker';

const isTabletCheck = () => (window.innerWidth < 1280);

export const ContactsMap = () => {
  const blockRef = useRef<HTMLElement | null>(null);
  const [isTablet, setIsTablet] = useState(false);
  //const [progress, setProgress] = useState(0);
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const [shwStkr, setShwStkr] = useState(false);
  const [map, setMap] = useState<HTMLDivElement | null>(null);
  const [stickers, setStickers] = useState<HTMLDivElement | null>(null);


  const resize = () => {
    setIsTablet(isTabletCheck());
  }


  useGlobalDOMEvents({resize});


  useEffect(() => {
    resize();
  }, [])


  useEffect(() => {
    if (!blockRef.current || !map || !stickers) return;

    const instance = new JSInstance(blockRef.current, map, stickers);
    setInstanceJS(instance)
    instance.init();

    return () => {
      instance.destroy();
    };
  }, [map, stickers])


  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((showStickers, vw) => {
      setShwStkr(showStickers)
      setIsTablet(vw < 768)
    });
  }, [instanceJS]);

  return (
    <section className={css.block} ref={blockRef}>
      <div className={css.wrap}>
        <div className={classNames(css.map)}>
          {isTablet ?
            <Image
              width={1630}
              height={940}
              src={'/contacts/map-adaptive.svg'}
              alt={'map'}
            />
            :
            <div ref={setMap} className={classNames(css.image, 'map')}>
              <Image
                width={905}
                height={522}
                src={'/contacts/map-desktop.svg'}
                alt={'map'}
              />
            </div>
          }
        </div>

        <div className={css.stickerWrap}>
          <div ref={setStickers} className={classNames(css.stickers, 'stickers', shwStkr ? css.active : '')}>
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'lithuania'}
              isSmall={isTablet}
              direction={'left'}
            />
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'ukraine'}
              isSmall={isTablet}
              direction={'left'}
            />
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'poland'}
              isSmall={isTablet}
            />
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'montenegro'}
              isSmall={isTablet}
            />
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'georgia'}
              isSmall={isTablet}
              direction={'left'}
            />
            <Sticker
              className={classNames(css.sticker, 'js-sticker')}
              country={'cyprus'}
              isSmall={isTablet}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
