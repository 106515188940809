import {EventEmitter} from 'a-utils';

export type ResizeEvent = {
  vw: number;
  vh: number;
}

export const getResizeData = () => {
  return {
    vw: window.innerWidth,
    vh: window.innerHeight
  }
}

export class ResizeDispatcher extends EventEmitter<ResizeEvent> {
  private _vw = 0;
  private _vh = 0;
  private readonly _onResize = () => this._resize();

  constructor() {
    super();

    this._resize();
    window.addEventListener('resize', this._onResize);
  }
  private _resize() {
    const event = getResizeData();
    this.dispatch(event);
    const {vw, vh} = event;

    if (this._vw !== vw || this._vh !== vh) {
      document.documentElement.style.setProperty('--vw', `${vw}px`);
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    this._vw = vw;
    this._vh = vh;
  }
  public destroy() {
    window.addEventListener('resize', this._onResize);
  }
  public fire() {
    this._onResize();
  }
}


let dispatcher: ResizeDispatcher;

export const getResizeDispatcher = () => {
  if (!dispatcher) dispatcher = new ResizeDispatcher();
  return dispatcher;
}
