import React, { useState} from 'react';
import css from './DeleteAccountForm.module.scss';
import {classNames} from 'a-utils';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {Form} from 'COMPONENTS/ui/form/Form';
import {Input} from 'COMPONENTS/ui/form/input/Input';
import {Button} from 'COMPONENTS/ui/form/button/Button';
import {FormResponse, FormState} from 'COMPONENTS/ui/form/form-utils';
import {Select} from 'COMPONENTS/pages-components/common/Select/Select';


export type Props = {
  api: string;
  anchor?: string;

  fields: {
    email: string;
    select: {
      text: string,
      filterText: string,
      link: string,
    }[];
  }
  policy: {
    text: string,
    linkText:string,
  }
  sendText: string,
}


export const DeleteAccountForm = ({anchor = 'form', api, fields, policy, sendText}: Props) => {
  const [message, setMessage] = useState<string | null>(null);
  const [selectVal, setSelectVal] = useState(fields.select[0].link);


  const setResponseMessage = (title: string, sigh:string) => {
    const message = title + ' ' + sigh;
    setMessage(message);
  }

  const onSuccess = (response: FormResponse) => {
    if (response.status === 'success') {
      const title = (response.response as any).title as string;
      const sign = (response.response as any).sign as string;

      setResponseMessage(title, sign);
    }
  }

  const onError = (msg: string) => {
    setMessage(msg);
  }

  const onBeforeSubmit = (state: FormState) => {
    let gameLink = fields.select[0].link;

    fields.select.map((el) => {
      if (el.filterText === selectVal) {
        gameLink = el.link
      }
    })

    state.game = {
      value: gameLink,
      error: false,
      notice:false,
      required: false,
      changed: true,
    }

    // console.log(state)
    return state;
  }


  return (
    <div id={anchor}  className={css.deleteForm}>
      {message ?
        (<h4 className={css.formMessage}>{message}</h4>)
        :
        (<Form
          noRadio
          action={api}
          beforeSubmit={(state) =>  onBeforeSubmit(state)}
          onSuccess={(response) => onSuccess(response)}
          onError={(msg) => onError(msg)}
        >
          <Select
            className={css.select}
            regularSelect
            changeActiveFilterLink={(message) => {
              setSelectVal(message)
            }}
            selectItems={fields.select}
          />

          <Input
            name='email'
            type='email'
            label={fields.email}
            required
            noNotice
          />

          <div className={classNames(css.buttons)}>
            <Button
              type={'submit'}
              className={classNames(css.button, `button orange large`)}
            >
              <span className={'button-text'}>{sendText}</span>
            </Button>
            <span data-id='agreement' className={css.agreement}>
              <span>{policy.text}</span>
              <SimpleLink isExternal={true} href={'/privacy'} className={'link'}>{policy.linkText}</SimpleLink>
            </span>
          </div>

        </Form>)}
    </div>
  );
};
