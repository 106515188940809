import React, {useEffect, useState} from 'react';
import css from './NewsPage.module.scss';
import {NewsProps} from 'TYPES/types';
import {NewsCard} from 'COMPONENTS/pages-components/common/NewsCard/NewsCard';
import {classNames} from 'a-utils';
import {useRouter} from 'next/router';
import {getData} from '../../../pages/api/mocks/news/items';
import { motion } from 'framer-motion';

export type NewsPageProps = {
	title: string;
  items: NewsProps[],
  moreText?: string;
  isEnd?: boolean;
  url?: string;
};


export type CardRequest = {
  slug: string[];
  page: number;
}

export type CardResponse = {
  moreText?: string;
  isEnd?: boolean;
  items?: NewsProps[];
}

export const NewsPage = ({ title, items=[],  isEnd, url, moreText}: NewsPageProps) => {
  const {query: {slug}} = useRouter();
  const [currentItems, setCurrentItems] = useState<NewsProps[]>(items);
  const [page, setPage] = useState(2);
  const [isEndCurrent, setInEndCurrent] = useState(isEnd);
  const [isLoading, setLoading] = useState(false);
  const [textOnLoad, setTextOnLoad] = useState('');

  useEffect(() => {
    setCurrentItems(items);
    setInEndCurrent(isEnd);
    setPage(2);
    if (moreText) {
      setTextOnLoad(moreText);
    }
  }, [items, isEnd]);


  const onClick = () => {
    if (!url || !slug || !Array.isArray(slug) || isLoading) return;
    setLoading(true);

    /*
    const searchString = mapObjectToParamsString<CardRequest>({
      page,
      slug
    });
     */
    const searchString = `?page=${page}`

    getData(url + searchString)
      .then((data) => {
        setLoading(false);
        setPage(old => old + 1);
        setInEndCurrent(data.isEnd);
        if (data.moreText) {
          setTextOnLoad(data.moreText);
        }
        setCurrentItems(items => {
          return [...items, ...(data.items || [])];
        });
      })
      .catch((e) => {
        console.warn(e);
        setLoading(false);
      });
  }


  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.main)}
    >
      <div className={'container'}>
        <h1 className={css.title}>{title}</h1>

        <div className={css.list}>
          {currentItems.map((newsItem, index) => {
            return <NewsCard  key={`news-card-${index}`} hasText={index % 3 === 0} {...newsItem}/>
          })}
        </div>

        {!isEndCurrent &&
          <div className={classNames(css.loadMoreButton, 'more')}>
            <button disabled={isLoading}
              className={classNames('button orange large', isLoading && 'loading')}
              onClick={onClick}>
              <span className={'button-text'} dangerouslySetInnerHTML={{__html: textOnLoad ? textOnLoad : 'load more'}}/>
            </button>
          </div>
        }
      </div>
    </motion.section>
  );
};
