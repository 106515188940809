import React from 'react';
import css from './StuffSlider.module.scss'
import {RegularSlider, RegularSliderProps} from 'COMPONENTS/pages-components/common/RegularSlider/RegularSlider';

export type GamesOtherProps = {
  title?: string;
  slider: RegularSliderProps;
}

export const StuffSlider = ({title, slider}: GamesOtherProps) => {
  return (
    <section className={css.main}>
      <div className={'container'}>
        {title && <h1 className={css.title} dangerouslySetInnerHTML={{__html:title}}/>}
        <RegularSlider arrows={true} className={css.slider} {...slider}/>
      </div>
    </section>
  )
}
