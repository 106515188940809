import React from 'react';
import css from './GamesOther.module.scss'
import { RegularSlider, RegularSliderProps } from 'COMPONENTS/pages-components/common/RegularSlider/RegularSlider';

export type GamesOtherProps = {
    title?: string;
    slider: RegularSliderProps;
}

export const GamesOther = ({title, slider}: GamesOtherProps) => {

  return (
    <section className={css.main}>
      <div className={'container'}>
        {title && <h2 className={css.title} dangerouslySetInnerHTML={{__html:title}}/>}
        <RegularSlider arrows={false} className={css.slider} {...slider}/>
      </div>
    </section>
  )
}
