import {getPerformance} from 'COMPONENTS/ui/move/move';
import {Performance} from 'a-utils';
import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';
import {getScrollDispatcher, ScrollEvent} from 'COMPONENTS/ui/scroll/ScrollDispatcher';

export type OnChange = (showStickers: boolean, vw: number) => void;

export class JSInstance {
  private _onChange: OnChange;
  private readonly _performance: Performance;
  private _canAnimate = false;
  private _showStickers = false;

  private readonly element: HTMLElement;
  private top: number;
  private bottom: number;
  private vw: number;
  private vh: number;
  private readonly afterScrollLength: number;
  private active: boolean;
  private end: boolean;
  private progressPercent: number;
  private readonly mapWrap: HTMLDivElement;
  private readonly stickerWrap: HTMLDivElement;

  private readonly animateHandler: (shift: number) => void;
  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _scrollDispatcher = getScrollDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = (e: ScrollEvent) => this._scroll(e);

  constructor(element: HTMLElement, map: HTMLDivElement, stickers: HTMLDivElement, onChange: OnChange = () => {
  }) {
    this._performance = getPerformance();
    this._onChange = onChange;

    this.element = element;
    this.top = 0;
    this.bottom = 0;
    this.vw = 0;
    this.vh = 0;
    this.active = false;
    this.end = false;
    this.progressPercent = 0;
    this.afterScrollLength = 300;
    this.mapWrap = map;
    this.stickerWrap = stickers;

    this.animateHandler = () => this._onAnimate();
  }

  init() {
    this._performance.addListener(this.animateHandler);
    this._resizeDispatcher.addListener(this._onResize);
    this._scrollDispatcher.addListener(this._onScroll);

    this._resizeDispatcher.fire();
  }

  destroy() {
    this._performance.removeListener(this.animateHandler);
    this._resizeDispatcher.removeListener(this._onResize);
    this._scrollDispatcher.removeListener(this._onScroll);
  }


  private _resize({vw, vh}: ResizeEvent) {
    this.vw = vw;
    this.vh = vh;

    //чтобы не запускать анимацию на мобилке-планшете
    this._canAnimate = this.vw > 1280;

    this.top = this.element.getBoundingClientRect().top + window.scrollY;
    this.bottom = this.top + this.element.getBoundingClientRect().height - this.vh;

    this._onChange(this._showStickers, this.vw);
  }

  private _scroll({offsetTop}: ScrollEvent) {
    if (this._canAnimate) {

      this.progressPercent = (offsetTop - this.top) / (this.bottom - this.top - this.afterScrollLength);

      if (this.progressPercent < 0) this.progressPercent = 0;
      if (this.progressPercent > 1) this.progressPercent = 1;

      this._showStickers = this.progressPercent > 0.35
      this._onChange(this._showStickers, this.vw);
    }
  }


  private _onAnimate() {
    if (this._canAnimate) {
      const progress = this.progressPercent;

      // мапу (подложку) не просто скейлим, а двигаем еще
      this.mapWrap.style.transform = `translate3d(${-380 * progress}px, ${450 * progress}px, 0.1px) scale(${4.5 * progress + 1})`;

      // стикеры тоже двигаем при скролле
      this.stickerWrap.style.transform = `translate3d(-${42 + (progress * 43)}%, -${66 - progress * 11}%, 0.2px) scale(${0.2 + progress / 1.25})`;
    } else {
      this.mapWrap.style.transform = ``;
      this.stickerWrap.style.transform = ``;
    }
  }

  set onChange(func: OnChange) {
    this._onChange = func;
  }

  private _change(showStickers: boolean, vw: number) {
    this._onChange(showStickers, vw);
  }
}
