import React, {useState} from 'react';
import {FormWrapper} from 'COMPONENTS/pages-components/common/FormWrapper/FormWrapper';
import css from 'COMPONENTS/pages-components/common/VacancyForm/VacancyForm.module.scss';
import {classNames} from 'a-utils';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {Form} from 'COMPONENTS/ui/form/Form';
import {GetInTouchImg, RadioItems} from 'TYPES/types';
import {Input} from 'COMPONENTS/ui/form/input/Input';
import {Radio} from 'COMPONENTS/ui/form/radio/Radio';
import {Button} from 'COMPONENTS/ui/form/button/Button';
import {FormResponse, FormState} from 'COMPONENTS/ui/form/form-utils';
import {InputFile} from 'COMPONENTS/ui/form/file/File';
import {ErrorModal} from 'COMPONENTS/pages-components/common/ErrorModal/ErrorModal';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';

export type Props = {
  api: string;
  title?: string;
  fields?: {
    name?: string;
    email?: string;
    phone?: string;
    textarea?: string;
    file?: string;
  }
  vacancyId?: string;
  radio?: RadioItems;
  imagePath?: GetInTouchImg;
  type?:string;
}

export const VacancyForm = ({api, title, fields, radio, imagePath, vacancyId, type}: Props) => {
  const [successMessage, showSuccessMessage] = useState(false);
  const [errorMessage, showErrorMessage] = useState(false);


  const onSuccess = (response: FormResponse) => {
    if (response.status === 'success') {
      showSuccessMessage(true);
    }
  }

  const onError = () => {
    showErrorMessage(true);
  }

  const onBeforeSubmit = (state: FormState) => {
    return state;
  }

  // TODO сделать пересчет высоты плавненько
  if (successMessage) {
    return (
      <FormWrapper
        title={`Thanks! Your application has been sent`}
        modifier={`success`}
      >
        <p>We will definitely contact you as soon as possible</p>
      </FormWrapper>
    )
  }

  if (errorMessage) {
    return (
      <ErrorModal/>
    )
  }

  return (
    <>
      <FormWrapper
        title={title}
        modifier={'vacancy'}
        type={type}
        imagePath={imagePath}
      >
        <Form
          id={vacancyId}
          action={api}
          beforeSubmit={(state) => onBeforeSubmit(state)}
          onSuccess={(response) => onSuccess(response)}
          onError={onError}
          className={imagePath ? css.withImage : css.noImage}
        >
          <div className={css.formItems}>
            <div className={classNames(css.columns, imagePath ? css.columns2 : css.columns3)}>
              <div className={css.col}>
                <Input
                  name='name'
                  label={fields?.name ? fields.name : ''}
                  required
                />
                <Input
                  name='email'
                  type='email'
                  label={fields?.email ? fields.email : ''}
                  required
                />
                <Input
                  name='phone'
                  type='tel'
                  label={fields?.phone ? fields.phone : ''}
                />
              </div>
              <div className={css.col}>
                <Input
                  name='text'
                  type='textarea'
                  label={fields?.textarea ? fields.textarea : ''}
                />
                <InputFile
                  name='file'
                  label={fields?.file ? fields.file : ''}
                />
              </div>

              {radio && <div className={classNames(css.col, css.colDouble)}>
                <div>
                  <h5 className={css.subtitle}>Where did you hear about this vacancy?</h5>
                  <Radio name="radio" items={radio}/>
                </div>
              </div>}
            </div>
            <div className={classNames(css.buttons, imagePath ? '' : css.smallBtn)}>
              <Button
                type={'submit'}
                className={classNames(css.button, `button orange large`)}
              >
                <span className={'button-text'}>submit</span>
              </Button>
              <span className={css.agreement}>
                <span>By pressing the button, I agree to the </span>
                <SimpleLink isExternal={true} href={'/privacy'} className={'link'}>Privacy Policy</SimpleLink>
              </span>
            </div>
          </div>
          {imagePath && <div className={classNames(css.image)}>
            <SquirrelVideo hide={1280} className={css.squirrelImg} name={imagePath}/>
          </div>}
        </Form>
      </FormWrapper>
    </>
  );
};
