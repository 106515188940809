import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {SlideProps, NewsProps, SliderType} from 'TYPES/types';
import {classNames} from 'a-utils';
import css from './RegularSlider.module.scss';
import {Navigation} from 'swiper';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {Slide} from 'COMPONENTS/pages-components/common/RegularSlider/GameSlide/Slide';
import {JSInstance} from './JSInstance';
import useIsTouch from 'HOOKS/useIsTouch';

export type RegularSliderProps = {
  slides: SlideProps[] | NewsProps[];
  slideType: SliderType;
  className?: string;
  arrows?: boolean;
};


export const RegularSlider = ({slides, slideType, className, arrows}: RegularSliderProps) => {
  const [col, setCol] = useState(1);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const isTouch = useIsTouch();


  useEffect(() => {
    if (window.innerWidth > 768) {
      setCol(2);

      if (slideType === 'screens') {
        setCol(1);
      }
    } else {
      setCol(1);
    }

    const instance = new JSInstance();
    setInstanceJS(instance)
    instance.init();

    return () => {
      instance.destroy();
    };
  }, [slideType]);

  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw) => {
      if (vw > 768) {
        setCol(2);

        if (slideType === 'screens') {
          setCol(1);
        }
      } else {
        setCol(1);
      }
    });
  }, [instanceJS, slideType]);

  return (
    <div className={css.wrap}>
      <Swiper
        grabCursor={true}
        className={classNames(className, css.slider, css[slideType])}
        centeredSlides={slideType === 'screens'}
        loop={slides.length > 2 }
        loopAdditionalSlides={6}
        spaceBetween={8}
        slidesPerView={col}
        modules={[Navigation]}
        threshold={0.6}
        speed = {600}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
      >
        {slides.map((slideItem, index) => {
          return <SwiperSlide key={`game-slide-${index}`}>
            <Slide parent={slideType} {...slideItem}/>
          </SwiperSlide>
        })}
      </Swiper>

      <div className={classNames(css.nav, 'more')} style={ { display:  slides.length <= 2 ? 'none' : 'flex'}}>
        <button className={classNames(css.button, css.prev, !isTouch && arrows && css.large)} ref={navigationPrevRef}>
          <span className={'button-nav button-nav--transparent-orange'}>
            <Icon id={'arrow-left'}/>
          </span>
        </button>
        <button className={classNames(css.button, css.next, !isTouch && arrows && css.large)} ref={navigationNextRef}>
          <span className={'button-nav button-nav--transparent-orange'}>
            <Icon id={'arrow-left'}/>
          </span>
        </button>
      </div>
    </div>

  );
};

