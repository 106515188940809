import React, {useEffect, useRef, useState} from 'react';
import css from './FirstAnimation.module.scss';
import Image, {ImageProps} from 'next/image';
import {JustLink, Video} from 'TYPES/types';
import {classNames} from 'a-utils';
import {Button} from 'COMPONENTS/pages-components/common/Button/Button';
import {JSInstance} from './JSInstance';
import VideoPopup from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';

export type animationProps = {
  firstSection: FirstScreenType;
  secondSection: SecondScreenType;
  video?: Video;
};

type FirstScreenType = {
  preview?: ImageProps,
  text?: string,
  button?: JustLink,
  small: string,
};

type SecondScreenType = {
  button?: JustLink,
  images: ImageProps[];
}

export const FirstAnimation = ({firstSection, secondSection, video}: animationProps) => {
  //нужно чтобы показать какой первый экран корректный
  const [width, setWidth] = useState(320);
  const [height, setHeight] = useState(700);
  const [secondScreenActive, setSecondScreenActive] = useState(false);
  //вся анимация внутри
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const blockRef = useRef<HTMLDivElement | null>(null)

  const [showFirst, setSetShowFirst] = useState(false)
  const [hideAll, setHideAll] = useState(false)
  const [image, setImage] = useState<HTMLButtonElement | null>(null)

  const [playVideo, setPlayVideo] = useState(false)



  useEffect(() => {
    window.scrollTo(0, 0);
    if (!blockRef.current) return;

    setTimeout(() => {
      window.scrollTo(0, 0);
      setSetShowFirst(true)
    }, 30)
  }, [])

  useEffect(() => {
    if (!image || !blockRef.current) return ;

    const instance = new JSInstance(image, blockRef.current);
    setInstanceJS(instance)
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)

    instance.init();

    return () => {
      instance.destroy();
    };
  }, [image]);

  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw, vh, secondScreen, hideAll) => {
      setWidth(vw)
      setHeight(vh)
      setSecondScreenActive(secondScreen)

      if (hideAll !== undefined) setHideAll(hideAll)
    });
  }, [instanceJS]);


  const imageBlock = video && firstSection.preview ?
    <button ref={setImage}  onClick={() => {
      setPlayVideo(!playVideo)
    }} className={classNames(css.squirrel, css.clickable)}>
      <Image
        className={css.squirrelAnimation}
        width={170}
        height={170}
        alt={''}
        src={'/main-page/playReel.svg'}
        priority={true}
        quality={90}/>
      {firstSection.preview.src && <Image
        className={css.squirrelPrev}
        {...firstSection.preview}
        alt={'video'}
        priority={true}
        quality={90}/>}
      <SquirrelVideo className={css.squirrelImg} name={'wink'}/>
    </button>
    :
    <button ref={setImage} className={classNames(css.squirrel, css.notClickable)}>
      <Image
        className={css.squirrelAnimation}
        width={170}
        height={170}
        alt={''}
        src={'/main-page/playReel.svg'}
        priority={true}
        quality={90}/>
      <SquirrelVideo className={css.squirrelImg} name={'wink'}/>
    </button>


  return (<>
    <div ref={blockRef} className={classNames(css.root, hideAll ? css.hide : '')}>

      {/* так как круги могут быть меньше экрана,
      включаем оранжевую подложку как подстраховку */}
      <div  className={classNames(css.bg, secondScreenActive ? css.active : '')}/>

      <section className={classNames(css.first,
        secondScreenActive ? '' : css.active,
        showFirst ? css.show : '')}>
        {/* в visually-hidden чистый заголовок, который будут забирать поисковики */}
        <h1 className='visually-hidden'>We create games you fall in love with</h1>
        <div className={css.wrap}>
          <div className={classNames(css.circles, css.filled)}/>
          <div className={classNames('container', css.container)}>
            <div className={classNames(css.title)}>
              {
                width < 768 &&
                  <>
                    {imageBlock}
                    <p className={classNames(css.text)}>
                      We&nbsp;create games you fall in&nbsp;love with
                    </p>
                  </>
              }
              {
                (width >= 768 && width < 1280) &&
                  <>
                    <p className={classNames(css.text)}>
                      <span>We create</span>
                      {imageBlock}
                    </p>
                    <p className={classNames(css.text)}>
                      <span>games you fall in&nbsp;love with</span>
                    </p>
                  </>
              }

              {
                (width >= 1280) &&
                  <>
                    <p className={classNames(css.text)}>
                      <span>We create</span>
                      {imageBlock}
                      <span>games</span>
                    </p>
                    <p className={classNames(css.text)}>
                      <span>you fall in love with</span>
                    </p>
                  </>
              }
            </div>

            <div className={classNames(css.aside)}>
              {firstSection.text && <p className={classNames('hand', css.hand)} dangerouslySetInnerHTML={{__html: firstSection.text}}/>}
              {firstSection.button && <Button className={css.btn} text={firstSection.button.text} link={firstSection.button.link}/>}
            </div>
          </div>
          <div className={classNames(css.small, 'container container--large')}>
            <small>{firstSection.small}</small>
          </div>
        </div>
      </section>


      {/* высота секции зависит от количества картинок. Больше картинок = длиннее скролл */}
      <section style={{
        height: `${(secondSection.images.length * 30) + 140}vh`
      }} className={classNames(css.second, secondScreenActive ? css.active : '')}>
        <h2 className='visually-hidden'>We are always looking for new adventures</h2>
        <div className={classNames(css.sticky)}>
          <div className={'container'}>
            <div className={css.outlineCircles}>
              <div/>
            </div>
            <div className={classNames(css.titleSecond)}>
              {
                width < 768 &&
                  <>
                    <span>We are</span>
                    <span>always looking</span>
                    <span>for new adventu&shy;res</span>
                  </>
              }
              {
                (width >= 768 && width < 1280) &&
                  <>
                    <span>We are always</span>
                    <span>looking</span>
                    <span>for new</span>
                    <span>adventu&shy;res</span>
                  </>
              }
              {
                (width >= 1280) &&
                  <>
                    <span>We are always</span>
                    <span>looking for</span>
                    <span>new adventures</span>
                  </>
              }
            </div>

            {secondSection.button && <div className={classNames(css.more, 'more')}>
              <Button text={secondSection.button.text} link={secondSection.button.link} color={'white'}/>
            </div>}

            <div className={css.parallaxWrap}>
              {
                secondSection.images.map((img, i) => {
                  return <div key={`games-${i}`} className={classNames(css.parallax)}>
                    <div className={css.image}>
                      {img.src && <Image {...img}
                        alt={img.alt}
                        quality={90}
                      />}
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </div>
      </section>
    </div>
    {video && <VideoPopup active={playVideo} video={video} onClose={() => setPlayVideo(false)}/>}
  </>
  );
};
