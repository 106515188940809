import React, {useRef, useState} from 'react';
import {NavigationLink} from 'TYPES/types';
import {classNames} from 'a-utils';
import css from './NavItem.module.scss';
import DropList from 'COMPONENTS/pages-components/common/header/Droplist/DropList';
import NavLink from 'COMPONENTS/ui/NavLink/NavLink';


const NavItem = ({link, text, dropdown}: NavigationLink) => {
  const [hovered, setHovered] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  const mouseLeaveHandler = () => {
    if (dropdown) {
      timerRef.current = setTimeout(() => setHovered(false), 200);
    } else {
      setHovered(false);
    }
  }

  const mouseEnterHandler = () => {
    if (dropdown) clearTimeout(timerRef.current);

    setHovered(true);
  }

  return (
    <li
      className={classNames(
        css.li,
        hovered && css.hovered
      )}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <div className={classNames(css.item, dropdown && css.itemDropdown)}>
        <NavLink href={link} className={classNames(css.link, 'link', 'link-white')}><span>{text}</span></NavLink>
        {/* <SimpleLink href={link} className={classNames(css.link, 'link', 'link-white')}><span>{text}</span></SimpleLink> */}
      </div>
      {dropdown && <DropList list={dropdown} isActive={hovered}/>}
    </li>
  );
};

export default NavItem;
