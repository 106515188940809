import React, { useRef} from 'react';
import {GetInTouchImg, JustLink} from 'TYPES/types';
import css from './GetInTouch.module.scss'
import {Button} from 'COMPONENTS/pages-components/common/Button/Button';
import {classNames} from 'a-utils';
import {togglePopup} from 'STORE/slices/Options';
import {useDispatch} from 'STORE/index';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';


export type getInTouchProps = {
  title: string;
  text: string;
  button: JustLink;
  lottiePath?: string;
  buttonIsLink?: boolean;
  imagePath?: GetInTouchImg;
};

export const GetInTouch = ({title, text, button, imagePath, buttonIsLink = true}: getInTouchProps) => {
  // штука для анимации появления
  const blockRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch();

  return (
    <section ref={blockRef} className={classNames(css.main)}>
      <div className={'container'}>
        <div className={css.wrap}>
          <div className={css.text}>
            <h3 className={css.title} dangerouslySetInnerHTML={{__html: title}}/>
            <p className={css.subtext} dangerouslySetInnerHTML={{__html: text}}/>
            {buttonIsLink ?
              <Button isExternal={true} className={css.btn} text={button.text} link={button.link}/>
              :
              <button
                className={classNames(css.btn, 'button', 'orange', 'large')}
                onClick={() => dispatch(togglePopup(true))}
              >
                <span className={'button-text'}>{button.text}</span>
              </button>
            }
          </div>
          <div className={css.image}>
            {imagePath &&
              <SquirrelVideo className={css.video} name={imagePath}/>}
          </div>
        </div>
      </div>
    </section>
  );
};
