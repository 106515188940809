import React from 'react';
import css from './DetailedTable.module.scss'
import {classNames} from 'a-utils';
import {Primitives, PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';

export type Props = {
  primitives: PrimitivesProps;
  anchor?: string;
}

export const DetailedTable = ({primitives, anchor}: Props) => {
  return <div id={anchor ? anchor : ''} className={classNames(css.block, 'container')}>
    <div className={css.tableWrap}>
      <Primitives className={classNames(css.primitive, 'primitive')} {...primitives}/>
    </div>
  </div>
};
