import React, {HTMLAttributes} from 'react';
import Image from 'next/image';
import css from './Sticker.module.scss';
import {classNames} from 'a-utils';

export type Props = HTMLAttributes<HTMLDivElement> & {
  country: 'lithuania' | 'poland' | 'ukraine' | 'montenegro' | 'georgia' | 'cyprus',
  isSmall?: boolean;
  direction?: 'right' | 'left';
}

const Sticker = ({className, country, isSmall, direction = 'right'}: Props) => {

  if (country === 'lithuania') {
    return (
      <div className={classNames(className, css.sticker, css.orange, css[direction], css[country])}>
        <div className={css.icon}>
          {isSmall ?
            <Image width={84} height={85} src={'/contacts/sticker1-small.svg'} alt={country}/>
            :
            <>
              <Image className={css.outer} width={124} height={124} src={'/contacts/sticker1-outer.svg'} alt={country}/>
              <Image data-item='inner' className={css.inner} width={124} height={124} src={'/contacts/sticker1-inner.svg'} alt={country}/>
            </>
          }
        </div>
        <div data-item='sign' className={css.sign}>
          <span>{country}</span>
        </div>
      </div>
    )
  }

  if (country === 'poland') {
    return (
      <div className={classNames(className, css.sticker, css.purple, css[direction], css[country])}>
      </div>
    )
  }

  if (country === 'ukraine') {
    return (
      <div className={classNames(className, css.sticker, css.yellow, css[direction], css[country])}>
        <div className={css.icon}>
          {isSmall ?
            <Image width={65} height={65} src={'/contacts/sticker3-small.svg'} alt={country}/>
            :
            <>
              <Image className={css.outer} width={94} height={95} src={'/contacts/sticker3-outer.svg'} alt={country}/>
              <Image data-item='inner' className={css.inner} width={94} height={95} src={'/contacts/sticker3-inner.svg'} alt={country}/>
            </>
          }
        </div>
        <div data-item='sign' className={css.sign}>
          <span>{country}</span>
        </div>
      </div>
    )
  }

  if (country === 'montenegro') {
    return (
      <div className={classNames(className, css.sticker, css.black, css[direction], css[country])}>
        <div className={css.icon}>
          {isSmall ?
            <Image width={99} height={97} src={'/contacts/sticker4-small.svg'} alt={country}/>
            :
            <>
              <Image className={css.outer} width={145} height={143} src={'/contacts/sticker4-outer.svg'} alt={country}/>
              <Image data-item='inner' className={css.inner} width={145} height={143} src={'/contacts/sticker4-inner.svg'} alt={country}/>
            </>
          }
        </div>
        <div data-item='sign' className={css.sign}>
          <span>{country}</span>
        </div>
      </div>
    )
  }

  if (country === 'georgia') {
    return (
      <div className={classNames(className, css.sticker, css.orange, css[direction], css[country])}>
        <div className={css.icon}>
          {isSmall ?
            <Image width={66} height={61} src={'/contacts/sticker5-small.svg'} alt={country}/>
            :
            <>
              <Image className={css.outer} width={98} height={90} src={'/contacts/sticker5-outer.svg'} alt={country}/>
              <Image data-item='inner' className={css.inner} width={98} height={90} src={'/contacts/sticker5-inner.svg'} alt={country}/>
            </>
          }
        </div>
        <div data-item='sign' className={css.sign}>
          <span>{country}</span>
        </div>
      </div>
    )
  }

  if (country === 'cyprus') {
    return (
      <div className={classNames(className, css.sticker, css.green, css[direction], css[country])}>
        <div className={css.icon}>
          {isSmall ?
            <Image width={76} height={76} src={'/contacts/sticker6-small.svg'} alt={country}/>
            :
            <>
              <Image className={css.outer} width={112} height={112} src={'/contacts/sticker6-outer.svg'} alt={country}/>
              <Image data-item='inner' className={css.inner} width={112} height={112} src={'/contacts/sticker6-inner.svg'} alt={country}/>
            </>
          }
        </div>
        <div data-item='sign' className={css.sign}>
          <span>{country}</span>
        </div>
      </div>
    )
  }

  return null;

};

export default Sticker;
