import React, {useEffect, useRef} from 'react';
import {VideoProps} from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup';
import useVideoPlayer from 'HOOKS/useVideoPlayer';
import {useEscape} from 'HOOKS/useEscape';
import {Popup} from 'COMPONENTS/ui/popup/Popup';
import css from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup.module.scss';
import {classNames} from 'a-utils';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import useVideoFormat from 'HOOKS/useVideoFormat';

export const VideoPopupFile = ({active = false, video, onClose}: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const timelineRef = useRef<HTMLDivElement | null>(null);
  const isNotWebp:boolean = useVideoFormat();

  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
  } = useVideoPlayer(videoRef);

  const onTimelineClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const clickedX = e.clientX;

    if (target) {
      const x0 = target.getBoundingClientRect().x;
      const x1 = target.getBoundingClientRect().width + x0;
      const width = x1 - x0;
      const targetProgress = Math.ceil((clickedX - x0) / width * 100);

      handleVideoProgress(targetProgress);
    }
  }

  const onCloseHandler = () => {
    if (playerState.isPlaying) togglePlay(false);
    if (onClose) onClose();
  }

  useEffect(() => {
    if (active) {
      togglePlay(true);
    }

  }, [active, togglePlay])

  useEscape(true, () => onCloseHandler());

  return (
    <Popup active={active} videoPopup>
      <div className={css.block}>
        <div className={classNames(css.wrapper)}>
          {video && video.mov && video.webm &&
            <video
              ref={videoRef}
              className={css.video}
              muted={playerState.isMuted}
              //preload={'auto'}
              onTimeUpdate={handleOnTimeUpdate}
              onClick={() => togglePlay(!playerState.isPlaying)}
              //controls={true}
              src={isNotWebp ? video.mov : video.webm}
              playsInline={true}
              preload='metadata'
            />
          }
          {video && !video.mov && video.webm &&
            <video
              ref={videoRef}
              className={css.video}
              muted={playerState.isMuted}
              //preload={'auto'}
              onTimeUpdate={handleOnTimeUpdate}
              onClick={() => togglePlay(!playerState.isPlaying)}
              //controls={true}
              src={video.webm}
              playsInline={true}
              preload='metadata'
            />
          }

          <div className={css.panel}>
            <div className={css.tools}>
              <button className={css.playbutton} onClick={() => togglePlay(!playerState.isPlaying)}>
                {playerState.isPlaying ?
                  <Icon className={css.pause} id={'pause'}/>
                  :
                  <Icon className={css.play} id={'play'}/>}
              </button>

              <button className={classNames(css.sound, playerState.isMuted && css.off)} onClick={toggleMute}>
                {playerState.isMuted ? <Icon id={'sound-off'}/> : <Icon id={'sound'}/>}
              </button>
              <div ref={timelineRef} className={css.timeline}
                onClick={(e) => onTimelineClick(e)}>
                <div className={css.progressbar} style={{transform: `scaleX(${playerState.progress / 100})`}}></div>
              </div>
              <button className={css.cross} onClick={() => onCloseHandler()}>
                <Icon id={'cross'}/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};
