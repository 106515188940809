import {MoveClass, Performance as PerformanceClass} from 'a-utils';
export {MoveEvent} from 'a-utils'


let Performance: PerformanceClass;
let Move: MoveClass;

// for next.js
export const getPerformance = (): PerformanceClass => {
  if (!Performance) {
    Performance = new PerformanceClass();
  }
  // TODO: какая-то фигня, без этого не работает
  Performance.disable();
  Performance.enable();
  return Performance;
}
export const getMove = (): MoveClass => {
  if (!Move) {
    Move = new MoveClass(getPerformance());
  }
  return Move;
}