import React from 'react';
import {classNames} from 'a-utils';
import css from './FormWrapper.module.scss';

export type Props = React.PropsWithChildren & {
  title?: string;
  modifier?: string;
  type?: string;
  className?: string;
  imagePath?: string;
};

export const FormWrapper = ({children, title,  modifier, type, className}: Props) => {
  return (
    <div id={'form'} className={classNames(css.block, modifier && css[modifier], type && css[type], className,)}>
      <div className={css.wrap}>
        {title && <h3 className={css.title}>{title}</h3>}
        {children && <>{children}</>}
      </div>
    </div>
  );
};
