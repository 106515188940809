import React, { useEffect, useState} from 'react';
import css from './Select.module.scss';
import NavLinkName from 'COMPONENTS/ui/NavLink/NavLinkName';
import {filterLink} from 'COMPONENTS/pages-components/vacancy-page/VacancyHead/VacancyHead';
import {classNames} from 'a-utils';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import useIsTouch from 'HOOKS/useIsTouch';
import {useRouter} from 'next/router';


export type mainNewsProps = {
  selectItems: filterLink[],
  changeActiveFilterLink?: (message: string) => void;
  reset?: boolean;
  className?: string;
  regularSelect?: boolean;
}

export const Select = ({ selectItems, changeActiveFilterLink, reset, regularSelect = false, className }: mainNewsProps) => {
  const router = useRouter();
  const {asPath, isReady} = useRouter();
  const [activeHead, setActiveHead] = useState(selectItems[0].text)
  const touch = useIsTouch();
  const [pathnameArray, setPathnameArray] = useState<string[]>([])
  const [defaultVal, setDefaultVal] = useState(selectItems[0].link)


  //передаем родителю что активный пункт поменялся
  useEffect(() => {
    if (!changeActiveFilterLink) return;

    changeActiveFilterLink(activeHead)
  }, [activeHead, changeActiveFilterLink])

  useEffect(() => {
    if (isReady) {
      const activePathname = new URL(asPath, location.href).pathname;
      const pathArray = activePathname.split('/')
      setPathnameArray(pathArray)

      if (pathArray.length === 2) {
        selectItems.forEach(select => {
          if (select.text === 'English') {
            setDefaultVal(select.link)
            setActiveHead(select.text);
          }
        })
      } else {
        selectItems.forEach(select => {
          if (pathArray.indexOf(select.filterText) !== -1) {
            setDefaultVal(select.link)
            setActiveHead(select.text);
          }
        })
      }
    }
  }, [asPath, isReady, selectItems])

  useEffect(() => {
    if (reset && touch) {
      setActiveHead(selectItems[0].text)
    }
  }, [reset, selectItems, touch])

  const options = selectItems.map((select) => {
    return <option
      key={`select-${select.filterText}`}
      value={select.link}>
      {select.text}
    </option>
  });


  return (
    (!touch || regularSelect)
      ? <div className={classNames(css.drop, className)}>
        <button className={css.head}>
          {activeHead}
          <Icon className={css.icon} id={'triangle-down'} />
        </button>
        <div className={classNames(css.body )}>
          <div className={css.wrap}>

            {selectItems.map((select, index) => {
              if (regularSelect) {
                return <button
                  className={classNames(css.item, select.text === activeHead && css.active )}
                  key={`select-${index}`}
                  onClick={() => {
                    setActiveHead(select.text)
                  }}
                >
                  {select.text} <span className={css.selected}/>
                </button>
              }

              return (<NavLinkName
                className={css.item}
                defaultName={defaultVal}
                href={select.link}
                key={`select-${index}`}
                text={select.filterText}
                activeClassName={css.active}
                pathname={pathnameArray}
                onActiveChange={(isActive) => {
                  isActive && setActiveHead(select.text)
                }}
              >
                {select.text} <span className={css.selected}/>
              </NavLinkName>)
            })}
          </div>
        </div>
      </div>
      :
      <select
        onChange={(e) => {
          if (!regularSelect) {
            void router.push(e.target.value);
          }
          // console.log(e.target.selectedOptions[0].text)
          setActiveHead(e.target.selectedOptions[0].text)
        }}
        value={defaultVal}
        className={classNames(css.select, className)}>
        {options}
      </select>
  );
};
