import {getPerformance} from 'COMPONENTS/ui/move/move';
import {Performance} from 'a-utils';

import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';
import {getScrollDispatcher, ScrollEvent} from 'COMPONENTS/ui/scroll/ScrollDispatcher';

export type OnChange = (vw: number, vh: number, secondScreen: boolean, hideAll?: boolean) => void;


export class JSInstance {
  private _isNeedUpdate?: boolean;
  private readonly _performance: Performance;
  private _onChange: OnChange;

  private _mainWrap: HTMLDivElement;
  private _image: HTMLButtonElement;

  private _vw = 1;
  private _vh = 1;
  private _top = 0;

  private _circleIsGrow = false;

  private _secondScreen = false;
  private _hideAll = false;

  private _goParallaxGo = false;
  private _parallaxPercent = 0;
  private _parallaxTop = 10000;
  private _parallaxBottom = 10000;

  private _circlesScaleOnScroll = 0;

  private readonly _obj: {
    new: number;
    current: number;
    move: number;
    last: number;
    speed: number;
    smooth: number;
    scale: number;
  };

  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _scrollDispatcher = getScrollDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = (e: ScrollEvent) => this._scroll(e);

  private readonly animateHandler: (shift: number) => void;

  constructor(image: HTMLButtonElement, mainWrap: HTMLDivElement, onChange: OnChange = () => {
  }) {
    this._performance = getPerformance();
    this._mainWrap = mainWrap;
    this._image = image;

    this._obj = {
      speed: 0,
      smooth: 0,
      current: 0,
      move: 0,
      new: 0,
      last: 0,
      scale: 1,
    };

    this._onChange = onChange;
    this.animateHandler = (shift: number) => this._onAnimate(shift);
  }

  public init() {
    this._performance.addListener(this.animateHandler);
    this._resizeDispatcher.addListener(this._onResize);
    this._scrollDispatcher.addListener(this._onScroll);

    this._resizeDispatcher.fire();
  }

  public destroy() {
    this._performance.removeListener(this.animateHandler);
    this._resizeDispatcher.removeListener(this._onResize);
    this._scrollDispatcher.removeListener(this._onScroll);
  }

  private _resize({vw, vh}: ResizeEvent) {
    this._vw = vw;
    this._vh = vh;


    this._parallaxTop = 40;
    this._parallaxBottom = this._mainWrap.getBoundingClientRect().height - this._vh * 1.4;


    //this._image

    const bound = this._image.getBoundingClientRect()
    this._mainWrap.style.setProperty('--x', `${this._image.clientLeft + bound.left + (bound.width / 2)}px`);
    this._mainWrap.style.setProperty('--y', `${this._image.clientTop + bound.top + (bound.width / 2)}px`);

    //console.log(this._image.getBoundingClientRect().left)


    this._onChange(this._vw, this._vh, this._secondScreen);
    this._scrollDispatcher.fire();
  }


  private _scroll({offsetTop}: ScrollEvent) {
    this._top = offsetTop;
    this._obj.new = this._top;


    //когда все проскролили, скрываем оранжевый скролл
    this._hideAll = this._top > this._parallaxBottom;
    this._onChange(this._vw, this._vh, this._secondScreen, this._hideAll);

    //раскрываем круг по скроллу. Начинаем когда проскроллили 0

    if (this._top < 10) {
      this._circlesScaleOnScroll = 0;
      this._circleIsGrow = false;
      this._secondScreen = false
    }
    if (this._top >= 0 && this._top < 30) {
      this._circlesScaleOnScroll = this._top / 10;
      this._circleIsGrow = true;
      this._secondScreen = false
    }
    if (this._top > 35) {
      this._circleIsGrow = false;
      this._circlesScaleOnScroll = 1;
      this._secondScreen = true
    }

    //this._circlesScaleOnScroll *= this._scale;


    //this._secondScreen = this._top >= this._vh;

    this._goParallaxGo = !(this._top < this._vh * .2 || this._top > this._parallaxBottom);


    if (this._goParallaxGo) {
      this._parallaxPercent = ((this._top - this._vh * .4) / (this._vh * .3)) * this._vh;
    }

    this._hideAll = this._top > this._parallaxBottom;
    this._onChange(this._vw, this._vh, this._secondScreen, this._hideAll);
  }

  private _onAnimate(shift: number) {
    const obj = this._obj;
    obj.last = obj.current;
    obj.move += (obj.new - obj.move) / 2;
    obj.current += (obj.new - obj.current) / 5;
    obj.speed = obj.last - obj.current;
    obj.smooth += obj.speed / 4;
    obj.smooth /= (1 + 0.05 * shift);
    obj.smooth = Math.max(obj.smooth, -100);
    obj.smooth = Math.min(obj.smooth, 100);
    obj.scale = obj.smooth / 100;

    if (obj.scale < 0.01 && obj.scale > -0.01) {
      this._update(0);
      this._isNeedUpdate = false;
      return;
    }
    this._isNeedUpdate = true;
    this._update(obj.scale);

    if (this._circleIsGrow) {
      this._mainWrap.style.setProperty('--scale', `${this._circlesScaleOnScroll}`);
    }

    if (this._goParallaxGo) {
      this._mainWrap.style.setProperty('--top1', `${-this._parallaxPercent * .7}px`);
      this._mainWrap.style.setProperty('--top2', `${-this._parallaxPercent * .6}px`);
      this._mainWrap.style.setProperty('--top3', `${-this._parallaxPercent * .5}px`);
    }
  }

  private _update(scale: number) {
    if (this._isNeedUpdate && this._goParallaxGo) {
      this._mainWrap.style.setProperty('--deg1', `${-scale * 12 + 6}deg`);
      this._mainWrap.style.setProperty('--deg2', `${scale * 10 - 6}deg`);
      this._mainWrap.style.setProperty('--deg3', `${-scale * 8 - 8}deg`);
    }
  }

  set onChange(func: OnChange) {
    this._onChange = func;
  }

  private _change(vw: number, vh: number, secondScreen: boolean, hideAll?: boolean) {
    this._onChange(vw, vh, secondScreen, hideAll);
  }
}
