import React, {useEffect, useRef, useState} from 'react';
import css from './OrangeScreen.module.scss'
import {classNames} from 'a-utils';
import {useIsVisible} from 'HOOKS/useIsVisible';

type OrangeScreenProps = {
  title: string
}

export const OrangeScreen = ({title} : OrangeScreenProps) => {
  const block = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const visible = useIsVisible(block, .5)
  const [shown, setShown] = useState(false)

  useEffect(() => {
    if (visible) setShown(true)
  }, [visible])

  return (
    <section className={classNames(css.main, shown && css.active)} >
      <div className={css.orange} ref={wrapper}></div>
      <div className={classNames('container', css.container)}>
        <div className={classNames(css.title)} ref={block} >
          <h2 className={'big-h1'}>
            {title.split('\n').map((word) => <span key={word}>{word}</span>)}
          </h2>
        </div>

        <div className={css.circle}>
          <div></div>
        </div>
      </div>

    </section>
  );
};
