import React from 'react';
import {Popup} from 'COMPONENTS/ui/popup/Popup';
import {VacancyForm} from 'COMPONENTS/pages-components/common/VacancyForm/VacancyForm';
import {useDispatch, useSelector} from 'STORE/index';
import {RadioItems} from 'TYPES/types';
import {ImageProps} from 'next/image';
import {togglePopup} from 'STORE/slices/Options';

type Props = {
  api: string;
  title?: string;
  fields?: {
    name?: string;
    email?: string;
    phone?: string;
    textarea?: string;
    file?: string;
  }
  radio?: RadioItems;
  picture?: ImageProps;
}

const GetInTouchPopup = (props: Props) => {
  const {popupOpened} = useSelector((state) => state.Options)
  const dispatcher = useDispatch();


  return (
    <Popup active={popupOpened} modifier={'form'} onClose={() => dispatcher(togglePopup(false))}>
      <VacancyForm type={'popup'} {...props}/>
    </Popup>

  );
};

export default GetInTouchPopup;
