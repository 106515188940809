import React, {HTMLAttributes} from 'react';
import css from './Contacts.module.scss'
import {JustLink} from 'TYPES/types';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {classNames} from 'a-utils';

type Props = HTMLAttributes<HTMLElement> & {
  title?: string;
  link?: JustLink;
}

const Contacts = ({className, title, link}: Props) => {

  return (
    <div className={classNames(className, css.contacts)}>
      {title && <div className={css.title}>{title}</div>}
      {link && <SimpleLink href={link.link} className={classNames(css.link, 'link')}>{link.text}</SimpleLink>}
    </div>
  );
};

export default Contacts;