import {useEffect, useState} from 'react';


export default function useIsTouch() {
  const [touchEvent, setTouchEvent] = useState(false);

  useEffect(() => {
    setTouchEvent(('ontouchstart' in window) || (navigator.maxTouchPoints > 0));

    return () => {
    };
  }, []);

  return touchEvent;
}
