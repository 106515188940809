// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import {NextApiRequest, NextApiResponse} from 'next';
import {Pathname} from 'TYPES/types';

export const AppRoutes = {
  Main: '/',
  GamesMain: '/games',
  Legal: '/legal',
  GamesDetailed: '/games-detailed',
  About: '/about',
  Vacancies: '/vacancies',
  VacancyDetailed: '/vacancy-detailed',
  News: '/news',
  NewsDetailed: '/news-detailed',
  Contact: '/contacts',
  Privacy: '/privacy/en',
  TermsOfService: '/terms-of-service/en',
  License: '/license',
  Supports: '/supports'
};

/**
 * Этот мок сообщает нексту о существующих страницах для SSG
 */
export const pathnames = [
  {pathname: '/'},
  {pathname: '/news'},
  {pathname: '/news-detailed'},
  {pathname: '/vacancies'},
  {pathname: '/vacancy-detailed'},
  {pathname: '/games'},
  {pathname: '/legal'},
  {pathname: '/games-detailed'},
  {pathname: '/about'},
  {pathname: '/contacts'},
  {pathname: '/privacy'},
  {pathname: '/terms-of-service'},
  {pathname: '/license'},
  {pathname: '/supports'}
];

const getMock = async () => {
  return pathnames;
};
const getData = async () => {
  const res = await fetch(`${process.env.NEXT_PUBLIC_API || ''}/pathnames`);
  return await res.json() as Pathname[];
};

export const getPathnames = process.env.NEXT_PUBLIC_BUILD_TYPE === 'production' ? getData : getMock;


export default function handler(req: NextApiRequest, res: NextApiResponse<Pathname[]>) {
  res.status(200).json(pathnames);
}
