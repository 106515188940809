import React from 'react';
import Link from 'next/link';
import NavLink, {NavLinkProps} from 'COMPONENTS/ui/NavLink/NavLink';

export type DefaultLinkProps = {
  href: string;
  isExternal?: boolean;
}

export type Props = React.PropsWithChildren<{
  className?: string;
  nav?: NavLinkProps;
} & DefaultLinkProps>;


/**
 * Стандартный компонент ссылок, все ссылки делать через него
 * @param href
 * @param children
 * @param className
 * @param isExternal
 * @param nav
 * @constructor
 */
export const SimpleLink = ({href, children, className, isExternal, nav}: Props) => {
  if (isExternal) {
    return <a href={href} className={className} target='_blank' rel='noreferrer'>{children}</a>
  } else {
    if (nav) {
      return <NavLink href={href} {...nav} className={className}>{children}</NavLink>
    } else {
      return <Link href={href} className={className}>{children}</Link>
    }
  }
}
