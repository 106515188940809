import {EventEmitter} from 'a-utils';

export type ScrollEvent = {
  offsetTop: number;
}

export const getScrollData = () => {
  return {
    offsetTop: window.scrollY,
  };
};

export class ScrollDispatcher extends EventEmitter<ScrollEvent> {
  private _top = 0;
  private readonly _onScroll = () => this._scroll();

  constructor() {
    super();

    window.addEventListener('scroll', this._onScroll);
  }

  private _scroll() {
    const event = getScrollData();
    this.dispatch(event);
    const {offsetTop} = event;

    this._top = offsetTop;
  }

  public destroy() {
    window.removeEventListener('scroll', this._onScroll);
  }

  public fire() {
    this._onScroll();
  }
}


let dispatcher: ScrollDispatcher;

export const getScrollDispatcher = () => {
  if (!dispatcher) dispatcher = new ScrollDispatcher();
  return dispatcher;
};
