import React, {useEffect, useRef, useState} from 'react';
import css from './AboutPerks.module.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCreative, Pagination, Swiper as SwiperRef} from 'swiper';
import {classNames} from 'a-utils';
import {PerkImg, PerkProps} from 'TYPES/types';
import {JSInstance} from './JSInstance';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';


type AboutPerksProps = {
  title: string,
  cards: {
    imgType: PerkImg,
    title: string,
    description: string
  }[]
}

export const AboutPerks = ({title, cards}: AboutPerksProps) => {
  const [width, setWidth] = useState(320);
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const blockRef = useRef<HTMLDivElement | null>(null)
  const swiperRef = useRef<SwiperRef>()
  const swiperRef1 = useRef<SwiperRef>()
  // eslint-disable-next-line prefer-const
  let [activeSlide, setActiveSlide] = useState(0)
  const [disabledPrev, setDisabledPrev] = useState(true)
  const [disabledNext, setDisabledNext] = useState(false)

  useEffect(() => {
    if (!blockRef.current) return ;

    const instance = new JSInstance(blockRef.current);
    setInstanceJS(instance)
    setWidth(window.innerWidth)

    instance.init();

    return () => {
      instance.destroy();
    };
  }, []);

  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw) => {
      setWidth(vw)
    });
  }, [instanceJS]);

  const slidePrev = () => {
    if (activeSlide <= 0) {
      setActiveSlide(0)
    } else {
      setActiveSlide(activeSlide--)
    }

    if (swiperRef.current && width > 1280) {
      swiperRef.current.slideTo(activeSlide)
    }
  }

  const slideNext = () => {
    if (activeSlide >= cards.length - 1) {
      setActiveSlide(cards.length - 1)
    } else {
      setActiveSlide(activeSlide++)
    }
    if (swiperRef.current && width > 1280) {
      swiperRef.current.slideTo(activeSlide)
    }
  }

  useEffect(() => {
    if (activeSlide >= cards.length - 1) {
      setDisabledNext(true)
    } else {
      setDisabledNext(false)
    }

    if (activeSlide <= 0) {
      setDisabledPrev(true)
    } else {
      setDisabledPrev(false)
    }
  }, [activeSlide, cards.length])


  type PerkSlideProps = {
    card: PerkProps,
    scrolled?: boolean,
  }

  // Слайд
  const PerkSlide = ({card, scrolled} : PerkSlideProps) => {
    return (
      <div className={classNames(css.card, scrolled && css.disabled)}>
        <div>
          <div className={css.image} style={{backgroundImage: `url(./about/perks.svg#${card.imgType})` }}/>
          <h3 className={css.cardTitle}>{card.title}</h3>
          <p className={css.cardDescription}>{card.description}</p>
        </div>
      </div>
    )
  }

  return (
    <section className={css.main}>
      <div className={classNames( 'container', css.wrap)}>

        {title && <h2 className={css.title}>{title}</h2>}

        <div ref={blockRef} className={css.slider}>
          {width > 1280 && <>
            <Swiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper
                swiperRef.current.slideTo(activeSlide)
              }}
              grabCursor={true}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              speed={800}
              threshold={0.6}
              effect={'creative'}
              creativeEffect={{
                prev: {
                  translate: [0, 0, 0],
                  scale: 0.8,
                  opacity: .2,
                },
                next: {
                  translate: [0, '900px', 0],
                  scale: 1,
                  opacity:1
                },
              }}
              onRealIndexChange ={ (swiper) => {
                setActiveSlide(swiper.realIndex)
              }}
              direction={'vertical'}
              slidesPerView={1}
              modules={[Pagination, EffectCreative]}
              className={css.swiper}
            >
              {cards.map((el ) => <SwiperSlide key={el.title} >
                <PerkSlide card={el}/>
              </SwiperSlide>)}
            </Swiper>

            <div className={classNames(css.nav)}>
              <button onClick={() => slidePrev()}
                className={classNames(css.button, 'button-nav small button-nav--transparent-orange', css.prev, disabledPrev ? css.disabled : '' )}>
                <Icon id={'arrow-left'}/>
              </button>
              <button onClick={() => slideNext()} className={classNames(css.button, 'button-nav small button-nav--transparent-orange', css.next, disabledNext ? css.disabled : '')}>
                <Icon id={'arrow-left'}/>
              </button>
            </div>
          </>
          }

          {width < 1280 &&
            <Swiper
              onSwiper={(swiper) => {
                swiperRef1.current = swiper
                swiperRef1.current.slideTo(activeSlide)
              }}
              threshold={0.6}
              grabCursor={true}
              spaceBetween={32}
              autoHeight={true}
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
              modules={[Pagination]}
              className={css.swiper}
              onRealIndexChange ={ (swiper) => {
                setActiveSlide(swiper.realIndex)
              }}
            >
              {cards.map((el ) => <SwiperSlide key={el.title} ><PerkSlide card={el}/></SwiperSlide>)}
            </Swiper>
          }


        </div>
      </div>
    </section>
  );
};
