import React, {HTMLAttributes} from 'react';
import {classNames} from 'a-utils';
import {DropdownLink} from 'TYPES/types';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import css from './MenuItemDropdown.module.scss';

type Props = HTMLAttributes<HTMLUListElement> & {
  list?: DropdownLink[];
}

const MenuItemDropdown = ({className, list}: Props) => {

  return (
    <div data-class={'drop'} className={classNames(className, css.drop)}>
      <div className={css.wrap}>
        {list?.map((item, index) => <SimpleLink key={`item-${index}`}
          href={item.link}><span>{item.text}</span></SimpleLink>)}
      </div>
    </div>
  );
};

export default MenuItemDropdown;
