import React from 'react';
import css from './DetailedSocial.module.scss';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';


export type Props = {
  title?: string;
  sharedLink: string;
  sharedText: string;
  heading?: boolean;
  anchor?: string;
}

export const DetailedSocial = ({title, sharedLink, sharedText, heading, anchor}: Props) => {
  return (
    <div id={anchor ? anchor : ''}  className={heading ? '' : css.block}>
      {title && <div className={css.title}>{title}</div>}

      <div className={css.share}>
        <FacebookShareButton
          url={sharedLink}
          quote={sharedText}>
          <span className={css.icon}>
            <Icon id={'social-facebook'}/>
          </span>
        </FacebookShareButton>

        <TwitterShareButton
          url={sharedLink}
          title={sharedText}>
          <span className={css.icon}>
            <Icon id={'social-twitter'}/>
          </span>
        </TwitterShareButton>

        <LinkedinShareButton
          url={sharedLink}
          title={sharedText}>
          <span className={css.icon}>
            <Icon id={'social-linkedin'}/>
          </span>
        </LinkedinShareButton>
      </div>
    </div>
  );
};
