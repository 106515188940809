import React, {HTMLAttributes} from 'react';
import {SocialLink} from 'TYPES/types';
import SocialItem from 'COMPONENTS/pages-components/common/Social/SocialItem/SocialItem';
import css from './Social.module.scss';


type Props = HTMLAttributes<HTMLElement> & {
  social: SocialLink[];
}

const Social = ({social}: Props) => {

  return (
    <ul className={css.social}>
      {social?.map((socLink, index) => <SocialItem key={`social-link-${index}`} {...socLink} />)}
    </ul>
  );
};

export default Social;