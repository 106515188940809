import React from 'react';
import css from './NewsCard.module.scss';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {classNames} from 'a-utils';
import Image from 'next/image';
import {NewsProps} from 'TYPES/types';


export type Props = React.PropsWithChildren<{
  hasText?: boolean;
  className?: string;
} & NewsProps>;

export const NewsCard = ({ title, text, date, link, image, hasText, className}: Props) => {
  return (
    <SimpleLink href={link ? link : ''} className={classNames(css.card, className)}>
      <span className={classNames(css.wrap, hasText ? css.wide : css.normal )} >
        {image.src && <Image
          className={css.image}
          priority={false}
          quality={90}
          src={image.src}
          alt={image.alt}
          width={image.width}
          height={image.height}
          loading={'lazy'}
        />}
        <div data-style={'text'} className={css.text}>
          {hasText ?
            <>
              {title &&  <h4 className={classNames(css.title, css.full)} dangerouslySetInnerHTML={{__html: title}}/>}
              {text && <p className={css.subtext} dangerouslySetInnerHTML={{__html: text}} />}
            </>
            :
            <>
              {title && <h5 className={classNames(css.title, css.short)} dangerouslySetInnerHTML={{__html: title}}/>}
            </>}
          <span className={css.tag}>{date}</span>
        </div>
      </span>
    </SimpleLink>
  );
};
