import React, {Fragment, useEffect, useState} from 'react';
import css from './GamesFeatures.module.scss'
import Image, {ImageProps} from 'next/image';
import {classNames} from 'a-utils';
import {Primitives, PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import { motion } from 'framer-motion';

export type GamesFeaturesProps = {
  tags: string[];
  title: string;
  subtitle?: PrimitivesProps;
  mainImg: ImageProps;
  feature: {
    text?: PrimitivesProps;
    images?: ImageProps[];
  }[];
}
export const GamesFeatures = ({title,subtitle, mainImg, feature}: GamesFeaturesProps) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [width, setWidth] = useState(320);

  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [width]);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.main)}
    >
      <h1 className={'visually-hidden'}>{title}</h1>

      <div className={classNames(css.wrap,  'container')}>
        {width < 768 &&
          <div className={css.titleWrap}>
            <h2 className={classNames('h1')}>{title}</h2>
            { subtitle && <Primitives {...subtitle} /> }
          </div>
        }
        <div className={css.sliderWrap}>
          <Swiper
            threshold={0.6}
            className={classNames(css.slider)}
            loop={false}
            slidesPerView={'auto'}
            spaceBetween={width > 768 ? width > 1280 ? 260 : 180 : 0}
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{
              type: 'progressbar',
            }}
          >

            <SwiperSlide className={css.slide}>
              {width >= 768 ?
                <>
                  <div className={css.text}>
                    <h2 className={classNames('h1' )}>{title}</h2>
                    { subtitle && <Primitives {...subtitle} /> }
                  </div>
                  <div className={classNames(css.image, css.xl)}>
                    {mainImg.src && <Image
                      src={mainImg.src}
                      alt={mainImg.alt}
                      width={mainImg.width}
                      height={mainImg.height}
                      priority={false}
                      quality={90}/>}
                  </div>
                </> :
                <>
                  <div className={classNames(css.image, css.xl)}>
                    {mainImg.src && <Image
                      src={mainImg.src}
                      alt={mainImg.alt}
                      width={mainImg.width}
                      height={mainImg.height}
                      priority={false}
                      quality={90}/>}
                  </div>
                </>}
            </SwiperSlide>

            {feature.map((el, index) => {
              return (<Fragment key={`feature-${index}-0`}>
                {
                  el.text && <SwiperSlide key={`feature-${index}-1`} className={css.slide}>
                    <div className={css.text}>
                      <Primitives {...el.text} />
                    </div>
                    <div className={classNames(css.image, el.images?.length === 1 ? css.xl : css.xs)}>
                      {
                        el.images && el.images.map((img) => (
                          <Image
                            key={`img-${Math.random()}`}
                            src={img.src}
                            alt={img.alt}
                            width={img.width}
                            height={img.height}
                            priority={false}
                            quality={90}/>
                        ))
                      }
                    </div>
                  </SwiperSlide>
                }
              </Fragment>)
            })}
          </Swiper>

          <div className={classNames(css.nav)}>
            <button className={classNames(css.button, css.prev)} ref={navigationPrevRef}>
              <span className={'button-nav small button-nav--transparent-orange'}>
                <Icon id={'arrow-left'}/>
              </span>
            </button>
            <button className={classNames(css.button, css.next)} ref={navigationNextRef}>
              <span className={'button-nav small button-nav--transparent-orange'}>
                <Icon id={'arrow-left'}/>
              </span>
            </button>
          </div>
        </div>
      </div>
    </motion.section>
  );
};
