export const splitLines = (splitText: string[], maxLength: number, startAnimation?: number) => {
  const arr = Array<string>();
  let str = '';

  let delay = 0;
  if (startAnimation) delay = startAnimation;


  splitText.forEach((word) => {
    if (word.length > 0) {
      if (str.length + word.length <= maxLength) {
        str += word + ' ';
      } else {
        arr.push(str);
        str = word + ' ';
      }
    }
  })
  arr.push(str);

  str = '';
  arr.forEach((line, i) => {
    str += `<span><i style="transition-delay: ${delay + i * 0.1}s">${line}</i></span>`
  })

  //return str
  return str

}
