import React, {useEffect, useState} from 'react';
import css from './DetailedVideo.module.scss';
import {Picture, Video} from 'TYPES/types';
import {classNames} from 'a-utils';
import Image from 'next/image';
import ReactPlayer from 'react-player/youtube';

export type Props = {
  video?: Video;
  picture?: Picture;
  anchor?: string;
}

export const DetailedVideo = ({video, picture, anchor}: Props) => {
  const [triggered, togglePopup] = useState(false);
  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);


  return (
    <div id={anchor ? anchor : ''} className={classNames(css.block, css.mobile, triggered && css.triggered)}>
      <div className={css.wrap}>
        <div className={css.play}>
          <span>play</span>
        </div>
        {picture && picture.src && <div className={classNames(css.preview)}>
          <Image
            className={css.picture}
            width={picture.width}
            height={picture.height}
            src={picture.src}
            alt={picture.alt ? picture.alt : 'Видео превью'}
            priority={true}
          />
        </div>
        }
        {video && hasWindow &&
          <div className={classNames(css.container, css.mobilevideo, css.youtube, triggered && css.triggered)}>
            <ReactPlayer
              width={`100%`}
              height={`100%`}
              url={video.url}
              playing={triggered}
              controls
              onPlay={() =>  {togglePopup(true)}}
            />
          </div>
        }
      </div>
    </div>
  );
};
