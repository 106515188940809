import {gsap} from 'gsap';

type AnchorSizes = {
  top: number;
  height: number;
  bottom: number;
}

export class AnchorInstance {
  private _y = 0;
  private _isAnimate: boolean;
  private _vw: number;
  private _vh: number;
  private _scrolled: number;
  private _tween?: gsap.core.Tween;
  private _anchor: HTMLDivElement | null;
  private _anchorArr: AnchorSizes;

  private readonly _onResize: () => void;
  private readonly _onStop: () => void;

  constructor() {
    this._anchor = document.querySelector('#form');
    this._anchorArr = {
      top: 0,
      height: 0,
      bottom: 0
    }

    this._y = 0;
    this._isAnimate = false;
    this._vw = 1;
    this._vh = 1;
    this._scrolled = 0;

    this._onResize = () => this._resize();
    this._onStop = () => this._stop();
  }

  public init() {
    this._resize();
    window.addEventListener('resize', this._onResize);

    document.addEventListener('wheel', () => this._onStop());
    document.addEventListener('mousedown', () => this._onStop());
    document.addEventListener('touchstart', () => this._onStop());
  }

  public destroy() {
    window.removeEventListener('resize', this._onResize);

    document.removeEventListener('wheel', () => this._onStop());
    document.removeEventListener('mousedown', () => this._onStop());
    document.removeEventListener('touchstart', () => this._onStop());
  }


  private _resize() {
    this._scrolled = window.scrollY;

    this._vw = window.innerWidth;
    this._vh = window.innerHeight;

    if (this._anchor) {
      const bound = this._anchor?.getBoundingClientRect();

      this._anchorArr = {
        'top': bound.top + this._scrolled,
        'height': bound.height,
        'bottom': bound.bottom,
      };
    }
  }

  private _stop() {
    if (this._tween) {
      this._tween.kill();
      this._tween = undefined;
      this._isAnimate = false;
    }
  }

  public _scrollTo() {
    const newY = this._anchorArr.top;
    const oldY = window.scrollY;
    this._y = oldY;

    let duration = Math.abs((newY - oldY) / this._vh * 0.2);
    duration = duration < 0.4 ? 0.4 : duration;

    this._tween = gsap.fromTo(this, {
      _y: oldY,
    }, {
      _y: newY,
      duration,
      onStart: () => {
        this._isAnimate = true;
      },
      onUpdate: () => {
        window.scrollTo(0, this._y);
      },
      onComplete: () => {
        this._isAnimate = false;
      },
    });
  }
}
