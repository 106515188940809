// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import type {NextApiRequest, NextApiResponse} from 'next';
import {AppRoutes} from '../pathnames';
import {CardResponse} from 'COMPONENTS/pages-components/vacancy-page/VacancyHead/VacancyHead';

const data: CardResponse = {
  items: [
    {
      title: 'Producer',
      tag: ['Analytics'],
      link: AppRoutes.VacancyDetailed,
      color: 'yellow'
    },
    {
      title: 'Accountant',
      tag: ['Other', 'Cyprus'],
      link: AppRoutes.VacancyDetailed,
      color: 'purple'
    },
    {
      title: 'Middle Fullstack Developer',
      tag: ['Analytics'],
      link: AppRoutes.VacancyDetailed,
      color: 'black'
    },
    {
      title: 'Senior/Lead Java Developer',
      tag: ['Analytics'],
      link: AppRoutes.VacancyDetailed,
      color: 'yellow'
    },
    {
      title: 'Motion Designer',
      tag: ['Development'],
      link: AppRoutes.VacancyDetailed,
      color: 'green'
    },
    {
      title: 'Senior 2D artist',
      tag: ['Art'],
      link: AppRoutes.VacancyDetailed,
      color: 'orange'
    },
    {
      title: '2D artist',
      tag: ['Other'],
      link: AppRoutes.VacancyDetailed,
      color: 'purple'
    },
    {
      title: 'Senior Narrative Designer',
      tag: ['Analytics'],
      link: AppRoutes.VacancyDetailed,
      color: 'black'
    },
    {
      title: 'QA Engineer',
      tag: ['Analytics'],
      link: AppRoutes.VacancyDetailed,
      color: 'yellow'
    },
    {
      title: 'Level Designer',
      tag: ['Development'],
      link: AppRoutes.VacancyDetailed,
      color: 'green'
    },
  ],
  //если isEnd: true, то пофиг что в moreText, эта строка может вообще не приходить
  moreText: 'Load more <i>12</i>',
  isEnd: true
};


const getMock = async () => {
  return data;
};
const getServerData = async (url: string) => {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const res = await fetch(process.env.NEXT_PUBLIC_API + url);
  return await res.json() as CardResponse;
};

export const getData = process.env.NEXT_PUBLIC_BUILD_TYPE === 'production' ? getServerData : getMock;

export default function handler(req: NextApiRequest, res: NextApiResponse<CardResponse>) {
  res.status(200).json(data);
}
