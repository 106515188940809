import React, {HTMLAttributes} from 'react';
import {JustLink} from 'TYPES/types';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import css from './RedCollar.module.scss';

type Props = HTMLAttributes<HTMLElement> & JustLink;

const RedCollar = ({className, link}: Props) => {


  return (
    <div className={className}>
      <SimpleLink
        className={css.link}
        href={link}
        isExternal
      >
        <Icon id={'red-collar-long'}></Icon>
      </SimpleLink>
    </div>
  );
};

export default RedCollar;
