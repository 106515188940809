import React, {useEffect, useState} from 'react';
import css from './StuffVideo.module.scss'

import ReactPlayer from 'react-player';
import {classNames} from 'a-utils';
//import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {Video} from 'TYPES/types';
import Image, {ImageProps} from 'next/image';
//import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import { motion } from 'framer-motion';

export type GamesOtherProps = {
  title?: string;
  video: Video;
  picture: ImageProps;
}

export const StuffVideo = ({title, video, picture}: GamesOtherProps) => {
  const ref = React.useRef<ReactPlayer>(null);
  const [isPlaying, togglePlay] = useState(false);
  //const [isMuted, toggleMute] = useState(false);
  //const [progress, setProgress] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [hasWindow, setHasWindow] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);


  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.main)}
    >
      <div className={'container'}>
        {title && <h1 className={css.title} dangerouslySetInnerHTML={{__html:title}}/>}

        {hasWindow &&
          <div className={css.wrap} >
            <div className={css.block}>
              <div className={classNames(css.wrapper, loaded && css.load)}>
                <div className={classNames(css.posterWrap, clicked ? css.active : '')}>

                  <div className={css.playIcon}>
                    <span>play</span>
                  </div>

                  {picture.src && <Image
                    className={css.image}
                    width={picture.width}
                    height={picture.height}
                    src={picture.src}
                    alt={picture.alt}
                    onLoad={() => {setLoaded(true)}}
                  />}

                  {video.url && picture.src &&
                    <ReactPlayer
                      width={`100%`}
                      height={`100%`}
                      url={video.url}
                      className={css.youtube}
                      playing={isPlaying}
                      //muted={isMuted}
                      //onPause={() => togglePlay(false)}
                      onPlay={() =>  {
                        setClicked(true); togglePlay(true)}
                      }
                      //onProgress={(e) => setProgress(e.played)}
                      ref={ref}
                      config={{
                        youtube: {
                          playerVars: {
                            cc_load_policy: 0,
                            controls: 1,
                            showinfo: 0,
                            enablejsapi: 0,
                            fs: 1,
                            modestbranding: 1,
                            rel: 0,
                            iv_load_policy: 3,
                          }
                        }
                      }}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </motion.section>
  )
}
