import React from 'react';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {classNames} from 'a-utils';


export type buttonProps = {
	link: string,
	text: string,
  color?: 'orange' | 'white',
  size?: 'large' | 'small',
  className?: string;
  isExternal?: boolean;
};


export const Button = ({ link, text, color = 'orange', size = 'large', className, isExternal = false }: buttonProps) => {
  return (
    <SimpleLink isExternal={isExternal} className={classNames(className, 'button', color === 'orange' ? 'orange' : 'white', size === 'large' ? 'large' : 'small')} href={link}>
      <span className={'button-text'}>{text}</span>
    </SimpleLink>
  );
};
