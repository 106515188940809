import {
  DetailedPicture
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedPicture/DetailedPicture';
import {
  DetailedPrimitive
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedPrimitive/DetailedPrimitive';
import {DetailedVideo} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedVideo/DetailedVideo';
import {
  DetailedAttentionBlock
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedAttentionBlock/DetailedAttentionBlock';
import {
  DetailedPreview
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedPreview/DetailedPreview';
import {
  DetailedSocial
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedSocial/DetailedSocial';
import {
  DetailedContacts
} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedContacts/DetailedContacts';
import {DetailedSelect} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedSelect/DetailedSelect';
import {DetailedTable} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedTable/DetailedTable';
import {DeleteAccountForm} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DeleteAccountForm/DeleteAccountForm';

type TypeOfContent = typeof content;
type ContentKeys = keyof TypeOfContent;

interface ContentType<T extends ContentKeys> {
  type: T;
  content: Parameters<TypeOfContent[T]>[0];
  isWhite?: boolean;
  anchor?: string;
}

export type ContentTypes = ContentType<ContentKeys>[];

export type ContentProps = {
  items?: ContentTypes;
  modifier?: string;
}


const content = {
  DetailedSelect,
  DetailedPreview,
  DetailedPicture,
  DetailedPrimitive,
  DetailedTable,
  DetailedVideo,
  DetailedAttentionBlock,
  DetailedSocial,
  DetailedContacts,
  DeleteAccountForm
}


export const Content = ({items = []}: ContentProps) => {
  // eslint-disable-next-line react/react-in-jsx-scope
  return <>
    {items.map((item, i) => {
      if (!(item.type in content)) throw new Error(`"type: ${item.type}" is not exist`);

      const ContentChild = content[item.type];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/react-in-jsx-scope
      return <ContentChild key={`ci${i}`} {...item.content}/>
    })}
  </>
}
