import React, {useEffect, useState} from 'react';
import css from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup.module.scss';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {VideoProps} from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup';
import {Popup} from 'COMPONENTS/ui/popup/Popup';
import {useEscape} from 'HOOKS/useEscape';
import ReactPlayer from 'react-player';

export const VideoPopupYoutube = ({active = false, video, onClose}: VideoProps) => {
  const ref = React.useRef<ReactPlayer>(null);
  const [isPlaying, togglePlay] = useState(false);

  const onCloseHandler = () => {
    if (isPlaying) togglePlay(false);
    if (onClose) onClose();
  }


  useEscape(true, () => onCloseHandler());

  useEffect(() => {
    if (active) togglePlay(true);
  }, [active])

  return (
    <Popup active={active} videoPopup>
      {video &&
        <>
          <div className={'container'}>
            <div className={css.block}>
              <ReactPlayer
                width={`100%`}
                height={`100%`}
                url={video.url}
                className={css.youtube}
                playing={isPlaying}
                //muted={isMuted}
                //onPause={() => togglePlay(false)}
                ref={ref}
                config={{
                  youtube: {
                    playerVars: {
                      cc_load_policy: 0,
                      controls: 1,
                      showinfo: 0,
                      enablejsapi: 0,
                      fs: 1,
                      modestbranding: 1,
                      rel: 0,
                      iv_load_policy: 3,
                    }
                  }
                }}
              />
            </div>
          </div>
          <button className={css.cross} onClick={() => onCloseHandler()}>
            <Icon id={'cross'}/>
          </button>
        </>
      }
    </Popup>
  );
};
