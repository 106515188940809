import React from 'react';
import {JustLink, LabelTagType} from 'TYPES/types';
import css from './DetailedHeading.module.scss';
import BackLink from 'COMPONENTS/ui/back-link/BackLink';
import {classNames} from 'a-utils';
import LabelTag from 'COMPONENTS/pages-components/common/LabelTag/LabelTag';
import DetailedHeadingAdditional, {
  DetailedHeadingAdditionalProps
} from 'COMPONENTS/pages-components/detailed/DetailedHeading/DetailedHeadingAdditional';
import { motion } from 'framer-motion';

export type Props = {
  backLink?: JustLink;
  labels?: LabelTagType[];
  title?: string;
  additionalContent?: DetailedHeadingAdditionalProps;
  anchor?: string;
}


export const DetailedHeading = ({backLink, labels, title, additionalContent, anchor}: Props) => {

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.heading, additionalContent && css.vanancy, 'container')}
    >
      <div id={anchor ? anchor : ''} className={classNames(css.wrap)}>
        {backLink &&
          <div className={css.back}>
            <BackLink link={backLink.link} text={backLink.text}/>
          </div>
        }

        <div className={css.inner}>
          {labels &&
            <div className={css.labels}>
              {labels.map((label, index) => <LabelTag key={`label-tag_${index}`} className={css.label} {...label}/>)}
            </div>
          }

          {title && <h1 className={css.title}>{title}</h1>}

          {additionalContent && <DetailedHeadingAdditional heading={true} className={css.additional} {...additionalContent}/>}

        </div>
      </div>
    </motion.section>
  );
};
