import React, {useEffect, useState} from 'react';
import {classNames} from 'a-utils';
import css from './Header.module.scss';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {AppRoutes} from '../../../../pages/api/mocks/pathnames';
import Logo from 'COMPONENTS/pages-components/common/header/Logo/Logo';
import {NavigationLink} from 'TYPES/types';
import {Primitives, PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';
import Navigation from 'COMPONENTS/pages-components/common/header/Navigation/Navigation';
import {HeaderHideInstance} from 'COMPONENTS/ui/HeaderHide/HeaderHide';
import Menu from 'COMPONENTS/pages-components/common/header/Menu/Menu';
import {useRouter} from 'next/router';
import {FooterProps} from 'COMPONENTS/pages-components/common/footer/Footer';

const isTabletCheck = () => (window.innerWidth < 1025);

export type HeaderProps = {
  navigation?: NavigationLink[];
  tagLine?: {
    primitives?: PrimitivesProps;
  };
  ftr?: FooterProps
}

const Header = ({navigation, tagLine}: HeaderProps) => {
  const router = useRouter();
  const [isTablet, setIsTablet] = useState(true);
  const [header, setHeader] = useState<HTMLElement | null>(null);
  const [headerHidden, hideHeader] = useState(false);
  const [bg, setBg] = useState(false);
  const [menuTriggered, triggerMenu] = useState(false);
  const [firstAppearance, setFirstAppearance] = useState(false);


  const resize = () => {
    //setIsMobile(isMobileCheck());
    setIsTablet(isTabletCheck());
  }

  const burgerClickHandler = () => {
    triggerMenu(!menuTriggered);
  }

  useGlobalDOMEvents({resize});


  useEffect(() => {
    resize();
  }, [isTablet])


  useEffect(() => {
    if (!header) return;

    const onChange = (isHidden: boolean, hasBg: boolean) => {
      hideHeader(isHidden);
      setBg(hasBg)
    }

    const instance = new HeaderHideInstance(header, onChange, {backScroll: true});
    instance.init();

    return () => {
      instance.destroy();
    }
  }, [header]);

  useEffect(() => {
    setFirstAppearance(true);
  }, [])


  //шоб менюха закрывалась при переходах
  useEffect(() => {
    triggerMenu(false);
  }, [router.asPath])

  return (
    <header
      className={classNames(
        css.header,
        !firstAppearance && css.appeared,
        headerHidden && css.hidden,
        menuTriggered && css.white,
        bg && css.bg,
      )}
      ref={setHeader}
    >
      <div className={classNames('container container--large ', css.wrapper)}>
        <div className={css.left}>
          <div className={css.logo}>
            <SimpleLink href={AppRoutes.Main}>
              <Logo/>
            </SimpleLink>
          </div>
          {tagLine?.primitives &&
            <Primitives className={classNames(css.tagline)} {...tagLine?.primitives}/>}
        </div>


        <nav className={css.right}>
          {navigation && isTablet
            ? <Menu navigationList={navigation} isTriggered={menuTriggered}/>
            : <Navigation navigationList={navigation}/>
          }

          {isTablet &&
            <button
              className={classNames(css.burger, menuTriggered && css.active)}
              onClick={burgerClickHandler}
            >
              <span></span><span></span><span></span>
            </button>
          }
        </nav>
      </div>
    </header>
  );
};

export default Header;
