import React from 'react';
import css from './DetailedSelect.module.scss'
import {classNames} from 'a-utils';
import {Select} from 'COMPONENTS/pages-components/common/Select/Select';

export type Props = {
  title?: string;
  select?: {
    text: string,
    filterText: string,
    link: string,
  }[];
  anchor?: string;
}

export const DetailedSelect = ({title, select, anchor}: Props) => {

  if (!select) return;
  return (
    <div id={anchor ? anchor : ''} className={css.block}>
      {title && <p className={classNames(css.text)}>{title}</p>}
      <div className={css.select}>
        <Select
          className={css.select}
          selectItems={select}
        />
      </div>
    </div>
  );
};
