import React, {useEffect, useState} from 'react';
import css from './Cookies.module.scss';
import {classNames, getCookie, setCookie} from 'a-utils';


export const Cookies = () => {
  const [visible, setVisibility] = useState(false);

  const acceptCookie = () => {
    setCookie('cookie_agreement', `-1`);
    setVisibility(false);
  }

  useEffect(() => {
    const cookie = getCookie('cookie_agreement');

    cookie ? setVisibility(false) : setVisibility(true);

  }, [])

  return (
    visible ? <div className={classNames(css.cookie)}>
      <div className={css.block}>
        <small>This website uses cookies</small>
        <button className={classNames('button small orange', css.btn)} onClick={acceptCookie}>
          <span className={'button-text'}>ok</span>
        </button>
      </div>
    </div> : <></>
  );
};
