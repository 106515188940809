import React from 'react';
import css from './ContactsOffices.module.scss';
import Image from 'next/image';
import {classNames} from 'a-utils';
import { motion } from 'framer-motion';

type Office = {
  name: string;
  address: string[];
}

export type Props = {
  mainOffice?: Office;
  offices?: Office[];
}

export const ContactsOffices = ({mainOffice, offices}: Props) => {

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.block)}
    >
      {mainOffice &&
        <div className={classNames(css.main, 'container')}>
          <div className={css.mainOffice}>
            <div>
              <div className={css.icon}>
                <Image
                  className={css.outer}
                  width={137}
                  height={136}
                  src={'/contacts/medical.svg'}
                  alt={''}
                  priority={true}
                />
              </div>
              {mainOffice.name && <h3 className={css.name}>{mainOffice.name}</h3>}
              {mainOffice.address && <p className={classNames(css.address, 'text')}>{mainOffice.address}</p>}
            </div>
          </div>
        </div>
      }
      {offices &&
        <div className={classNames(css.additional, 'container')}>
          <ul className={css.offices}>

            {offices.map((office, index) => {
              return (
                <li key={`office-${index}`}>
                  {office.name && <h4 className={css.name}>{office.name}</h4>}
                  {office.address.map((address, index) => <p key={`address-${index}`}
                    className={css.address}>{address}</p>)}
                </li>
              )
            })}
          </ul>
        </div>
      }
    </motion.section>
  );
};
