import {getPerformance} from 'COMPONENTS/ui/move/move';
import {Performance} from 'a-utils';

import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';
import {getScrollDispatcher, ScrollEvent} from 'COMPONENTS/ui/scroll/ScrollDispatcher';

export type OnChange = (vw: number, hideSmall: boolean) => void;


export class JSInstance {
  private _onChange: OnChange;
  private readonly _performance: Performance;

  private _canAnimate = false;

  private _vw = 1;
  private _vh = 1;
  private _top = 0;
  private _section: HTMLElement;
  private _list: HTMLElement;
  private _listLength = 0;
  private _offset = 0;
  private _sectionTop = 5000;
  private _sectionBottom = 5000;
  private _percent = 0;

  private _hideSmall = false;
  private _canScroll = false;

  private readonly _obj: {
    new: number;
    current: number;
    move: number;
    last: number;
    speed: number;
    smooth: number;
    scale: number;
  };


  private readonly animateHandler: () => void;
  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _scrollDispatcher = getScrollDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = (e: ScrollEvent) => this._scroll(e);

  constructor(section: HTMLElement, list: HTMLElement, onChange: OnChange = () => {
  }) {
    this._performance = getPerformance();
    this._onChange = onChange;

    this._section = section;
    this._list = list;

    const length = this._list.querySelectorAll('a')
    if (length) {
      this._listLength = length.length
    }

    this._obj = {
      speed: 0,
      smooth: 0,
      current: 0,
      move: 0,
      new: 0,
      last: 0,
      scale: 1,
    };

    this.animateHandler = () => this._onAnimate();
  }

  public init() {
    this._performance.addListener(this.animateHandler);
    this._resizeDispatcher.addListener(this._onResize);
    this._scrollDispatcher.addListener(this._onScroll);

    this._resizeDispatcher.fire();
  }

  public destroy() {
    this._performance.removeListener(this.animateHandler);
    this._resizeDispatcher.removeListener(this._onResize);
    this._scrollDispatcher.removeListener(this._onScroll);
  }

  private _resize({vw, vh}: ResizeEvent) {
    this._vw = vw;
    this._vh = vh;

    this._canAnimate = this._vw > 1280;

    if (this._canAnimate && this._listLength > 2) {
      // дизайн идет по колонкам.
      // 2 колонки - смещение, 4 колонки - ширина одной вакансии
      const col = this._list.getBoundingClientRect().width / 12;

      this._list.style.setProperty('--padding', `${col * 2}px`);
      this._list.style.setProperty('--width', `${col * 4 - 8}px`);

      this._offset = ((this._listLength - 3) * col * 4 + col * 2);
      //console.log(this._offset)

      this._sectionTop = this._section.getBoundingClientRect().top + this._top + 200;
      this._sectionBottom = this._sectionTop + this._section.getBoundingClientRect().height - this._vh * 1.5;
    }
    this._onChange(this._vw, this._hideSmall);
    this._scrollDispatcher.fire();
  }


  private _scroll({offsetTop}: ScrollEvent) {
    if (this._canAnimate) {
      this._top = offsetTop;

      // на сколько процентов сдвигать блок
      this._percent = ((this._top - this._sectionTop) / (this._sectionBottom - this._sectionTop));


      this._hideSmall = this._percent > .2;

      // если еще не дошли до блока или проскролили, анимацию отключаем
      this._canScroll = (this._top > this._sectionTop && this._top < this._sectionBottom);

      if (this._top > this._sectionBottom) {
        //this._list.style.setProperty('--offset', `-116.67%`);
        this._list.style.setProperty('--offset', `-${this._offset}px`);
      }

      this._obj.new = this._top;

      this._onChange(this._vw, this._hideSmall);
    }
  }


  private _onAnimate() {

    if (this._canScroll) {
      // (100 / 6) - смещение на 2 колонки из 12, то есть, у нас ширина блока 100% + 2 колонки
      //this._list.style.setProperty('--offset', `${this._percent * -(100 + (100 / 6))}%`);
      this._list.style.setProperty('--offset', `${-this._percent * this._offset}px`);
    }
  }

  set onChange(func: OnChange) {
    this._onChange = func;
  }

  private _change(vw: number, hideSmall: boolean) {
    this._onChange(vw, hideSmall);
  }
}
