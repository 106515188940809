// Next.js API route support: https://nextjs.org/docs/api-routes/introduction
import type {NextApiRequest, NextApiResponse} from 'next';
import {AppRoutes} from '../pathnames';
import {CardResponse} from 'COMPONENTS/pages-components/news-page/NewsPage';

const data: CardResponse = {
  items: [
    {
      title: 'LOADED NEWS Belkathon 2 is over',
      text: 'While we are waiting for the jury’s decision, follow the link to watch our special video',
      date: 'february 16, 2022',
      image: {
        src: '/main-page/news2.jpg',
        width: 416,
        height: 340,
        alt: 'news 2',
      },
      link: AppRoutes.NewsDetailed
    },
    {
      title: 'LOADED NEWS How to make your R&D rock How to make your R&D rock How to make your R&D rock How to make your R&D rock ',
      text: 'While we&nbsp;are waiting for the jury&rsquo;s decision, follow the link to&nbsp;watch our special video',
      date: 'may 03, 2022',
      image: {
        src: '/main-page/news1.jpg',
        width: 416,
        height: 340,
        alt: 'news 1',
      },
      link: AppRoutes.NewsDetailed
    },
    {
      title: 'LOADED NEWS Title Title Games at WN Conference 2021',
      text: 'While we&nbsp;are waiting for the jury&rsquo;s decision',
      date: 'february 10, 2022',
      image: {
        src: '/main-page/news2.jpg',
        width: 416,
        height: 340,
        alt: 'news 2',
      },
      link: AppRoutes.NewsDetailed
    },
  ],
  //если isEnd: true, то пофиг что в moreText, эта строка может вообще не приходить
  moreText: 'Load more <i>12</i>',
  isEnd: true
};


const getMock = async () => {
  return data;
};
const getServerData = async (url: string) => {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const res = await fetch(process.env.NEXT_PUBLIC_API + url);
  return await res.json() as CardResponse;
};

export const getData = process.env.NEXT_PUBLIC_BUILD_TYPE === 'production' ? getServerData : getMock;

export default function handler(req: NextApiRequest, res: NextApiResponse<CardResponse>) {
  res.status(200).json(data);
}
