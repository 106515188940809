import React, {useEffect, useState} from 'react';
import css from './VacancyHead.module.scss';
import {useRouter} from 'next/router';
import {VacancyCard, VacancyCardProps} from 'COMPONENTS/pages-components/common/VacancyCard/VacancyCard';
import {classNames} from 'a-utils';
import {Select} from 'COMPONENTS/pages-components/common/Select/Select';
import {getData} from '../../../../pages/api/mocks/vacancies/items';
import {JustLink} from 'TYPES/types';
import Link from 'next/link';
import { motion } from 'framer-motion';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';


export type detailVacancyProps = {
	title: string,
  filters: FilterType;
  items: VacancyCardProps[],
  moreText?: string;
  isEnd?: boolean;
  url?: string;
};

type FilterType = {
  sortBy?: string;
  firstFilterLinks?: filterLink[]
  secondFilterLinks?: filterLink[]
  resetBtn: JustLink;
}

export type filterLink = JustLink & {
  filterText: string;
}

export type CardResponse = {
  moreText?: string;
  isEnd?: boolean;
  items?: VacancyCardProps[];
}


export const VacancyHead = ({title, filters, items = [], isEnd, url, moreText  }: detailVacancyProps) => {
  const {query: {slug}} = useRouter();
  const [currentItems, setCurrentItems] = useState<VacancyCardProps[]>(items);
  const [page, setPage] = useState(2);
  const [isEndCurrent, setInEndCurrent] = useState(isEnd);
  const [isLoading, setLoading] = useState(false);

  //при смене активного пункта добавляем в состояние.
  // Когда оба совпадают со стартовым, скрываем ссылку "reset"
  const [activeFilterLink1, setActiveFilterLink1] = useState('');
  const [activeFilterLink2, setActiveFilterLink2] = useState('');
  const [resetFilter, setResetFilter] = useState(true);
  const [noItems, setNoItems] = useState(false);
  const [textOnLoad, setTextOnLoad] = useState('');

  useEffect(() => {
    if (items.length > 0) {
      setCurrentItems(items);
      setInEndCurrent(isEnd);
      setPage(2);

      setNoItems(false)
    } else {
      setNoItems(true)
    }
    if (moreText) {
      setTextOnLoad(moreText);
    }
  }, [items, isEnd]);


  const onClick = () => {
    if (!url || !slug || !Array.isArray(slug) || isLoading) return;
    setLoading(true);

    let teamsIndex = 2, locationsIndex = 4;

    slug.forEach((el, i) => {
      if (el === 'teams') {
        teamsIndex = i + 1;
      }
      if (el === 'locations') {
        locationsIndex = i + 1;
      }
    })

    if (slug[teamsIndex] === undefined) {
      slug[teamsIndex] = 'all-teams'
    }
    if (slug[locationsIndex] === undefined) {
      slug[locationsIndex] = 'all-locations'
    }

    const backRequest = `${url}?page=${page}&teams=${slug[teamsIndex]}&locations=${slug[locationsIndex]}`;

    getData(backRequest)
      .then((data) => {
        setLoading(false);
        setPage(old => old + 1);
        setInEndCurrent(data.isEnd);
        setCurrentItems(items => {
          return [...items, ...(data.items || [])];
        });
        if (items.length > 0) {
          setNoItems(false)
        } else {
          setNoItems(true)
        }
        if (data.moreText) {
          setTextOnLoad(data.moreText);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }

  //когда что-то выбрали - показываем ссылку "reset"
  //если стандартные выбраны - скрываем
  useEffect(() => {
    if (!filters.firstFilterLinks || !filters.secondFilterLinks) return;

    setResetFilter(activeFilterLink1 === filters.firstFilterLinks[0].text
      && activeFilterLink2 === filters.secondFilterLinks[0].text)

  }, [activeFilterLink1, activeFilterLink2, filters.firstFilterLinks, filters.secondFilterLinks])


  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.main)}
    >
      <div className={'container'}>
        <h1 className={css.title}>{title}</h1>
        <div className={css.filters}>
          <p className={classNames(css.sort, css.text)}>{filters.sortBy}</p>
          <div className={css.select}>
            {
              filters.firstFilterLinks &&
                <Select
                  reset={resetFilter}
                  changeActiveFilterLink={(message) => {setActiveFilterLink1(message)}}
                  selectItems = {filters.firstFilterLinks}
                />
            }
            {
              filters.secondFilterLinks &&
                <Select
                  reset={resetFilter}
                  changeActiveFilterLink={(message) => {setActiveFilterLink2(message)}}
                  selectItems = {filters.secondFilterLinks}
                />
            }
          </div>
          <Link
            href={filters.resetBtn.link}
            onClick={() => {setResetFilter(true)}}
            className={classNames(css.reset, css.text, resetFilter ? css.hidden : '')}>
            {filters.resetBtn.text}
          </Link>
        </div>

        <div className={css.list}>
          {!noItems ?
            currentItems.map((vacancy, index) => {
              return <VacancyCard key={`collection-card-${index}`} {...vacancy}/>
            })
            :
            <div className={css.noVacancy}>
              <SquirrelVideo className={css.img} name={'hi'}/>
              <div>
                <h4>WOW...</h4>
                <p className={'big-body'}>There are no jobs openings available {activeFilterLink2 === 'All' ? '' : 'in ' + activeFilterLink2} {activeFilterLink1 === 'All' ? '' : 'for ' + activeFilterLink1 }. Please, stay in touch and leave your contacts in the form below.</p>
              </div>
            </div>
          }
        </div>

        {!isEndCurrent && !noItems  &&
          <div className={classNames(css.loadMoreButton, 'more')}>
            <button disabled={isLoading}
              className={classNames('button orange large', isLoading && 'loading')}
              onClick={onClick}>
              <span className={'button-text'} dangerouslySetInnerHTML={{__html: textOnLoad ? textOnLoad : 'load more'}}/>
            </button>
          </div>
        }
      </div>
    </motion.section>
  );
};
