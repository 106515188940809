import React from 'react';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {SocialLink} from 'TYPES/types';
import css from './SocialItem.module.scss';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';

const SocialItem = ({iconType, link}: SocialLink) => {

  return (
    <li className={css.item}>
      <SimpleLink
        href={link}
        isExternal={true}
      >
        <span className={css.inner}>
          {iconType && <Icon id={iconType}/>}
        </span>
      </SimpleLink>
    </li>
  );
};

export default SocialItem;
