export type FormResponse<C = undefined> = {
  status: 'error' | 'success';
  response?: string | string[];
  errors?: {
    [key: string]: string | string[];
  };
  content: C;
}

export type FormProps<T extends FormResponse<unknown>> = React.PropsWithChildren<{
  action?: string;
  id?: string;
  noRadio?: boolean;
  beforeSubmit?: (state: FormState) => FormState;
  onSuccess?: (response: T) => void;
  onError?: (errorMessage: string, error: Error | Response | unknown) => void;
  className?: string;
}>

export type FormInputValue = string | string[] | boolean | FileList | File | null;

export type FormItemProps<V = FormInputValue> = {
  name: string;
  value?: V;
  defaultValue?: V;
  onChange?: (value: V, isComplete: boolean, name: string) => void;
  onComplete?: (isComplete: boolean, name: string) => void;
  notice?: string | boolean;
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
}

export type FormState = {
  [key: string]: FormStateItem | undefined;
}
export type FormStateItem<K = FormInputValue> = {
  value?: K;
  error?: boolean;
  notice?: string | boolean;
  required?: boolean;
  changed?: boolean;
}

export const errors = {
  en: {
    required: 'The field is required',
    incorrect: 'The field is incorrect'
  }
};

export const getFormData = (state: FormState) => {
  const data = new FormData;

  Object.entries(state).forEach(([key, obj]) => {
    if (!obj) return;

    let value = obj.value;

    if (key === 'file' && value) {
      Array.from(value as FileList).forEach((f: File) => {
        data.append('file', f)
      });
      return;
    }

    if (Array.isArray(value)) value = value[0];
    if (value === undefined || value === null) return;
    value = value.toString();

    data.set(key, value);
  });

  return data;
};

export const isError = (state: FormState) => {
  let isError = false;

  (Object.values(state) as FormStateItem[]).forEach((item) => {
    if (item.required && (!item.changed || item.error)) {
      isError = true;
    }
  });

  return isError;
};

export const joinMessage = (message: string | string[]) => Array.isArray(message) ? message.join(' ') : message;

export const checkErrors = async (error: Response, state: FormState): Promise<[string, FormState | undefined]> => {
  if (error.status === 400) {
    const response: FormResponse = (await error.json()) as FormResponse;

    if (response.errors) {
      const messages: string[] = [];

      const newState = {...state};
      for (const key in response.errors) {
        const errorField = response.errors[key];

        if (key in newState) {
          const message = joinMessage(errorField);
          messages.push(message);

          newState[key] = {
            ...newState[key],
            error: true,
            notice: message
          };
        }
      }

      return [messages.join(' / '), newState];
    } else if (response.response) {
      return [joinMessage(response.response), undefined];
    }
  } else if (error.status === 404) {
    try {
      const response: FormResponse = (await error.json()) as FormResponse;
      if (response.response) {
        return [joinMessage(response.response), undefined];
      }
    } catch (error) {
      console.error(error);
      // return [error.message, undefined];
    }
  }
  return [`${error.status} ${error.statusText}`, undefined];
};


type FormItemClasses = {
  error?: boolean;
  notice?: boolean;
  focus?: boolean;
  value?: boolean;
}
type Keys = keyof FormItemClasses;

const formItemClasses: { [key in Keys]: string } = {
  error: 'error',
  notice: 'notice',
  focus: 'focus',
  value: 'not-empty',
};

export const getFormItemClassName = (initialClass: string, props: FormItemClasses) => {
  const c = Object.entries(props).map(([key, value]) =>
    value && formItemClasses[key as Keys]).filter(Boolean);
  return [initialClass, ...c].join(' ');
};
