import React, {HTMLAttributes} from 'react';
import {classNames} from 'a-utils';
import css from './LabelTag.module.scss';
import {LabelTagType} from 'TYPES/types';

export type Props = HTMLAttributes<HTMLElement> & LabelTagType;

const LabelTag = ({className, text, isDate = false}: Props) => {
  return (
    <div className={classNames(className, css.tag, isDate && css.date)}>{text}</div>
  );
};

export default LabelTag;