import {useEffect, useState} from 'react';
import {isSafari} from 'a-utils';


export default function useVideoFormat() {
  const [isNotWebp, setIsNotWebp] = useState<boolean>(false);

  useEffect(() => {
    const navigator = window.navigator;
    const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
    setIsNotWebp(isSafari() && hasMediaCapabilities);

    return () => {
    };
  }, []);

  return isNotWebp;
}
