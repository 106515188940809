import styles from './Popup.module.scss';

import React, {useEffect} from 'react';
import {classNames} from 'a-utils';
import {Portal} from '../modal/Portal';
import useScrollDisable from 'HOOKS/useScrollDisable';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import css from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopup.module.scss';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';

export type Props = React.PropsWithChildren<{
  theme?: 'black' | 'white';
  active?: boolean;
  onClose?: () => void;
  className?: string;
  closeClassName?: string;
  closeChildren?: React.PropsWithChildren['children'];
  modifier?: string;
  videoPopup?: boolean;
}>;

export const Popup = ({
  theme,
  active,
  children,
  onClose,
  closeClassName,
  className,
  videoPopup = false,
  modifier,
}: Props) => {
  useScrollDisable(active);

  useGlobalDOMEvents({
    'keydown': (e) => {
      if (onClose && e.key && e.key.toLowerCase() === 'escape') onClose();
    }
  });

  useEffect(() => {
    if (active) {
      document.body.classList.add('popup-opened');
    } else {
      document.body.classList.remove('popup-opened');
    }
  }, [active])

  if (videoPopup) {
    return (
      <Portal
        className={classNames(styles.popup, styles.form, active && styles.active, className, theme && styles[theme])}>
        {/*<div className={styles.selfClose} onClick={onClose}/>*/}
        <div className={styles.wrapper}>
          {children}
        </div>
      </Portal>
    )
  }

  return (
    <Portal
      className={classNames(styles.popup, styles.form, modifier && styles[modifier], active && styles.active, className, theme && styles[theme])}>
      {/*<div className={styles.selfClose} onClick={onClose}/>*/}

      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <button className={classNames(styles.close, closeClassName, css.cross)} onClick={onClose}>
            <Icon id={'cross'}/>
          </button>

          {children}
        </div>
      </div>
    </Portal>
  )
}
