import React, {HTMLAttributes} from 'react';
import {JustLink} from 'TYPES/types';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import css from './Copyright.module.scss'
import {classNames} from 'a-utils';

type Props = HTMLAttributes<HTMLElement> & {
  license?: string;
  links?: JustLink[];
}

const Copyright = ({className, license, links}: Props) => {

  return (
    <div className={classNames(css.block, className)}>
      {license && <p className={css.copyright}>{license}</p>}
      <div className={css.links}>
        {links && links.map((link, i) => {
          return <SimpleLink
            key={`copy-${i}`}
            className={classNames(css.privacy)}
            href={link.link}>
            {link.text}
          </SimpleLink>
        })}
      </div>
    </div>
  );
};

export default Copyright;
