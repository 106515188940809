import React from 'react';
import css from './Logo.module.scss'

const Logo = () => {

  return (
    <svg viewBox="0 0 87 47" className={css.logo}>
      <path
        d="M73.5626 45.3967C73.6463 45.3967 73.7266 45.3634 73.7858 45.3042C73.845 45.245 73.8783 45.1647 73.8783 45.081V39.9841L62.9839 39.7531L70.0903 37.5281L70.275 34.2097L63.9694 32.8007L73.4086 30.7219V26.8338C73.398 26.7661 73.3662 26.7036 73.3178 26.6552C73.2694 26.6067 73.2068 26.5749 73.1392 26.5643H72.477C70.7293 26.4334 60.9281 26.3103 59.7194 26.241C59.6274 26.2411 59.539 26.277 59.473 26.3411C59.4394 26.3721 59.4136 26.4105 59.3975 26.4533C59.3815 26.4961 59.3757 26.542 59.3806 26.5874C59.5423 28.5815 59.5962 44.5729 59.7887 44.9193C59.8179 44.9734 59.8638 45.0167 59.9195 45.0425C60.0504 45.1118 72.2999 45.3505 73.5395 45.4044"
        fill='#121212'/>
      <path
        d="M59.4427 45.5565C59.1963 44.9483 52.9445 28.0638 52.5827 27.0244L52.3594 26.3623C52.3384 26.3023 52.3001 26.2499 52.2494 26.2116C52.1988 26.1732 52.1379 26.1507 52.0745 26.1467C52.0126 26.1435 51.9511 26.1586 51.8977 26.19C51.8443 26.2215 51.8014 26.2681 51.7743 26.3238C51.0813 27.6096 47.378 35.4166 46.993 36.2558C46.4849 34.8084 43.9133 28.2563 43.2974 26.3238C43.2763 26.2638 43.2381 26.2114 43.1874 26.1731C43.1368 26.1348 43.0759 26.1122 43.0125 26.1082C42.9483 26.1029 42.8841 26.1179 42.8288 26.1511C42.7736 26.1842 42.7301 26.2339 42.7045 26.293C42.3889 26.9705 34.5895 43.6548 33.9582 44.8482C33.9319 44.8938 33.918 44.9456 33.918 44.9983C33.918 45.051 33.9319 45.1028 33.9582 45.1485C33.9848 45.1936 34.0216 45.2318 34.0658 45.2599C34.11 45.288 34.1602 45.3052 34.2123 45.3101H39.6017L42.4736 39.2431L44.2675 44.6788L48.5637 44.9791L51.4509 40.4904L54.084 45.8799L59.127 46.0262H59.2194C59.2756 46.0158 59.3284 45.9921 59.3734 45.9569C59.4395 45.9089 59.4845 45.8372 59.4989 45.7568C59.5132 45.6764 59.4958 45.5936 59.4504 45.5257"
        fill='#121212'/>
      <path
        d="M35.5713 44.846C34.8861 43.0059 26.4939 27.6074 26.1551 26.8837C26.129 26.8336 26.0906 26.7911 26.0434 26.7601C25.9963 26.7291 25.942 26.7107 25.8857 26.7066C25.8287 26.7057 25.7724 26.7185 25.7215 26.7439C25.6706 26.7694 25.6265 26.8068 25.5931 26.8529C24.3535 28.8855 18.3019 42.0974 17.1239 44.5611C17.099 44.6073 17.0859 44.6589 17.0859 44.7113C17.0859 44.7637 17.099 44.8153 17.1239 44.8614C17.1492 44.9054 17.1848 44.9424 17.2278 44.9693C17.2708 44.9962 17.3197 45.012 17.3703 45.0154C18.6407 45.1386 21.6126 45.0924 21.6126 45.0924C21.6786 45.0965 21.7442 45.0793 21.7997 45.0432C21.8551 45.0072 21.8975 44.9542 21.9206 44.8922L22.6443 43.1214H29.3734L30.6823 45.1848C30.7109 45.2291 30.7495 45.266 30.795 45.2928C30.8404 45.3195 30.8915 45.3352 30.9441 45.3388C32.3145 45.3388 34.6936 45.3388 34.6936 45.3388C35.8177 45.3388 35.4635 45.2387 35.5251 45.154C35.5551 45.1107 35.5749 45.0612 35.5829 45.0092C35.5909 44.9572 35.5869 44.904 35.5713 44.8537M25.4776 36.0766L27.9568 40.2804L23.8377 40.111L25.4776 36.0766Z"
        fill='#121212'/>
      <path
        d="M86.0286 36.3443C85.713 36.1057 82.4716 33.6496 81.694 32.872C81.3888 32.5431 81.1902 32.1296 81.1243 31.6858C81.0583 31.242 81.1281 30.7886 81.3244 30.3851C82.2021 29.1302 86.5445 30.2619 86.5445 30.2619C86.5445 29.0994 86.6061 24.2796 86.6061 24.1025C86.6061 23.2864 86.5137 23.1555 85.8362 22.9631C85.6899 22.9246 85.5128 22.8784 85.3203 22.8091C84.1269 22.401 78.953 22.4087 77.29 23.579C76.6353 24.2103 76.1666 25.0093 75.9349 25.8888C75.4884 27.2361 75.1034 28.2447 75.7655 29.7384C76.2737 30.8625 81.4168 35.7977 80.824 36.9064C80.4123 37.7767 79.7322 38.4918 78.8837 38.9467C78.1138 39.424 75.4884 39.0699 75.5038 39.1623C75.5192 39.2547 75.5808 45.3217 75.7271 45.7836V45.8606C75.7271 45.9915 76.5586 46.0993 76.6433 46.1147C76.728 46.1301 79.4381 45.9992 79.4381 45.9992L80.7162 45.9222C81.7787 45.9222 86.198 44.3208 86.5291 43.035C86.8602 41.7492 87.0296 37.0373 86.044 36.3212"
        fill='#121212'/>
      <path
        d="M18.5427 33.724C17.1106 33.4622 12.2986 32.6923 11.2823 32.5152C11.1988 32.5012 11.1131 32.5206 11.0436 32.5691C10.975 32.6224 10.9284 32.6992 10.9127 32.7847V32.9155C10.7203 34.3784 10.6895 35.0636 10.8204 35.3639C10.9512 35.6642 13.7307 36.7421 14.054 36.9038L14.6007 37.1347C14.0617 37.1886 12.1523 37.204 11.829 37.1963C10.1505 37.0731 7.80225 36.7575 6.82444 36.0568C5.84664 35.3562 6.05452 34.8481 6.14691 33.3159C6.30089 31.1986 6.91683 30.19 8.78005 29.7589C10.9805 29.1995 13.239 28.8997 15.5092 28.8657C15.5929 28.8657 15.6732 28.8325 15.7324 28.7733C15.7916 28.7141 15.8249 28.6338 15.8249 28.5501C15.8249 28.5116 15.6709 26.5175 15.6786 25.4704C15.6787 25.4252 15.6692 25.3805 15.6506 25.3393C15.632 25.2982 15.6048 25.2615 15.5708 25.2317C15.5372 25.2026 15.498 25.1808 15.4556 25.1676C15.4132 25.1543 15.3686 25.1499 15.3244 25.1547L14.6392 25.2471C13.4104 25.3789 12.1895 25.5768 10.982 25.8399C9.15731 26.248 2.92092 28.373 1.81223 29.7743C1.19629 30.5442 0.988414 33.4699 0.718941 35.7489C0.41867 38.2665 0.233885 40.5532 0.842126 42.1084C1.45037 43.6637 9.61927 46.0274 10.9512 46.0274C11.3439 46.0274 15.6247 45.8965 15.6709 45.8888C15.7253 45.8849 15.7778 45.8673 15.8235 45.8377C15.8693 45.8081 15.9068 45.7674 15.9327 45.7194C15.9655 45.6521 15.9937 45.5826 16.0173 45.5115C16.2399 42.9721 16.3144 40.4219 16.2406 37.8739C16.7565 38.1202 17.2723 38.3512 17.8883 38.6053C17.933 38.6248 17.9816 38.6336 18.0302 38.6312C18.0789 38.6287 18.1264 38.615 18.1689 38.5912C18.2114 38.5673 18.2478 38.5339 18.2753 38.4936C18.3027 38.4533 18.3205 38.4072 18.3271 38.3589C18.458 37.3888 18.7429 34.5093 18.7891 34.1012C18.7983 34.0221 18.7772 33.9424 18.7301 33.8781C18.6829 33.8138 18.6133 33.7698 18.535 33.7548"
        fill='#121212'/>
      <path
        d="M10.805 11.5932C9.88168 11.0578 8.88592 10.6584 7.84851 10.4075V10.1842C9.46535 9.71454 9.59623 9.06781 10.728 7.78203C11.7443 6.63485 12.3911 5.98811 11.1592 4.32507C10.3893 3.19328 8.57993 1.68423 7.24026 1.55334C5.13534 1.38139 3.02273 1.32228 0.911487 1.37626C0.828421 1.38022 0.750071 1.41603 0.692719 1.47625C0.635367 1.53647 0.603421 1.61647 0.603516 1.69963C0.603516 2.9931 0.757498 21.8794 0.757498 21.8794C0.755864 21.924 0.763975 21.9685 0.781259 22.0097C0.798543 22.0509 0.82459 22.0879 0.857592 22.118C0.916125 22.1726 0.993176 22.2028 1.07317 22.2027C4.46854 21.9794 9.19588 21.8871 9.90421 21.5098C10.9744 20.9324 13.0301 17.8065 13.0378 16.3436C13.0378 15.027 11.3902 11.9473 10.805 11.5932ZM4.28376 4.90251C4.99469 4.86685 5.70528 4.9796 6.37026 5.23358C6.39303 5.84326 6.34131 6.45345 6.21626 7.0506C5.69261 7.29912 5.12458 7.44047 4.54553 7.46636C4.33765 7.46636 4.18367 5.21818 4.28376 4.90251ZM7.03239 17.2213C6.33542 17.6713 5.54588 17.9582 4.72261 18.0605C4.41464 18.0605 4.18366 14.6883 4.32995 14.2109C4.38384 14.0338 7.23256 14.5574 7.32496 14.8345C7.35442 15.6361 7.26622 16.4376 7.06318 17.2136"
        fill='#F05125'/>
      <path
        d="M46.1276 14.1181C46.1292 14.0734 46.1211 14.0289 46.1038 13.9877C46.0866 13.9465 46.0605 13.9096 46.0275 13.8794C45.9955 13.8472 45.9569 13.8223 45.9144 13.8063C45.8719 13.7904 45.8264 13.7838 45.7811 13.787C41.7313 14.1258 40.1607 14.3414 36.6498 14.4954C36.7653 12.1856 37.5891 2.73091 37.8047 0.921584C37.8149 0.87663 37.8154 0.830016 37.8061 0.784868C37.7968 0.73972 37.7779 0.69708 37.7508 0.659809C37.7218 0.623739 37.6856 0.594141 37.6445 0.572923C37.6034 0.551705 37.5583 0.539339 37.5121 0.536621C37.0117 0.536621 31.9071 0.613612 31.8147 0.613612C31.2296 0.613612 31.0448 0.698305 30.9678 0.782997C30.9364 0.806631 30.9124 0.838663 30.8985 0.875387C30.8292 0.998575 31.0448 21.3092 31.0448 21.3092C31.0551 21.5187 31.0808 21.7271 31.1218 21.9328C31.1436 21.9799 31.175 22.0219 31.2142 22.056C31.2942 22.1081 31.3793 22.152 31.4682 22.1869C31.5986 22.2028 31.7305 22.2028 31.8609 22.1869C32.2844 22.1869 37.1503 21.9405 37.9202 21.8481C38.3283 21.8019 40.7073 21.7557 41.485 21.6942C41.8237 21.6942 43.0248 21.648 43.2712 21.6942H43.3251C43.5638 21.6942 45.196 21.6942 45.3654 21.6249C45.4225 21.6063 45.4731 21.5718 45.5113 21.5254C45.5495 21.479 45.5736 21.4227 45.581 21.3631C45.581 21.2091 46.0814 15.5348 46.1353 14.1104"
        fill='#F05125'/>
      <path
        d="M57.7711 11.4386C57.6524 11.354 57.5637 11.2337 57.518 11.0953C57.4723 10.9568 57.472 10.8074 57.5171 10.6687C57.5171 10.4916 61.4822 4.13203 61.7594 3.73937L61.8748 3.50069C61.9054 3.43813 61.9145 3.3672 61.9005 3.29896C61.8866 3.23073 61.8505 3.16902 61.7979 3.12343C61.7517 3.06954 61.6978 3.01564 61.6285 2.92325C61.1687 2.45208 60.6513 2.04078 60.0886 1.69907L59.8422 1.52969C59.7707 1.48297 59.6836 1.46646 59.5999 1.48377C59.5162 1.50108 59.4428 1.55081 59.3957 1.62208C59.08 2.12253 53.9061 9.82948 53.9061 9.82948C53.9061 9.82948 52.8667 1.3603 52.7666 1.13703C52.7404 1.08837 52.7015 1.04768 52.6541 1.01923C52.6067 0.990777 52.5525 0.975618 52.4972 0.975342H52.4125C52.1122 0.975342 48.1163 1.58358 47.9854 1.62208C47.9103 1.65125 47.8476 1.7057 47.8083 1.77606C47.6851 2.01474 48.0547 19.5536 48.0624 21.2629C48.0634 21.3049 48.0727 21.3462 48.0899 21.3846C48.1071 21.4229 48.1318 21.4575 48.1625 21.4861C48.191 21.5166 48.2256 21.5405 48.2641 21.5565C48.3027 21.5724 48.3441 21.5799 48.3858 21.5785C50.8341 21.5323 52.9206 21.2937 55.0225 21.0627C55.105 21.053 55.1804 21.0112 55.2322 20.9464C55.2841 20.8815 55.3084 20.7988 55.2997 20.7162C55.2458 20.2158 54.9147 16.5124 54.6607 15.1189C55.9002 17.2208 57.494 19.2842 58.5103 21.3784C58.5336 21.4221 58.5665 21.46 58.6065 21.4893C58.6465 21.5186 58.6925 21.5386 58.7412 21.5477C58.7902 21.5584 58.8411 21.557 58.8894 21.5436C58.9377 21.5302 58.982 21.5052 59.0184 21.4707C60.3858 20.1571 61.6714 18.7609 62.868 17.29C62.868 17.0514 57.8481 11.5849 57.7788 11.5079"
        fill='#F05125'/>
      <path
        d="M28.0228 20.9796C28.1052 20.9796 28.1843 20.9474 28.2433 20.8899C28.3023 20.8323 28.3365 20.754 28.3385 20.6716V15.5747L16.8589 15.3361L23.9961 13.111L24.1809 9.7926L17.8598 8.36054L27.8688 6.28944V2.41672C27.8591 2.34947 27.8274 2.28733 27.7786 2.23998C27.7299 2.19263 27.6668 2.16274 27.5993 2.15494H27.3915H26.9757C25.2203 2.02406 15.4268 1.90857 14.2103 1.83928C14.1193 1.83647 14.0308 1.86967 13.964 1.93167C13.9322 1.96822 13.9089 2.01129 13.8956 2.05785C13.8822 2.10441 13.8793 2.15333 13.887 2.20114C14.041 4.19525 14.0949 20.1943 14.295 20.5407C14.3268 20.5928 14.372 20.6354 14.4259 20.6639C14.5491 20.7255 26.7986 20.9719 28.0382 21.0181"
        fill='#F05125'/>
      <path
        d="M81.9239 18.6406C81.2387 16.7773 72.8465 1.00157 72.5077 0.285544C72.4851 0.232873 72.4475 0.188011 72.3996 0.156529C72.3517 0.125047 72.2956 0.108332 72.2382 0.108462C72.1821 0.106014 72.1264 0.118215 72.0764 0.143865C72.0265 0.169515 71.9841 0.207731 71.9534 0.254749C70.7061 2.28735 64.462 15.8919 63.2763 18.3403C63.2514 18.385 63.2383 18.4354 63.2383 18.4866C63.2383 18.5378 63.2514 18.5881 63.2763 18.6329C63.3011 18.6778 63.3366 18.716 63.3795 18.7442C63.4224 18.7724 63.4716 18.7897 63.5227 18.7945C64.7931 18.9177 67.765 18.8638 67.765 18.8638C67.8305 18.8711 67.8966 18.8561 67.9524 18.8212C68.0083 18.7862 68.0508 18.7334 68.073 18.6713L68.7967 16.9005H75.5335L76.8424 18.9639C76.8687 19.008 76.9059 19.0446 76.9504 19.0702C76.9949 19.0958 77.0452 19.1096 77.0965 19.1102L81.639 19.064C81.6897 19.0632 81.7395 19.0509 81.7848 19.0283C81.8301 19.0056 81.8697 18.9731 81.9008 18.9331C81.9312 18.89 81.9506 18.8401 81.9574 18.7878C81.9641 18.7354 81.9579 18.6822 81.9393 18.6329M71.63 9.84801L74.1092 14.0595L69.9978 13.8901L71.63 9.84801Z"
        fill='#F05125'/>
    </svg>
  );
};

export default Logo;
