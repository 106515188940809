import React, {HTMLAttributes} from 'react';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {JustLink} from 'TYPES/types';
import {classNames} from 'a-utils';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';

const BackLink = ({className, link, text}: HTMLAttributes<HTMLElement> & JustLink) => {
  return (
    <SimpleLink href={link}
      className={classNames(className, 'button-transparent', 'button-transparent--orange', `button--with-icon`)}>
      <span className={'button-transparent__icon'}><Icon id={'arrow-left'}/></span>
      <span className={'button-transparent__text small-links'}>{text}</span>
    </SimpleLink>
  );
};

export default BackLink;