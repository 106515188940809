import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

type T_Props = React.PropsWithChildren<{
  className?: string;
  tag?: string;
}>

/**
 * Если нужен SSR, то:
 * https://github.com/jesstelford/react-portal-universal/
 */

export const Portal = ({ children, className = 'root-portal', tag = 'div' }: T_Props) => {
  const [container, setContainer] = useState<HTMLElement>();

  useEffect(() => {
    const container = document.createElement(tag);
    container.className = className;

    setContainer(container);

    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    }
  }, [tag, className]);

  if (!container) return null;

  return ReactDOM.createPortal(children, container);
}