import React, {useEffect, useState} from 'react';
import {JustLink, NavigationLink, SocialLink} from 'TYPES/types';
import Navigation from 'COMPONENTS/pages-components/common/header/Navigation/Navigation';
import Contacts from 'COMPONENTS/pages-components/common/footer/Contacts/Contacts';
import Social from 'COMPONENTS/pages-components/common/Social/Social';
import Copyright from 'COMPONENTS/pages-components/common/footer/Copyright/Copyright';
import FooterTextInfo from 'COMPONENTS/pages-components/common/footer/FooterTextInfo/FooterTextInfo';
import {PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';
import RedCollar from 'COMPONENTS/pages-components/common/footer/RedCollar/RedCollar';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import css from './Footer.module.scss';
import {classNames} from 'a-utils';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';

export type FooterProps = {
  nav?: NavigationLink[];
  contacts?: {
    title?: string;
    link?: JustLink;
  };
  social?: SocialLink[];
  copyright?: {
    license?: string;
    links?: JustLink[];
  };
  footerTextInfo?: {
    primitives?: PrimitivesProps;
  };
  redcollar?: JustLink;
}

const isMobileCheck = () => (window.innerWidth < 768);
const isTabletCheck = () => (window.innerWidth < 1025);


const Footer = ({nav, contacts, social, copyright, footerTextInfo, redcollar}: FooterProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const resize = () => {
    setIsMobile(isMobileCheck());
    setIsTablet(isTabletCheck());
  }

  useGlobalDOMEvents({resize});

  useEffect(() => {
    resize();
  }, [])


  if (isMobile) {
    return (
      <footer className={css.footer}>
        <div className={classNames(css.wrap, 'container container--large')}>
          {nav && <Navigation className={css.navigation} navigationList={nav} footerNavigation/>}
          {contacts && <Contacts className={css.contacts} {...contacts}/>}
          {social && <Social className={css.social} social={social}/>}
          {footerTextInfo && <FooterTextInfo className={css.primitive} {...footerTextInfo}/>}
          {copyright && <Copyright className={css.copyright} {...copyright}/>}
          {redcollar && <RedCollar className={css.rc}{...redcollar}/>}
        </div>
      </footer>
    )
  }


  if (isTablet) {
    return (
      <footer className={css.footer}>
        <div className={classNames(css.wrap, 'container')}>
          <div className={css.tabletrow}>
            <div className={css.tabletcolumn}>
              {contacts && <Contacts className={css.contacts} {...contacts}/>}
              {social && <Social className={css.social} social={social}/>}
            </div>
            <div className={css.tabletcolumn}>
              {nav &&
                <Navigation className={css.navigation} navigationList={nav} footerNavigation/>}
            </div>
          </div>
          <div className={css.tabletrow}>
            <div className={css.tabletcolumn}>
              {copyright && <Copyright className={css.copyright} {...copyright}/>}
            </div>
            <div className={css.tabletcolumn}>
              {footerTextInfo && <FooterTextInfo className={css.primitive} {...footerTextInfo}/>}
              {redcollar && <RedCollar className={css.rc}{...redcollar}/>}
            </div>
          </div>
        </div>
      </footer>
    )
  }

  return (
    <footer className={css.footer}>
      <div className={classNames(css.wrap, 'container container--large')}>
        <div className={css.desktoprow}>
          <div className={css.desktopcolumn}>
            {contacts && <Contacts className={css.contacts} {...contacts}/>}
          </div>
          <div className={css.desktopcolumn}>
            {social && <Social className={css.social} social={social}/>}
          </div>
          <div className={css.desktopcolumn}>
            {nav && <Navigation className={css.navigation} navigationList={nav} footerNavigation/>}
          </div>
        </div>
        <div className={css.desktoprow}>
          <div className={css.desktopcolumn}>
            {copyright && copyright.license && <div className={css.copyright}>{copyright.license}</div>}
          </div>
          <div className={css.desktopcolumn}>
            {footerTextInfo && <FooterTextInfo className={css.primitive} {...footerTextInfo}/>}
          </div>
          <div className={css.desktopcolumn}>
            {copyright && <div className={css.links}>
              {copyright.links && copyright.links.map((link, i) => {
                return <SimpleLink
                  key={`copy-${i}`}
                  className={classNames(css.privacy, 'link')}
                  href={link.link}>
                  {link.text}
                </SimpleLink>
              })}
            </div>
            }
            {redcollar && <RedCollar className={css.rc} {...redcollar}/>}
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
