import {useEffect} from 'react';

let isHidden = false;

const destroyHidden = () => {
  if (isHidden) {
    document.documentElement.style.overflow = '';
    document.body.style.marginRight = '';
    // document.body.style.overflow = '';
    // document.body.style.marginTop = '';
    // document.body.scrollTop = 0;
    isHidden = false;
  }
}

export default function useScrollDisable(active?: boolean) {
  useEffect(() => {
    if (active) {
      if (!isHidden) {
        // const scrolled = window.scrollY;

        const widthBefore = document.body.clientWidth;
        document.documentElement.style.overflow = 'hidden';
        const widthAfter = document.body.clientWidth;
        document.body.style.marginRight = `${widthAfter - widthBefore}px`;
        // document.body.style.overflow = 'hidden';
        // document.body.style.marginTop = scrolled + 'px';
        // document.body.scrollTop = scrolled;
        isHidden = true;
      }
    } else {
      destroyHidden();
    }

    return () => {
      destroyHidden();
    }
  }, [active]);
}

