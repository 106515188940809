import React from 'react';
import {Popup} from 'COMPONENTS/ui/popup/Popup';
import {classNames} from 'a-utils';
import css from './ErrorModal.module.scss';

type Props = {
  title?: string;
}

export const ErrorModal = ({title}: Props) => {

  return (
    <Popup active modifier={'error'}>
      <div className={css.wrap}>
        {title ? title : <h4 className={css.title}>Sorry, the technical error has occurred</h4>}
        <p>You can try reloading the page</p>
        <button
          className={classNames(css.reload, 'button', 'large', 'orange')}
          onClick={() => window.location.reload()}>
          <span className={'button-text'}>reload</span>
        </button>
      </div>
    </Popup>
  );
};
