import styles from './Primitives.module.scss';

import React, {HTMLAttributes} from 'react';
import {RawHTML} from '../primitives/RawHTML';
import {Wrapper} from '../wrapper/Wrapper';

type Props = {
  children?: string | string[];
  className?: string;
}

const List = ({children}: Props) => <ul>
  {
    Array.isArray(children) ?
      children.map((t, i) => <li key={`li-${i}`}>{t}</li>) :
      <li>{children}</li>
  }
</ul>

type PrimitiveTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'blockquote';
type CompositeTag = 'bigP' | 'largeP' | 'ul' | 'html';

export type Primitive = {
  type: PrimitiveTag | CompositeTag;
  isDiv?: boolean;
  content?: string | string[];
}
export type PrimitivesProps = HTMLAttributes<unknown> & {
  items?: Primitive[];
  className?: string;
}


export const Primitives = ({items = [], className}: PrimitivesProps) => {

  return <>
    {items.map(({type, content, isDiv}, i) => {
      if (!type) throw new Error(`"type" is not defined`);

      let Child: React.FunctionComponent<Props> | string = type;
      let classResult = className;

      if (isDiv) {
        Child = 'div';
        classResult = type;
      }

      switch (type) {
        case 'bigP':
          Child = 'p';
          classResult = 'big-text';
          break;
        case 'largeP':
          Child = 'p';
          classResult = 'h2';
          break;
        case 'ul':
          Child = List;
          break;
        case 'html':
          Child = RawHTML;
          break;
      }

      return <Child key={`ch-${i}`} className={classResult}>{content}</Child>
    })}
  </>
}


export const PrimitivesSection = (props: PrimitivesProps) => {
  return <section className={styles.section}>
    <Wrapper>
      <Primitives {...props}/>
    </Wrapper>
  </section>
}
