import {RefObject, useEffect, useState} from 'react';

const useVideoPlayer = (videoElement: RefObject<HTMLVideoElement>) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isMuted: false,
    // isFullscreen: false,
    progress: 0,
  });

  const togglePlay = (boolean: boolean) => {
    setPlayerState({
      ...playerState,
      isPlaying: boolean
    });
  };

  useEffect(() => {
    if (videoElement.current) {
      playerState.isPlaying
        ? videoElement.current.play().then(() => '').catch(() => '')
        : videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    if (videoElement.current) {
      const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;

      setPlayerState({
        ...playerState,
        progress,
      });
    }
  };

  const handleVideoProgress = (offset: number) => {
    if (videoElement.current) {
      const manualChange = Number(offset);
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
      setPlayerState({
        ...playerState,
        progress: offset,
      });
    }
  };


  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  useEffect(() => {
    if (videoElement.current) {
      playerState.isMuted
        ? (videoElement.current.muted = true)
        : (videoElement.current.muted = false);
    }
  }, [playerState.isMuted, videoElement]);


  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
  };
};

export default useVideoPlayer;
