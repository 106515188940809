import css from './ErrorPage.module.scss';

import Head from 'next/head';
import React from 'react';
import {Button} from 'COMPONENTS/pages-components/common/Button/Button';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';
import {useRouter} from 'next/router';


export type Props = {
  statusCode: string | number;
}

const ErrorPage = ({statusCode} : Props) => {
  const router = useRouter();
  const arr = router.asPath.split('/')
  let careers = false;
  let vacancy = false;

  arr.map(el => {
    if (el === 'careers') {
      careers = true
    }
    if (el === 'vacancy') {
      vacancy = true
    }
  })
  if (careers && vacancy) {
    void router.push('/careers/vacancy')
    return null
  }

  return (
    <section className={css.wrap}>
      <Head>
        <meta name='robots' content='noindex'/>
        <title>{statusCode}</title>
      </Head>

      {statusCode === 404 ?
        <div>
          <h1 className={css.code}>4
            <SquirrelVideo className={css.video} name={'wheel'}/>
            4</h1>
          <h4>Sorry, there&apos;s nothing here</h4>
          <Button link={'/'} text={'Go to main'}/>
        </div>
        :
        <div>
          <h1 className={css.code}>5
            <SquirrelVideo className={css.video} name={'wheel'}/>
            5</h1>
          <h4>Sorry, something is wrong with the server</h4>
          <Button link={'/'} text={'Go to main'}/>
        </div>
      }
    </section>
  );
};

export default ErrorPage;
