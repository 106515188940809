import React, {Fragment, useEffect, useState} from 'react';
import css from './GameCommunity.module.scss'
import Image from 'next/image';
import {classNames} from 'a-utils';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {IconId} from 'COMPONENTS/pages-components/common/Icon/Sprite';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';

export type GameCommunityProps = {
  title: string;
  socials:{
    title: string,
    iconType: IconId
    link: string
  }[]
}

export const GameCommunity = ({title, socials}: GameCommunityProps) => {
  const [size, setSize] = useState(0);
  const [titleArr, setTitleArr] = useState<string[]>([]);

  useEffect(() => {
    setTitleArr(title.split(' '))
  }, [title])

  // Получение размера страницы
  useEffect(() => {

    function updateSize() {
      setSize(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const imageBlock = <p className={css.squirrel}>
    <Image
      className={css.squirrelAnimation}
      width={170}
      height={170}
      alt={''}
      src={'/games/community.svg'}
      priority={true}
      quality={90}/>
    <SquirrelVideo className={css.squirrelImg} name={'hi2'}/>
  </p>

  return (
    <section className={css.main}>
      <div className={'container'}>
        <h2 className={classNames(css.title, 'h1')}>
          {size < 768 && <>
            {imageBlock}
            <p className={css.divider}/>
            {titleArr.map(el => <span key={el} >{el}</span>)}
          </>
          }
          {
            size >= 768 && size < 1280 ?
              titleArr.map((el, index) => {
                if(index === 0) return <Fragment key={el}><span >{el}</span> {imageBlock}</Fragment>
                else return <span key={index} >{el}<br/> </span>
              })
              : <></>
          }
          {
            size >= 1280 && <>
              {titleArr.map(el => <span key={el} >{el}</span>)} {imageBlock}
            </>
          }
        </h2>
        <div className={css.media}>
          {socials.map((el, index) =>
            <SimpleLink href={el.link} key={index} className={css.item} isExternal>
              <h4 className={css.itemTitle}>{el.title}</h4>
              <div className={css.itemSoc}>
                <span className={css.inner}>{el.iconType && <Icon id={el.iconType}/>}</span>
              </div>
            </SimpleLink>
          )}
        </div>
      </div>
    </section>
  );
};
