import React, {useEffect, useState} from 'react';
import Image from 'next/image';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import css from './ContactsHeading.module.scss';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {JustLink} from 'TYPES/types';
import {classNames} from 'a-utils';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';
import { motion } from 'framer-motion';

const isMobileCheck = () => (window.innerWidth < 768);

export type Props = {
  sign: JustLink;
}

export const ContactsHeading = ({sign}: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  //const [isTablet, setIsTablet] = useState(false);

  const resize = () => {
    setIsMobile(isMobileCheck());
    // setIsTablet(isTabletCheck());
  }

  useGlobalDOMEvents({resize});

  useEffect(() => {
    resize();
  }, [])

  const imageBlock = <p className={css.squirrel}>
    <Image
      className={css.squirrelAnimation}
      width={170}
      height={170}
      alt={''}
      src={'/contacts/contact.svg'}
      priority={true}
      quality={90}/>
    <SquirrelVideo className={css.squirrelImg} name={'hi'}/>
  </p>

  if (isMobile) {
    return (
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={classNames(css.block, 'container')}
      >
        <div className={css.wrap}>
          <h1 className={css.title}>
            {imageBlock}
            <span className={css.title}>Get in touch</span>
          </h1>
          {sign && <div className={classNames(css.sign, 'hand')}>
            <SimpleLink href={sign.link}><span>{sign.text}</span></SimpleLink>
          </div>
          }
        </div>
      </motion.section>
    )
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.block, 'container')}
    >
      <div className={css.wrap}>
        <h1 className={classNames(css.title)}>
          <span className={css.title}>Get in</span>
          {imageBlock}
          <span className={css.title}>touch</span>
        </h1>
        {sign && <div className={classNames(css.sign, 'hand')}>
          <SimpleLink href={sign.link}><span>{sign.text}</span></SimpleLink>
        </div>
        }
      </div>
    </motion.section>
  );
};
