import React, {useState} from 'react';
import {FormItemProps, getFormItemClassName} from 'COMPONENTS/ui/form/form-utils';
import styles from 'COMPONENTS/ui/form/radio/Radio.module.scss';
import {useDispatch} from 'STORE/index';
import {setActiveRadio} from 'STORE/slices/Options';
import {classNames} from 'a-utils';

type T_Props = FormItemProps<string> & {
  label?: string;
  items: { label?: string, value: string }[];
}

export const Radio = ({items, defaultValue = items[0].value, name = `radio-${Math.random()}`, ...props}: T_Props) => {
  const [active, setActive] = useState(props.value || defaultValue);
  const a = props.value !== undefined ? props.value : active;
  const dispatch = useDispatch();
  const className = getFormItemClassName('field-wrapper radio-wrapper', {
    error: props.error,
    notice: !!props.notice,
    value: !!active
  });

  return (
    <fieldset className={classNames(styles.radio,className)}>
      {/*<span className={fieldStyles.fieldLabel}>{props.label}</span>*/}
      {items.map((item, index) => {
        const itemLabel = item.label && <span className={styles.label}>{item.label}</span>

        const onChange = () => {
          setActive(item.value);

          dispatch(setActiveRadio(item.value));
          if (props.onChange) props.onChange(item.value, true, name);
        }

        return (
          <label key={`radioitem-${index}`} className={styles.item}>
            <input
              className={'visually-hidden'}
              type='radio'
              disabled={props.disabled}
              checked={a === item.value}
              name={name}
              value={item.value}
              onChange={onChange}
            />
            <span className={styles.circle}/>
            {itemLabel}
          </label>
        )
      })}
    </fieldset>
  )
}
