import React, {useEffect, useState} from 'react';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {SocialLink} from 'TYPES/types';
import Image, {ImageProps} from 'next/image';
import css from './GameHeading.module.scss'
import Social from 'COMPONENTS/pages-components/common/Social/Social';
import {classNames} from 'a-utils';
import { motion } from 'framer-motion';

export type GameHeadingProps = {
  title: string,
  description: string,
  link?: string,
  webstoreLink?: string,
  imageDesktop: ImageProps;
  imageWebstore?: ImageProps;
  imageMobile: ImageProps;
  social: SocialLink[];
}

export const GameHeading = ({title, imageDesktop, imageMobile, imageWebstore, description, social, link, webstoreLink }: GameHeadingProps) => {
  const [size, setSize] = useState(0);

  // Получение размера страницы
  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);


  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames( css.main, 'container')}
    >
      <h1 className={css.heading} dangerouslySetInnerHTML={{__html:title}}/>
      {size < 768 && imageMobile.src && <Image
        className={css.image}
        src={imageMobile.src}
        alt={imageMobile.alt ? imageMobile.alt : `gameImg`}
        width={imageMobile.width}
        height={imageMobile.height}
      />}

      {size >= 768 && imageDesktop.src && <Image
        className={css.image}
        src={imageDesktop.src}
        alt={imageDesktop.alt ? imageDesktop.alt : `gameImg`}
        width={imageDesktop.width}
        height={imageDesktop.height}
      />}
      <div className={css.info}>
        <div className={css.social}>
          <span className={classNames( css.socialTitle,'small-links')}>Download game</span>
          <div>
            <Social social={social}/>
          </div>
          {webstoreLink!=undefined && <div style={{textAlign:'left'}}>
            <SimpleLink isExternal={true} href={webstoreLink} className={`webstore-link`} >
              <span>
                {imageWebstore?.src!=undefined && imageWebstore.src && <Image
                  src={imageWebstore.src}
                  alt={imageWebstore.alt ? imageWebstore.alt : `gameImg`}
                  width={imageWebstore.width}
                  height={imageWebstore.height}
                />}
              </span>
            </SimpleLink>
          </div>}

        </div>
        <div className={css.about}>
          <div className={css.description}>{description}</div>
          {link && <SimpleLink href={link} className={classNames( 'button orange large', css.button)} isExternal>
            <span className={'button-text'}>PLAY NOW</span>
          </SimpleLink>
          }
        </div>
      </div>
    </motion.section>
  );
};
