import React, {useEffect, useRef, useState} from 'react';
import {JustLink, NewsProps} from 'TYPES/types';
import css from './MainNews.module.scss';
import {JSInstance} from './JSInstance'
import {Swiper, SwiperSlide} from 'swiper/react';
import {NewsCard} from 'COMPONENTS/pages-components/common/NewsCard/NewsCard';
import {classNames} from 'a-utils';
import {Button} from 'COMPONENTS/pages-components/common/Button/Button';
import {useIsVisible} from 'HOOKS/useIsVisible';

export type mainNewsProps = {
	title: string,
  news: NewsProps[],
  button?: JustLink,
};


export const MainNews = ({ title, news, button }: mainNewsProps) => {
  //ширина экрана, чтобы знать сколько слайдов показать
  const [width, setWidth] = useState(320);
  // штука для анимации появления
  const blockRef = useRef<HTMLDivElement | null>(null)
  const active = useIsVisible(blockRef, width > 768 ? 0.4 : 0);
  const [blockViewed, setBlockViewed] = useState(false);

  const [section, setSection] = useState<HTMLElement | null>(null);
  //сюда пихаем движущиеся линии
  const [lines, setLines] = useState<HTMLElement | null>(null);
  //здесь лежит пример, чтобы раз дублировать тексты
  const [oneElem, setOneElem] = useState<HTMLElement | null>(null);

  // магия происходит здесь:
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  // когда проскроллили блок до конца, показать слайдер
  const [showBtn, setShowBtn] = useState(false)

  useEffect(() => {
    if (active) setBlockViewed(true);
  }, [active])

  useEffect(() => {
    if (!lines || !title || !oneElem || !section) return;

    const instance = new JSInstance(section, lines, oneElem);
    setInstanceJS(instance)
    setWidth(window.innerWidth)
    instance.init();

    return () => {
      instance.destroy();
    };
  }, [lines, title, oneElem, section]);


  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw,showBtn) => {
      setWidth(vw)
      setShowBtn(showBtn)
      //setShowFullSlider(scrolledBottom)
    });
  }, [instanceJS]);




  return (
    <section ref={setSection} className={classNames(css.main, blockViewed && css.active, showBtn ? css.visible : '')}>
      <h2 className={'visually-hidden'}>{title}</h2>

      <div ref={blockRef}  className={css.sticky}>
        <div className={css.example}>
          <span ref={setOneElem}>{title}</span>
        </div>
        <div ref={setLines} className={css.lines} />


        <div className={classNames('container', css.wrap)}>
          {
            width > 1280 ?
              <div className={css.news}>
                {news.map((newsItem, index) => {
                  return <NewsCard className={css.newsItem} key={`news-card-${index}`} {...newsItem}/>
                })}
              </div>
              :
              <Swiper
                threshold={0.6}
                className={css.slider}
                centeredSlides={true}
                loop={true}
                loopAdditionalSlides={3}
                spaceBetween={8}
                initialSlide={1}
                slidesPerView={1}
              >
                {news.map((newsItem, index) => {
                  return <SwiperSlide key={`news-card-${index}`}>
                    <NewsCard {...newsItem}/>
                  </SwiperSlide>})}
              </Swiper>
          }

          {button && <div className={classNames(css.more, 'more')}>
            <Button text={button.text} link={button.link}/>
          </div>}
        </div>
      </div>
    </section>
  );
};
