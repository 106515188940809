import React, {useEffect, useState} from 'react';
import {FormItemProps, FormStateItem, getFormItemClassName} from 'COMPONENTS/ui/form/form-utils';
import {useFormContext} from 'COMPONENTS/ui/form/context/FormContext';
import {classNames} from 'a-utils';
import css from 'COMPONENTS/ui/form/file/InputFile.module.scss';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';


export type T_Props = FormItemProps<null | FileList> & {
  label?: string;
  lang?: string;
  maxSize?: number;
  maxLength?: number;
  list?: string[];
}


const maxSize = 5 * 1024 * 1024;
const fileTypeList = ['pdf', 'jpg', 'jpeg'];


const errors = {
  size: `File size is more then 5Mb bytes`,
  type: `Please select a valid file: ${fileTypeList.join(', ')}`,
  length: 'Exceeded the maximum number of files - 5',
  empty: 'Select a file'
}

const acceptFiles = fileTypeList.map(t => '.' + t).join(', ');


export const InputFile = ({
  disabled,
  onChange,
  onComplete,
  error,
  notice,
  name,
  label,
  required
}: T_Props) => {
  const [localError, setLocalError] = useState(false);
  const [localNotice, setLocalNotice] = useState<string | null>(null);
  const [stateValue, setStateValue] = useState<FileList | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const context = useFormContext();

  const setDefaultStateItem = context.setDefaultStateItem;
  let contextItemState: FormStateItem | undefined;
  if (context.isForm) {
    onChange = context.onChange;
    onComplete = context.onComplete;
    disabled = disabled || context.isSending;

    contextItemState = context.state[name];
    if (contextItemState) {
      error = localError || error || contextItemState.error;
      notice = localNotice || notice || (contextItemState.error && (contextItemState.notice || errors.empty));
    }
  }

  useEffect(() => {
    setDefaultStateItem && setDefaultStateItem(name, {
      value: null,
      required,
      error
    });
  }, [error, name, required, setDefaultStateItem]);

  const onElemChange = ({target}: React.ChangeEvent<HTMLInputElement>) => {
    let isAccepted = true;
    const errorNotices: string[] = [];
    let value: FileList | null;

    if (target.files && target.files[0]) {
      if (target.files.length > 5) {
        isAccepted = false;
        errorNotices.push(errors.length);
      } else {
        Array.from(target.files).forEach((file) => {
          const fileType = file.name.split('.').pop() || '';

          if (!fileTypeList.includes(fileType)) {
            isAccepted = false;
            errorNotices.push(`"${file.name}" - ${errors.type}`)
          } else if (file.size > maxSize) {
            isAccepted = false;
            errorNotices.push(`"${file.name}" - ${errors.size}`)
          }
        });
      }

      if (isAccepted) {
        setStateValue(target.files);
        value = target.files;
      } else {
        setStateValue(null);
        value = null;
      }
    } else {
      setStateValue(null);
      value = null;
    }

    onChange && onChange(value, Boolean(value), name);
    onComplete && onComplete(Boolean(value), name);
    setLocalError(!isAccepted);
    setLocalNotice(errorNotices.join(', ') || '');
  }

  const onClear = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    setStateValue(null);
    setLocalError(false);
    setLocalNotice('');
    onChange && onChange(null, false, name);
    onComplete && onComplete(false, name);
    setFileName('')
  }

  const className = getFormItemClassName(css.wrapper, {
    error,
    notice: Boolean(notice),
    value: Boolean(stateValue)
  });

  useEffect(() => {
    stateValue && setFileName(Array.from(stateValue).map((file) => file.name).join(', '))

  }, [stateValue])

  return (
    <label className={classNames(className, 'text-input', fileName.length > 0 && css.fill)}>
      <span className={classNames(css.button)}>
        {stateValue ?
          <>
            <span className={css.clear} onClick={onClear}>CLEAR</span>
            <span className={css.names}>
              {fileName}
            </span>
          </>
          :
          <>
            <span className={css.icon}></span>
            <span className={css.label}>{label}</span>
          </>
        }
        <span className={css.warning}>
          <Icon id={'warning'}/>
          <span className={css.notice}>{notice}</span>
        </span>
      </span>
      <span className={css.additional}>
        <input
          type='file'
          accept={acceptFiles}
          disabled={disabled}
          onChange={onElemChange}
        />
        {/*<span className={css.sign}>Maximum file size 5 mb (pdf, doc, jpg)</span>*/}
      </span>
    </label>
  )
}
