import React, {HTMLAttributes, useEffect, useState} from 'react';
import {NavigationLink} from 'TYPES/types';
import MenuItem from 'COMPONENTS/pages-components/common/header/MenuItem/MenuItem';
import css from './Menu.module.scss';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import {classNames} from 'a-utils';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';

type Props = HTMLAttributes<HTMLUListElement> & {
  navigationList?: NavigationLink[];
  isTriggered?: boolean
}

const Menu = ({navigationList, isTriggered = false}: Props) => {
  //const [isMobile, setIsMobile] = useState<boolean | null>(false);
  const [menu, setMenu] = useState<HTMLElement | null>(null);

  const resize = () => {
    //setIsMobile(window.innerWidth < 1025);
  }

  useEffect(() => {
    if (isTriggered) {
      if (menu) disableBodyScroll(menu);
      document.body.classList.add('body-triggered');
    } else {
      if (menu) clearAllBodyScrollLocks();
      document.body.classList.remove('body-triggered');
    }

    return () => {
      if (menu) clearAllBodyScrollLocks();
      document.body.classList.remove('body-triggered');
    }

  }, [menu, isTriggered]);

  useEffect(() => {
    resize();
  }, [])

  useGlobalDOMEvents({resize});

  return (
    <div className={classNames(css.menuWrap, isTriggered && css.opened)}>
      <ul className={css.menu} ref={setMenu}>
        {navigationList?.map((navLink, index) => <MenuItem key={`nav-link-${index}`} {...navLink}/>)}
      </ul>
    </div>
  );
};

export default Menu;
