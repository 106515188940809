import React, {HTMLAttributes} from 'react';
import css from './Navigation.module.scss';
import {NavigationLink} from 'TYPES/types';
import NavItem from 'COMPONENTS/pages-components/common/header/NavItem/NavItem';
import {classNames} from 'a-utils';

type Props = HTMLAttributes<HTMLUListElement> & {
  navigationList?: NavigationLink[];
  footerNavigation?: boolean;
}


const Navigation = ({navigationList, footerNavigation = false}: Props) => {
  return (
    <ul className={classNames(css.navigation, footerNavigation && css.footerNav)}>
      {navigationList?.map((navLink, index) =>
        <NavItem key={`nav-link-${index}`} {...navLink}/>
      )}
    </ul>
  );
};

export default Navigation;
