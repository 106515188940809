import React from 'react';
import {Video} from 'TYPES/types';
import {VideoPopupYoutube} from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopupYoutube';
import {VideoPopupFile} from 'COMPONENTS/pages-components/common/VideoPopup/VideoPopupFile';


export type VideoProps = {
  active?: boolean;
  video?: Video;
  onClose?: () => void;
}

const VideoPopup = ({...props}: VideoProps) => {
  if (!props.video) return null;

  if (props.video.type === 'youtube') {
    return <VideoPopupYoutube {...props}/>
  } else {
    return <VideoPopupFile {...props}/>
  }
};

export default VideoPopup;
