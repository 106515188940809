import React, {useEffect, useState} from 'react';
import {NavigationLink} from 'TYPES/types';
import {classNames} from 'a-utils';
import css from './MenuItem.module.scss';
import MenuItemDropdown from 'COMPONENTS/pages-components/common/header/MenuItemDropdown/MenuItemDropdown';
import {useRouter} from 'next/router';
import NavLink from 'COMPONENTS/ui/NavLink/NavLink';

const MenuItem = ({link, text, dropdown}: NavigationLink) => {
  const router = useRouter();
  // const {isReady} = useRouter();
  const [dropdownVisible, showDropdown] = useState(false);

  useEffect(() => {
    showDropdown(false);
  }, [router.asPath])


  return (
    <li className={classNames(css.item, dropdown && css.itemDropdown, dropdownVisible && css.itemOpened)}>
      <div className={css.wrap}>
        <NavLink href={link} activeClassName={css.active} className={classNames(css.link)}><span>{text}</span></NavLink>
        {/*dropdown && <button className={css.icon} onClick={() => showDropdown(!dropdownVisible)}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.67257 8.73352L6.45209 2.89189C7.25229 1.91386 8.74771 1.91386 9.54791 2.89189L14.3274 8.73352C15.3959 10.0394 14.4668 12 12.7795 12L3.22048 12C1.53323 12 0.604138 10.0394 1.67257 8.73352Z"/>
          </svg>
        </button>*/}
      </div>
      {dropdown &&
        <MenuItemDropdown className={classNames(css.dropList, dropdownVisible && css.active)} list={dropdown}/>}
    </li>
  );
};

export default MenuItem;
