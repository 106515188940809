import React, {useEffect, useState} from 'react';

import {http} from 'a-utils';
import {FormProvider} from './context/FormContext';
import {
  checkErrors,
  FormInputValue,
  FormProps,
  FormResponse,
  FormState,
  FormStateItem,
  getFormData,
  isError
} from './form-utils';
import {useSelector} from 'STORE/index';


// <Form<FormResponse<{token: string}>> ...></Form>;

export const Form = <R extends FormResponse<unknown> = FormResponse>({
  id,
  children,
  noRadio = false,
  className,
  action = '',
  ...props
}: FormProps<R>) => {
  // const dispatchNotice = useNotice();
  const [state, setState] = useState<FormState>({});
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { activeRadio } = useSelector((state) => state.Options);

  // validate
  useEffect(() => {
    const error = isError(state);
    setIsValid(!error);
  }, [state]);


  const setDefaultStateItem = (name: string, stateItem: FormStateItem) => {
    if (name in state) return;
    setState(state => ({
      ...state,
      [name]: stateItem
    }));
  }
  const onChange = (value: FormInputValue, isComplete: boolean, name: string) => {
    setState(state => {
      const item = state[name];
      return {
        ...state,
        [name]: {
          ...item,
          value,
          error: item && item.required && item.error ? !isComplete : false,
          changed: true
        }
      }
    });
  }
  const onComplete = (isComplete: boolean, name: string) => {
    setState(state => {
      const item = state[name];
      return {
        ...state,
        [name]: {
          ...item,
          error: item?.required && !isComplete
        }
      }
    });
  }


  const onSubmit = async () => {
    if (isSending || !isValid) return;
    setIsSending(true);

    let sendingState = state;
    if (props.beforeSubmit) sendingState = props.beforeSubmit(state);


    if (!state.radio && !noRadio) {
      state.radio = {
        value: activeRadio,
      }
    }
    if (id) {
      state.id = {
        value: id,
      }
    }

    try {
      // console.log(action)
      // console.log(getFormData(sendingState))
      const response = await http<R>({
        action: action,
        data: getFormData(sendingState),
      });

      if (props.onSuccess) props.onSuccess(response);

      // console.log(response)

      // dispatchNotice((Array.isArray(response.response) ? response.response.join(', ') : response.response) || 'Success');
    } catch (error) {
      //console.log(error)
      if (error instanceof Error) {
        // dispatchNotice(error.message, true);
        if (props.onError) props.onError(error.message, error);
      } else if (error instanceof Response) {
        const [message, newState] = await checkErrors(error, state);
        // dispatchNotice(message, true);
        if (props.onError) props.onError(message, error);
        if (newState) setState(newState);
      } else {
        if (props.onError) props.onError('Unknown error', error);
        throw error;
      }
    }

    setIsSending(false);
  }

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit().then(() => {
    }).catch((error) => {
      console.warn(error, 'catch')
    })
  }


  return <form onSubmit={onSubmitForm} className={className}>
    <FormProvider value={{
      setDefaultStateItem,
      onChange,
      onComplete,
      state,
      isSending,
      isValid,
      isForm: true
    }}>
      {children}
      {/*{Object.entries(state).map(([key, obj], i) => <div key={i}>*/}
      {/*  {i}. {key}: {`${obj!.value}`}*/}
      {/*</div>)}*/}
    </FormProvider>
  </form>
}
