import React, {useEffect, useRef} from 'react';
import {Content, ContentProps} from './content/Content';
import css from './DetailedContent.module.scss';
import {classNames} from 'a-utils';
import { motion } from 'framer-motion';
import {useRouter} from 'next/router';


export const DetailedContent = (content: ContentProps) => {
  const {modifier} = content;
  const router = useRouter()
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (contentRef.current) {
      if (router.asPath.includes('/ar/')) {
        contentRef.current?.classList.add('ql-direction-rtl')
      } else {
        contentRef.current?.classList.remove('ql-direction-rtl')
      }
    }
  }, [router])


  return <motion.section
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.3 }}
    className={classNames(css.section, modifier && css[modifier])}
  >
    <div ref={contentRef} className={classNames(css.content)}>
      <Content {...content}/>
    </div>
  </motion.section>
}
