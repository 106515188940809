import {AnyMaskedOptions} from 'imask';


export type T_InputType = 'text' | 'email' | 'tel' | 'number' | 'password' | 'textarea' | 'date';
export type T_MaskAlias = 'email';
export type T_MaskType = boolean | T_MaskAlias | AnyMaskedOptions
export type InputProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  name?: string;
  disabled?: boolean;
  value?: string;
  max?: number;
  min?: number;
  lang?: string;
}

export const getMaskOptions = (maskType: T_MaskType, type: T_InputType) => {
  let options: AnyMaskedOptions;

  if (typeof maskType === 'boolean' || typeof maskType === 'string') {
    let alias: T_InputType | T_MaskAlias = type;
    if (typeof maskType === 'string') alias = maskType;

    options = {
      mask: '',
      lazy: false
    };

    switch (alias) {
      case 'email':
        options.mask = '*A@*A.d*A';
        options.blocks = {
          A: {
            mask: /^[A-Za-z0-9]+$/
          }
        };
        options.definitions = {
          d: /^[A-Za-z0-9]+$/
        };
        break;
    }

  } else {
    options = maskType;
  }

  return options;
};
