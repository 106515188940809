import React from 'react';
import {Primitives, PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';
import css from './DetailedPrimitive.module.scss';
import {classNames} from 'a-utils';

export type Props = {
  primitives: PrimitivesProps;
  anchor?: string;
}

export const DetailedPrimitive = ({primitives, anchor}: Props) => {
  return <>
    {anchor ? <div id={anchor}/> : <></>}
    <Primitives className={classNames(css.primitive, 'container-article', 'text', 'primitive')} {...primitives}/>
  </>
}
