import React from 'react';
import css from './DetailedAttentionBlock.module.scss';
import Image from 'next/image';
import {classNames} from 'a-utils';

export type Props = {
  text?: string;
  position?:string;
  name?: string;
  anchor?: string;
}

export const DetailedAttentionBlock = ({text, position, name, anchor}: Props) => {
  if (!text) return null;

  return (
    <div id={anchor ? anchor : ''} className={css.block}>
      <div className={classNames(css.wrap, (position || name) && css.large)}>
        <div className={css.icon}>
          <Image
            className={css.outer}
            width={126}
            height={124}
            src={'/common/attention-block-outer.svg'}
            alt={''}
            priority={true}
          />
          <div className={css.inner}>
            <Image
              width={69}
              height={75}
              src={'/common/attention-block-inner.svg'}
              alt={''}
              priority={true}
            />
          </div>
        </div>
        <h5 className={css.text}>{text}</h5>
        {(position || name) && <div className={css.author}>
          {position && <p className={css.position}>{position}</p>}
          {name && <p className={css.name}>{name}</p>}
        </div>}
      </div>

    </div>
  );
};
