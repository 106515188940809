import React, {useEffect, useRef, useState} from 'react';
import css from './Games.module.scss'
import {classNames} from 'a-utils';
import Image, {ImageProps} from 'next/image';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {useIsVisible} from 'HOOKS/useIsVisible';
import { motion } from 'framer-motion';
import useIsSafari from 'HOOKS/useIsSafari';

export type Props = {
  title: string;
  items: {
    image: ImageProps;
    title: string;
    description: string;
    link: string
  }[];
}

type Block = {
  image: ImageProps;
  title: string;
  description: string;
  link: string
}


export const Games = ({title, items}: Props) => {


  useEffect(() => {
    window.scrollTo({top:0})
  },[])

  const Block = (block: Block, index: number) => {
    const blockRef = useRef<HTMLDivElement | null>(null)
    const active = useIsVisible(blockRef, 0.4);
    //чтобы эффект сработал 1 раз
    const [blockViewed, setBlockViewed] = useState(false);
    const isSafari = useIsSafari();

    useEffect(() => {
      if (active) setBlockViewed(true);
    }, [active])

    return (<div key={index} className={classNames(css.gameBlock, blockViewed && css.visible, isSafari ? css.safari : css.notSafari)} ref={blockRef}>
      <SimpleLink href={block.link} className={css.imgWrap} >
        <span className={css.image}>
          {block.image.src && <Image
            src={block.image.src}
            alt={`game${index}`}
            width={block.image.width}
            height={block.image.height}
          />}
        </span>
      </SimpleLink>
      <div className={css.info}>
        <h4>{block.title}</h4>
        <p>{block.description}</p>
        <SimpleLink href={block.link} className={'button orange large'}>
          <span className={'button-text'}>view more</span>
        </SimpleLink>
      </div>
    </div> )}


  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames(css.main)}
    >
      <div className={classNames(css.wrap, 'container')}>
        <h1 className={css.heading}>{title}</h1>

        {items.map((block, index) => <Block {...block} key={index} />)}

      </div>
    </motion.section>
  );
};
