import React, {useEffect, useRef, useState} from 'react';
import css from './MainTeam.module.scss'
import {classNames} from 'a-utils';
import {useIsVisible} from 'HOOKS/useIsVisible';
import {JSInstance} from './JSInstance';


export type mainTeamProps = {
	title: string,
	text?: string,
  badges: BadgeProps[],
};

type BadgeProps = {
  svg?: string,
  title: string,
  text: string,
}

export const MainTeam = ({ title, text, badges }: mainTeamProps) => {
  const blockRef = useRef<HTMLElement | null>(null)
  const titleRef = useRef<HTMLHeadingElement | null>(null)
  const active = useIsVisible(blockRef, 0.4);
  //чтобы эффект сработал 1 раз
  const [blockViewed, setBlockViewed] = useState(false);


  useEffect(() => {
    if (active) setBlockViewed(true);
  }, [active])

  useEffect(() => {
    if (!blockRef.current || !titleRef.current) return;
    const textArray = title.split(' ');

    const instance = new JSInstance(blockRef.current, titleRef.current, textArray);
    instance.init();

    return () => {
      instance.destroy();
    };
  }, [title]);


  return (
    <section ref={blockRef} className={classNames(css.main, active && css.active, blockViewed && css.viewed )}>
      <div className={classNames('container', css.wrap)}>
        <div className={css.texts}>
          {title && <h3 ref={titleRef} className={css.title} dangerouslySetInnerHTML={{__html: title}}/>}
          {text && <p className={css.text} dangerouslySetInnerHTML={{__html: text}}/>}
        </div>
        <div className={css.badges}>

          {
            badges.map((badge, i) => {
              return <div key={badge.title} className={classNames(css.badge)}>
                <div className={css.image}>
                  <div className={css.icon} style={{backgroundImage: `url(./main-page/badge${i}.svg)`}}/>
                  <p className={css.badgeTitle} dangerouslySetInnerHTML={{__html: badge.title}}/>
                </div>
                <p className={css.badgeText}>
                  {
                    badge.text.split('\n').map((el, i) => {
                      return <span key={`badge-${i}-${el}`}><i>{el}</i></span>
                    })
                  }
                </p>
              </div>
            })
          }
        </div>
      </div>
    </section>
  );
};
