import React from 'react';
import css from './VacancyCard.module.scss';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import {classNames} from 'a-utils';

export type VacancyCardProps = {
  title: string,
  tag?: string[],
  link: string,
  color?: string,
  moreBtn?: boolean,
}

export const VacancyCard = ({ title, tag, link, color, moreBtn }: VacancyCardProps) => {

  return (
    <SimpleLink href={link} className={classNames(css.card, color && css[color], moreBtn && css.more)}>
      <span className={css.wrap}>
        <h4 className={css.title} dangerouslySetInnerHTML={{__html: title}}/>

        <div className={css.tags}>
          {
            moreBtn && <span className={css.tag}><span className={css.icon}/></span>
          }
          {
            !moreBtn && tag && tag?.map((item, index) => {
              return <span key={`tag-${index}`} className={css.tag}>
                {item}
              </span>
            })
          }
        </div>

      </span>
    </SimpleLink>
  );
};
