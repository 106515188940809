import React, {useEffect, useRef, useState} from 'react';
import Image, {ImageProps} from 'next/image';
import css from './AboutLocation.module.scss'
import {classNames} from 'a-utils';
import {JSInstance} from './JSInstance';
import {Swiper, SwiperSlide} from 'swiper/react';
import { type Swiper as SwiperRef } from 'swiper'


type AboutLocationsProps = {
  locations: {
    name: string,
    img: ImageProps,
  }[]
}

type ObjPin = {
  pin1: number;
  pin2: number;
  rotate1: number;
  rotate2: number;
  offsetX: number;
  offsetY: number;
}

type locations = {
  id: number,
  country: string;
}

export const AboutLocations = ({locations}: AboutLocationsProps) => {
  const [width, setWidth] = useState(320);
  const [deg, setDeg] = useState<number>(-2);
  const [activeLoc, setActiveLoc] = useState(0);
  const swiperRef = useRef<SwiperRef>()
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const listRef = useRef<locations[]>([])

  const [obj, setObj] = useState<ObjPin>({
    pin1: 2,
    pin2: 7,
    rotate1: 4,
    rotate2: -6,
    offsetX: 40,
    offsetY: 20
  })

  useEffect(() => {
    if (!locations) return;

    listRef.current = [];
    locations.forEach((country, id) => {
      listRef.current.push({
        id: id,
        country: country.name
      })
    })
  }, [locations])

  useEffect(() => {
    const instance = new JSInstance();
    setInstanceJS(instance)
    setWidth(window.innerWidth)

    instance.init();

    return () => {
      instance.destroy();
    };
  }, []);

  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw) => {
      setWidth(vw)
    });
  }, [instanceJS]);


  //обработка пинов, чтобы не повторялись и на грузии-кипре стояли их
  const click = (id:number) => {

    setActiveLoc(9)

    let rand1 = Math.ceil(Math.random() * 6)
    const rand2 = Math.ceil(Math.random() * 6)
    let pin1:number, pin2:number;

    setTimeout(() => {
      setActiveLoc(id)

      if (rand1 === rand2) {
        rand1 -= 1;
        if (rand1 === 6) rand1 = 5
        if (rand1 === 0) rand1 = 2
      }
      pin1 = rand1
      pin2 = rand2

      listRef.current.forEach(el => {
        if (id === el.id && el.country === 'Georgia') {
          pin2 = 8
        }
        if (id === el.id && el.country === 'Cyprus') {
          pin2 = 7
        }
      })

      setObj({
        pin1: pin1,
        pin2: pin2,
        rotate1: Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1),
        rotate2: Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1),
        offsetX: Math.random() * 70 ,
        offsetY: Math.floor(Math.random() * 50 + 20)
      })

      setDeg(Math.random() * 4 * (Math.random() > 0.5 ? 1 : -1))
    }, 300)

    if (swiperRef.current && width < 768) {
      //setActiveLoc(id)
      swiperRef.current.slideTo(id)
    }
  }


  return (
    <section className={css.main}>
      <div className={classNames(  'container', css.wrap)}>

        <div className={css.location}>
          {width < 768 ?
            <Swiper
              threshold={0.6}
              onSwiper={(swiper) => {
                swiperRef.current = swiper
              }}
              className={classNames(css.slider, css.names)}
              centeredSlides={true}
              loop={false}
              spaceBetween={24}
              slidesPerView={'auto'}
              onRealIndexChange ={ (swiper) => {
                if (swiper.realIndex !== activeLoc) {
                  click(swiper.realIndex)
                }
              }}
            >
              {locations.map((el, index) => (
                <SwiperSlide key={`slide-${el.name}-${index}`}>
                  <button
                    className={classNames(activeLoc === index ? css.active : '', css.title, 'h2')}
                    onClick={() => click(index)}>{el.name}
                  </button>
                </SwiperSlide>
              ))}
            </Swiper>
            :
            <div className={css.names}>{
              locations.map((el, index) => (
                <button
                  key={`loc-${el.name}-${index}`}
                  className={classNames(activeLoc === index ? css.active : '', css.title, 'h2')}
                  onClick={() => click(index)}>{el.name}
                </button>
              ))
            }</div>
          }

          <div className={css.images}>
            {locations.map((el, index) =>
              <div key={`key-${el.img.alt}-${index}`} className={classNames(css.block,  activeLoc === index && css.active)}>
                <span className={classNames(css.imageWrap)} style={{transform: width > 768 ?  `rotate(${deg}deg)` : 'initial'}}>
                  {el.img.src && <Image
                    priority={false}
                    quality={90}
                    src={el.img.src}
                    alt={el.img.alt}
                    width={el.img.width}
                    height={el.img.height}
                  />}
                </span>

                <span style={{left: `${obj.offsetX}%`}} className={classNames(css.pin)}>
                  <span
                    style={{
                      transform: `rotate(${obj.rotate1}deg)`,
                      backgroundImage: `url("./about/images.svg#${obj.pin1}")`
                    }}
                    className={css.pinIcon}/>
                </span>

                <span style={{top: `${obj.offsetY}%`}} className={classNames(css.pin)}>
                  <span style={{
                    transform: `rotate(${obj.rotate2}deg)`,
                    backgroundImage: `url("./about/images.svg#${obj.pin2}")`
                  }}
                  className={css.pinIcon}/>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
