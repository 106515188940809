import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';

export type OnChange = (vw: number, vh: number) => void;


export class JSInstance {
  private _onChange: OnChange;

  private _section: HTMLDivElement;
  private _paginationLength = 0;

  private _vw = 1;
  private _vh = 1;


  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);

  constructor(section: HTMLDivElement, onChange: OnChange = () => {
  }) {
    this._section = section;
    this._onChange = onChange;
  }

  public init() {
    this._resizeDispatcher.addListener(this._onResize);
    this._resizeDispatcher.fire();
  }

  public destroy() {
    this._resizeDispatcher.removeListener(this._onResize);
  }

  private _resize({vw, vh}: ResizeEvent) {
    this._vw = vw;
    this._vh = vh;

    const width = this._section.getBoundingClientRect().width
    let col: number;
    this._vw > 1280 ? col = width / 12 : col = width / 8

    this._section.style.setProperty('--col', `${col}px`)
    this._onChange(this._vw, this._vh);
  }

  set onChange(func: OnChange) {
    this._onChange = func;
  }

  private _change(vw: number, vh: number) {
    this._onChange(vw, vh);
  }
}
