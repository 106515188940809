import {getPerformance} from 'COMPONENTS/ui/move/move';
import {Performance} from 'a-utils';

import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';
import {getScrollDispatcher, ScrollEvent} from 'COMPONENTS/ui/scroll/ScrollDispatcher';
import {splitLines} from 'COMPONENTS/ui/splittedText/splittedText';

export class JSInstance {
  private _isNeedUpdate?: boolean;
  private readonly _performance: Performance;
  private _mainWrap: HTMLElement;

  private _vw = 1;
  private _vh = 1;
  private _top = 0;

  private _goParallaxGo = false;
  private _parallaxPercent = 0;
  private _parallaxTop = 10000;
  private _parallaxBottom = 10000;

  private readonly _obj: {
    new: number;
    current: number;
    move: number;
    last: number;
    speed: number;
    smooth: number;
    scale: number;
  };


  private _title: HTMLHeadingElement;
  protected _textFromTitle: string[];
  private _fontSize = 26;
  private _stringLimit = 26;

  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _scrollDispatcher = getScrollDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = (e: ScrollEvent) => this._scroll(e);

  private readonly animateHandler: (shift: number) => void;

  constructor(mainWrap: HTMLElement, title: HTMLHeadingElement, textArray: string[]) {
    this._performance = getPerformance();
    this._mainWrap = mainWrap;
    this._title = title;
    this._textFromTitle = textArray;

    this._obj = {
      speed: 0,
      smooth: 0,
      current: 0,
      move: 0,
      new: 0,
      last: 0,
      scale: 1,
    };

    this.animateHandler = (shift: number) => this._onAnimate(shift);
  }

  public init() {
    this._performance.addListener(this.animateHandler);
    this._resizeDispatcher.addListener(this._onResize);
    this._scrollDispatcher.addListener(this._onScroll);

    this._resizeDispatcher.fire();
  }

  public destroy() {
    this._performance.removeListener(this.animateHandler);
    this._resizeDispatcher.removeListener(this._onResize);
    this._scrollDispatcher.removeListener(this._onScroll);
  }

  private _resize({vw, vh}: ResizeEvent) {
    this._vw = vw;
    this._vh = vh;

    this._parallaxTop = this._top + this._mainWrap.getBoundingClientRect().top - this._vh * .6;
    this._parallaxBottom = this._parallaxTop + this._mainWrap.getBoundingClientRect().height;

    this._fontSize = parseInt(getComputedStyle(this._title).fontSize);
    this._stringLimit = ((this._title.getBoundingClientRect().width - this._fontSize * 2) / this._fontSize) * 1.8;
    this._title.innerHTML = splitLines(this._textFromTitle, this._stringLimit, 0.2);

    this._scrollDispatcher.fire();
  }


  private _scroll({offsetTop}: ScrollEvent) {
    this._top = offsetTop;
    this._obj.new = this._top;

    //разрешаем параллакс только тогда, когда блок в области видимости
    this._goParallaxGo = !(this._top < this._parallaxTop || this._top > this._parallaxBottom);

    this._parallaxPercent = 100 * ((this._top - this._parallaxTop) / (this._parallaxBottom - this._parallaxTop));
    if (this._parallaxPercent > 100) this._parallaxPercent = 100;
  }


  private _onAnimate(shift: number) {
    const obj = this._obj;
    obj.last = obj.current;
    obj.move += (obj.new - obj.move) / 2;
    obj.current += (obj.new - obj.current) / 5;
    obj.speed = obj.last - obj.current;
    obj.smooth += obj.speed / 4;
    obj.smooth /= (1 + 0.05 * shift);
    obj.smooth = Math.max(obj.smooth, -100);
    obj.smooth = Math.min(obj.smooth, 100);
    obj.scale = obj.smooth / 100;

    if (obj.scale < 0.01 && obj.scale > -0.01) {
      this._update(0);
      this._isNeedUpdate = false;
      return;
    }
    this._isNeedUpdate = true;
    this._update(obj.scale);

  }

  private _update(scale: number) {
    if (this._isNeedUpdate && this._goParallaxGo) {
      if (this._vw > 1280) {
        this._mainWrap.style.setProperty('--top1', `${-this._parallaxPercent * .6}%`);
        this._mainWrap.style.setProperty('--top2', `${-this._parallaxPercent}%`);
        this._mainWrap.style.setProperty('--top3', `${-this._parallaxPercent * .8}%`);
      }

      this._mainWrap.style.setProperty('--deg1', `${-scale * 10 - 20}deg`);
      this._mainWrap.style.setProperty('--deg2', `${-scale * 10 + 10}deg`);
    }
  }

}
