import React from 'react';
import {classNames} from 'a-utils';
import css from './DetailedSlider.module.scss';
import {RegularSlider, RegularSliderProps} from 'COMPONENTS/pages-components/common/RegularSlider/RegularSlider';

export type Props = {
  title?: string;
  slider: RegularSliderProps;
  anchor?: string;
};


export const DetailedSlider = ({title, slider, anchor}: Props) => {


  return (
    <div id={anchor ? anchor : ''} className={css.block}>
      <div className={classNames(css.wrap, 'container')}>
        {title && <h3 className={css.title}>{title}</h3>}

        <RegularSlider arrows={true} className={css.slider} {...slider}/>
      </div>
    </div>
  );
};
