import React, {useEffect} from 'react';
import {useRouter} from 'next/router';

export type Props = {
  redirectUrl?: string;
}

const SupportsRedirect = ({redirectUrl}: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (!redirectUrl) return;

    void router.push(redirectUrl).catch(err => console.log(err)).then(() => console.log('redirect'));

  }, [redirectUrl])


  return (
    <div/>
  );
};

export default SupportsRedirect;
