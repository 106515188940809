import React from 'react';
import css from './GameScreens.module.scss';
import {RegularSlider, RegularSliderProps} from 'COMPONENTS/pages-components/common/RegularSlider/RegularSlider';
import {classNames} from 'a-utils';
import { motion } from 'framer-motion';


export type GameScreensProps = {
  slider: RegularSliderProps,
};

export const GameScreens = ({ slider, }: GameScreensProps) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={classNames( css.main)}
    >
      <div className={'container'}>
        <RegularSlider arrows={true} className={css.slider} {...slider}/>
      </div>
    </motion.section>
  );
};
