import React, {useEffect, useState} from 'react';
import {AnyMaskedOptions} from 'imask';
import {InputProps, T_InputType, T_MaskAlias} from './utils/input-utils';
import {errors, FormItemProps, getFormItemClassName} from '../form-utils';
import {useFormContext} from '../context/FormContext';
import {MaskedInput} from 'COMPONENTS/ui/form/input/utils/MaskedInput';
import {classNames} from 'a-utils';
import styles from './Input.module.scss';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';


type T_PropsCommon = FormItemProps<string> & {
  type?: T_InputType;
  label?: string;
  // placeholder?: string;
  lang?: string;
  mask?: boolean | T_MaskAlias | AnyMaskedOptions;
  onFocus?: () => void;
  onBlur?: () => void;
  noNotice?: boolean;
}
type T_PropsNumber = T_PropsCommon & { type: 'tel', max?: number, min?: number };
export type T_Props = (T_PropsCommon & { type?: Exclude<T_InputType, 'number'> }) | T_PropsNumber;


const validate: { [key in T_InputType]: (value: string, props: Partial<T_Props>) => boolean } = {
  text: (value) => !!value.length,
  email: (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value.toLowerCase());
  },
  number: () => {
    return true;
  },
  password: (value) => value.length >= 8,
  textarea: (value) => !!value.length,
  tel: (value, props) => {
    const p = props as T_PropsNumber;
    const min = p.min || -Infinity;
    const max = p.max || Infinity;
    const v = parseFloat(value);

    return !isNaN(v) && v <= max && v >= min;
  },
  date: (value) => !!value
}


/**
 * <Input name='phone' mask type='tel' label={'Phone'} required/>
 * просто добавь mask и он автоматом подтянется маска из типа
 * так же можно строкой указать тип маски
 * или передать Options для IMask
 */

export const Input = ({
  type = 'text', value = '', name, required, mask,
  onChange, onComplete, disabled, error, notice,
  lang = 'en_US', noNotice = false, ...props
}: T_Props) => {
  const [isFocused, setFocused] = useState(false);
  const [stateValue, setStateValue] = useState(props.defaultValue || value);
  const context = useFormContext();

  const setDefaultStateItem = context.setDefaultStateItem;
  if (context.isForm) {
    onChange = context.onChange;
    onComplete = context.onComplete;
    disabled = disabled || context.isSending;

    const contextItemState = context.state[name];
    if (contextItemState) {
      error = error || contextItemState.error;

      if (!notice && contextItemState.error) {
        if (contextItemState.notice) {
          notice = contextItemState.notice;
        } else {
          if (!stateValue) {
            notice = errors['en'].required;
          } else {
            notice = errors['en'].incorrect;
          }
        }
      }
    }
  }

  useEffect(() => {
    if (setDefaultStateItem) {
      setDefaultStateItem(name, {
        value: stateValue,
        error,
        required
      })
    }
  }, [error, name, required, setDefaultStateItem, stateValue]);


  let input;
  let inputProps: InputProps = {
    name,
    disabled,
    lang,
    value: stateValue,
  }

  if (mask) {
    const inputMaskedProps = {
      ...inputProps,
      mask,
      type,
      onFocus: () => {
        props.onFocus && props.onFocus();
        setFocused(true);
      },
      onBlur: () => {
        props.onBlur && props.onBlur();
        setFocused(false);
      },
      onChange: (value: string, isComplete: boolean) => {
        setStateValue(value);
        onChange && onChange(value, isComplete, name);
      },
      onComplete: (isComplete: boolean) => {
        onComplete && onComplete(isComplete, name);
      }
    }
    input = <MaskedInput {...inputMaskedProps}/>
  } else {
    inputProps = {
      ...inputProps,
      onKeyDown: (e) => {
        if (inputProps.name === 'phone') {
          if (!(e.key >= '0' && e.key <= '9') && e.key !== 'Backspace') {
            e.preventDefault()
          }
        }
      },
      onChange: (e: React.ChangeEvent) => {
        const target = e.target as (HTMLInputElement | HTMLTextAreaElement);
        const targetValue = target.value;

        setStateValue(targetValue);

        onChange && onChange(targetValue, validate[type](targetValue, props), name);
      },
      onFocus: () => {
        props.onFocus && props.onFocus();
        setFocused(true);
      },
      onBlur: () => {
        props.onBlur && props.onBlur();
        setFocused(false);
        onComplete && onComplete(validate[type](stateValue, props), name);
      }
    }

    if (type === 'tel') {
      inputProps.max = (props as T_PropsNumber).max;
      inputProps.min = (props as T_PropsNumber).min;
    }
    if (type === 'textarea') {
      input = <textarea {...inputProps}/>
    } else {
      input = <input {...inputProps} type={type}/>
    }
  }

  const className = getFormItemClassName(styles.wrapper, {
    error,
    notice: Boolean(notice),
    focus: isFocused,
    value: !!stateValue
  });

  return (
    <label
      className={classNames(className, 'input-text', type === 'textarea' && styles.textarea)}>
      <span className={styles.placeholder}>{props.label}</span>
      {input}
      <span className={styles.plus}/>
      <span data-id="warning" className={styles.warning}><Icon id={'warning'}/></span>
      {!noNotice &&
        <span data-id="notice" className={styles.notice}>{notice}</span>
      }
    </label>
  )
}
