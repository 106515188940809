import React from 'react';
import {Picture} from 'TYPES/types';
import css from './DetailedPicture.module.scss'
import Image from 'next/image';

export type Props = {
  picture?: Picture;
  sign?: string;
  anchor?: string;
}

export const DetailedPicture = ({picture, sign, anchor}: Props) => {
  if (!picture) return null;

  return (
    <div id={anchor ? anchor : ''} className={css.block}>
      {picture.width && <Image
        className={css.picture}
        width={picture.width}
        height={picture.height}
        src={picture.src}
        alt={picture.alt ? picture.alt : 'Фото/картинка внутри статьи'}
        priority={true}
      />}
      {sign && <div className={css.sign}>{sign}</div>}
    </div>
  );
};
