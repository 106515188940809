import React, {HTMLAttributes} from 'react';
import {Primitives, PrimitivesProps} from 'COMPONENTS/content/primitives/Primitives';
import css from './FooterTextInfo.module.scss';

type Props = HTMLAttributes<HTMLElement> & {
  primitives?: PrimitivesProps;
}

const FooterTextInfo = ({className, primitives}: Props) => {

  return (
    <div className={className}>
      {primitives && <Primitives className={css.textInfo} {...primitives}/>}
    </div>
  );
};

export default FooterTextInfo;