import React, {Fragment, useEffect, useRef, useState} from 'react';
import {JustLink} from 'TYPES/types';
import css from './MainVacancy.module.scss'
import {classNames} from 'a-utils';
import Image from 'next/image';
import 'swiper/css';
import {VacancyCard, VacancyCardProps} from 'COMPONENTS/pages-components/common/VacancyCard/VacancyCard';
import {JSInstance} from './JSInstance';
import {useIsVisible} from 'HOOKS/useIsVisible';
import {SquirrelVideo} from 'COMPONENTS/pages-components/common/SquirrelVideo/SquirrelVideo';

export type mainVacancyProps = {
  vacancies: VacancyCardProps[],
  button: JustLink,
  small: string,
};



export const MainVacancy = ({ vacancies, button, small }: mainVacancyProps) => {
  const [width, setWidth] = useState(320);
  const [smallIsHidden, setSmallIsHidden] = useState(false);
  const [list, setList] = useState<HTMLElement | null>(null);
  const [section, setSection] = useState<HTMLElement | null>(null);

  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);

  const blockRef = useRef<HTMLDivElement | null>(null)
  const active = useIsVisible(blockRef, width > 768 ? 0.4 : 0);
  const [blockViewed, setBlockViewed] = useState(false);


  useEffect(() => {
    if (active) setBlockViewed(true);
  }, [active])

  useEffect(() => {
    if (!section || !list) return ;
    const instance = new JSInstance(section, list);
    setInstanceJS(instance)
    setWidth(window.innerWidth)

    instance.init();

    return () => {
      instance.destroy();
    };
  }, [section, list]);


  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw, hideSmall) => {
      setWidth(vw)
      setSmallIsHidden(hideSmall)
    });
  }, [instanceJS]);

  //button чтобы линтер не ругался
  const imageBlock = <button className={css.squirrel}>
    <Image
      className={css.squirrelAnimation}
      width={170}
      height={170}
      alt={''}
      src={'/main-page/playVacancy.svg'}
      priority={true}
      quality={90}/>

    <SquirrelVideo className={css.squirrelImg} name={'like'}/>
  </button>

  return (
    <section ref={setSection} className={classNames(css.main, blockViewed && css.active)}>
      <h1 className='visually-hidden'>Discover your dream vacancy</h1>

      <div className={css.sticky}>
        <div ref={blockRef} className={classNames('container')}>
          <div className={classNames(css.title)}>
            {
              width <= 767 ?
                <>
                  {imageBlock}
                  <p className={classNames(css.text)}>
                    <span>Discover your dream job</span>
                  </p>
                </>
                :
                <>
                  <p className={classNames(css.text)}>
                    <span>Discover</span>
                    {imageBlock}
                    <span>your</span>
                  </p>
                  <p className={classNames(css.text)}>
                    <span>dream job</span>
                  </p>
                </>
            }
          </div>

          <div ref={setList} className={css.vacancies}>
            <div className={css.list}>
              {vacancies.map((vacancy, index) => {
                return (index < 5) ? <Fragment key={`collection-card-${index}`}>
                  <VacancyCard {...vacancy}/>
                </Fragment> : <></>})
              }
              <VacancyCard title={button.text} link={button.link} moreBtn={true}/>
            </div>
          </div>
        </div>
        <div className={classNames(css.small, 'container container--large', smallIsHidden ? css.hide : '')}>
          <small>{small}</small>
        </div>
      </div>
    </section>
  );
};
