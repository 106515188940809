import React, {HTMLAttributes} from 'react';
import {IconId} from 'COMPONENTS/pages-components/common/Icon/Sprite';

export type Props = HTMLAttributes<SVGElement> & {
  id: IconId;
}

const Icon = ({className, id}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      aria-hidden="true"
      className={className}
    >
      <use xlinkHref={`#${id}`}/>
    </svg>
  );
};

export default Icon;
