import React, {HTMLAttributes, useEffect, useState} from 'react';
import css from './DetailedHeading.module.scss';
import {classNames} from 'a-utils';
import {DetailedSocial} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DetailedSocial/DetailedSocial';
import {AnchorInstance} from './AnchorInstance';

export type DetailedHeadingAdditionalProps = HTMLAttributes<HTMLElement> & {
  sign?: string;
  button?: {
    target?: string;
    text?: string;
  }
  share?: {
    title?: string;
    sharedLink: string;
    sharedText: string;
  }
  heading?: boolean;
}

const DetailedHeadingAdditional = ({sign, button, share}: DetailedHeadingAdditionalProps) => {
  const [anchorInstance, setAnchorInstance] = useState<AnchorInstance | null>(null);

  useEffect(() => {
    const instance = new AnchorInstance();
    setAnchorInstance(instance);

    setTimeout(() => {
      instance.init();
    }, 200);

    return () => {
      instance.destroy();
    };
  }, []);

  const onClick = () => {
    if (!anchorInstance) return;

    anchorInstance._scrollTo();
  }

  return (
    <div className={css.additional}>
      <div className={css.sign}>
        {sign && <p>{sign}</p>}
        {button && <button className={classNames('button', 'orange', 'large', css.button)} onClick={onClick}>
          <span className={'button-text'}>{button.text && button.text}</span>
        </button>}
      </div>


      {share && <DetailedSocial heading={true} {...share} />}
    </div>
  );
};

export default DetailedHeadingAdditional;
