import styles from './Wrapper.module.scss';
import React from 'react';
import {classNames} from 'a-utils';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export const Wrapper = ({className, children}: Props) => {
  return <div className={classNames(styles.container, className)}>
    {children}
  </div>
}