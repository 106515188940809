import React from 'react';
import {Picture} from 'TYPES/types';
import css from './DetailedPreview.module.scss';
import Image from 'next/image';

export type Props = {
  picture: Picture;
  anchor?: string;
}

export const DetailedPreview = ({picture, anchor}: Props) => {
  if (!picture) return null;

  return (
    <div id={anchor ? anchor : ''}  className={css.block}>
      <div className={css.content}>
        {picture.src && <Image
          className={css.picture}
          width={picture.width}
          height={picture.height}
          src={picture.src}
          alt={picture.alt ? picture.alt : 'Превью статьи'}
          priority={true}
        />}
      </div>
    </div>
  );
};
