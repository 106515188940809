import React from 'react';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';

export type Props = {
  text?: string;
  link?: {
    text: string;
    link: string;
  }
}

export const HelloWorld = ({text, link}: Props) => {
  return <div style={{paddingTop: '100px'}}>
    <h1>{text}</h1>
    {link && <SimpleLink href={link.link}>
      {link.text}
    </SimpleLink>}
  </div>
}