import React from 'react';
import {MetaData} from './meta/meta';
import {HelloWorld} from 'COMPONENTS/pages-components/HelloWorld';

import {FirstAnimation} from 'COMPONENTS/pages-components/main-page/FirstAnimation/FirstAnimation';
import {MainTeam} from 'COMPONENTS/pages-components/main-page/MainTeam/MainTeam';
import {MainVacancy} from 'COMPONENTS/pages-components/main-page/MainVacancy/MainVacancy';
import {MainNews} from 'COMPONENTS/pages-components/main-page/MainNews/MainNews';

import {DetailedContent} from 'COMPONENTS/pages-components/detailed/DetailedContent/DetailedContent';
import {DetailedHeading} from 'COMPONENTS/pages-components/detailed/DetailedHeading/DetailedHeading';
import {DetailedSlider} from 'COMPONENTS/pages-components/detailed/DetailedSlider/DetailedSlider';

import {VacancyForm} from 'COMPONENTS/pages-components/common/VacancyForm/VacancyForm';
import {VacancyHead} from 'COMPONENTS/pages-components/vacancy-page/VacancyHead/VacancyHead';

import {NewsPage} from 'COMPONENTS/pages-components/news-page/NewsPage';

import {StuffVideo} from 'COMPONENTS/pages-components/about-page/StuffVideo/StuffVideo';
import {StuffSlider} from 'COMPONENTS/pages-components/about-page/StuffSlider/StuffSlider';
import {AboutPerks} from 'COMPONENTS/pages-components/about-page/perks/AboutPerks';
import {AboutLocations} from 'COMPONENTS/pages-components/about-page/locations/AboutLocations';
import {OrangeScreen} from 'COMPONENTS/pages-components/about-page/orangeScreen/OrangeScreen';

import {Games} from 'COMPONENTS/pages-components/games-page/Games'
import {GameScreens} from 'COMPONENTS/pages-components/games-detail/GameScreens/GameScreens';
import {GameHeading} from 'COMPONENTS/pages-components/games-detail/GamesHeading/GameHeading';
import {GameCommunity} from 'COMPONENTS/pages-components/games-detail/GamesCommunity/GameCommunity';
import {GamesFeatures} from 'COMPONENTS/pages-components/games-detail/GamesFeatures/GamesFeatures';
import {GamesOther} from 'COMPONENTS/pages-components/games-detail/GamesOther/GamesOther';

import {ContactsHeading} from 'COMPONENTS/pages-components/contacts/ContactsHeading/ContactsHeading';
import {ContactsOffices} from 'COMPONENTS/pages-components/contacts/ContactsOffices/ContactsOffices';
import {ContactsMap} from 'COMPONENTS/pages-components/contacts/ContactsMap/ContactsMap';

import {GetInTouch} from 'COMPONENTS/pages-components/common/GetInTouch/GetInTouch';
import GetInTouchPopup from 'COMPONENTS/pages-components/common/GetInTouchPopup/GetInTouchPopup';
import SupportsRedirect from 'COMPONENTS/pages-components/common/SupportsRedirect/SupportsRedirect';
import {DeleteAccountForm} from 'COMPONENTS/pages-components/detailed/DetailedContent/content/DeleteAccountForm/DeleteAccountForm';

type TypeOfContent = typeof content;
type ContentKeys = keyof TypeOfContent;

interface ContentType<T extends ContentKeys> {
  type: T;
  content: Parameters<TypeOfContent[T]>[0];
  anchor?: string;
}

export type ContentTypes = ContentType<ContentKeys>[];

export type ContentProps = {
  items?: ContentTypes;
  meta?: MetaData;
}

// сюда бахнуть все компоненты страниц
const content = {
  HelloWorld,

  FirstAnimation,
  MainTeam,
  MainVacancy,
  MainNews,

  StuffSlider,
  StuffVideo,
  AboutLocations,
  AboutPerks,
  OrangeScreen,

  Games,
  GameHeading,
  GameScreens,
  GameCommunity,
  GamesFeatures,
  GamesOther,

  DetailedHeading,
  DetailedContent,
  DetailedSlider,

  VacancyHead,
  VacancyForm,

  NewsPage,

  ContactsHeading,
  ContactsOffices,
  ContactsMap,

  GetInTouch,
  GetInTouchPopup,

  SupportsRedirect,

  DeleteAccountForm,

} as const;


export const Content = ({items = []}: ContentProps) => {

  return <>
    {items.map((item, i) => {
      if (!(item.type in content)) throw new Error(`"type: ${item.type}" is not exist`);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const ContentChild = content[item.type];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const returned = <ContentChild key={`ci-${i}`} {...item.content}/>

      return returned;
    })}
  </>
}
