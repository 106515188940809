import styles from './Primitives.module.scss';

import React from 'react';
import {useRouter} from 'next/router';
import {classNames} from 'a-utils';


type Props = {
  children?: string | string[];
  className?: string;
}

export const RawHTML = ({children, className}: Props) => {
  const router = useRouter();
  const c = classNames(styles.html, className);

  if (!children) return null;

  const onClick = (e: React.MouseEvent) => {
    const {target} = e;

    if (target instanceof Element) {
      const targetLink = target.closest('a');
      if(!targetLink) return;
      e.preventDefault();

      const {href} = targetLink;
      const {origin} = window.location;

      if (href.includes(origin)) {
        void router.push(targetLink.href.replace(window.location.origin, '') || '/');
      } else {
        window.open(href, '_blank');
      }
    }
  }

  let content = '';
  if (Array.isArray(children)) {
    content = children.join(' ')
  } else {
    content = children;
  }

  return <div className={c} onClick={onClick} dangerouslySetInnerHTML={{__html: content}}/>
}