import React, {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import Link, {LinkProps} from 'next/link';
import {classNames} from 'a-utils';

export type NavLinkProps = {
  className?: string;
  activeClassName?: string;
  strict?: boolean;
  onActiveChange?: (isActive: boolean) => void;
}

type Props = React.PropsWithChildren<LinkProps & NavLinkProps>;

const NavLink = ({children, activeClassName = 'active', strict = true, onActiveChange, className, ...props}: Props) => {
  const {asPath, isReady} = useRouter();
  const [currentClassName, setCurrentClassName] = useState(className);

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(
        (props.as || props.href) as string,
        location.href
      ).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;


      const getMatch = () => {
        return activePathname.indexOf(linkPathname) > -1;
      }

      const isActive = getMatch()
      const newClassName =
        isActive
          ? classNames(className, activeClassName)
          : className;

      onActiveChange && onActiveChange(isActive);

      if (newClassName !== currentClassName) {
        setCurrentClassName(newClassName);
      }
    }
  }, [asPath, strict, isReady, props.as, props.href, className, activeClassName, setCurrentClassName, currentClassName, onActiveChange]);

  return (
    <Link {...props} className={currentClassName}>
      {children}
    </Link>
  )
}

export default NavLink;
