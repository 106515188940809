import {getPerformance} from 'COMPONENTS/ui/move/move';
import {Performance} from 'a-utils';


import {getResizeDispatcher, ResizeEvent} from 'COMPONENTS/ui/resize/ResizeDispatcher';
import {getScrollDispatcher, ScrollEvent} from 'COMPONENTS/ui/scroll/ScrollDispatcher';

export type OnChange = (vw: number, showBtn: boolean) => void;


export class JSInstance {
  private _isNeedUpdate?: boolean;
  private readonly _performance: Performance;

  private _onChange: OnChange;

  private _vw = 1;
  private _vh = 1;
  private _top = 0;
  private _section: HTMLElement;
  private _line: HTMLElement;
  private _oneElem: HTMLElement;
  private _sectionTop = 5000;
  private _sectionBottom = 5000;
  private _sectionHeight = 50;
  private _lineWidth = 100;

  private _sliderPercent = 100;
  private _otherSlidesPercent = 0;
  private _scrollDown = true;
  private _blockScrolled = false;

  private readonly _obj: {
    new: number;
    current: number;
    move: number;
    last: number;
    speed: number;
    smooth: number;
    scale: number;
  };

  private readonly _resizeDispatcher = getResizeDispatcher();
  private readonly _scrollDispatcher = getScrollDispatcher();
  private readonly _onResize = (e: ResizeEvent) => this._resize(e);
  private readonly _onScroll = (e: ScrollEvent) => this._scroll(e);

  private readonly animateHandler: (shift: number) => void;

  constructor(section: HTMLElement, line: HTMLElement, oneElem: HTMLElement,
    onChange: OnChange = () => {
    }) {
    this._performance = getPerformance();
    this._onChange = onChange;

    this._obj = {
      speed: 0,
      smooth: 0,
      current: 0,
      move: 0,
      new: 0,
      last: 0,
      scale: 1,
    };

    this._section = section;
    this._line = line;
    this._oneElem = oneElem;


    this.animateHandler = (shift: number) => this._onAnimate(shift);
  }

  public init() {
    this._performance.addListener(this.animateHandler);

    this._resizeDispatcher.addListener(this._onResize);
    this._scrollDispatcher.addListener(this._onScroll);
    this._resizeDispatcher.fire();

    this._createLines();
  }

  public destroy() {
    this._performance.removeListener(this.animateHandler);

    this._resizeDispatcher.removeListener(this._onResize);
    this._scrollDispatcher.removeListener(this._onScroll);
  }

  private _resize({vw, vh}: ResizeEvent) {
    this._vw = vw;
    this._vh = vh;
    this._createLines();
    this._onChange(this._vw, this._sliderPercent >= 0.8);

    this._sectionTop = this._section.getBoundingClientRect().top + this._top;
    this._sectionBottom = this._sectionTop + this._section.getBoundingClientRect().height - this._vh;


    this._scrollDispatcher.fire();
  }

  private _scroll({offsetTop}: ScrollEvent) {
    this._top = offsetTop;
    this._obj.new = this._top;


    if (this._vw >= 1280) {
      this._sliderPercent = 1 - 1.4 * ((this._top - this._sectionTop) / (this._sectionBottom - this._sectionTop));

      this._sliderPercent = Math.min(this._sliderPercent, 1)
      this._sliderPercent = Math.max(this._sliderPercent, 0)

      if (this._top > this._sectionBottom) {
        this._section.style.setProperty(`--slider`, `0`);
      }
      this._scrollDown = (this._top > this._sectionTop) && (this._top < (this._sectionBottom))

      this._onChange(this._vw, this._sliderPercent === 0);
    }
  }


  private _onAnimate(shift: number) {
    const obj = this._obj;
    obj.last = obj.current;
    obj.move += (obj.new - obj.move) / 6;
    obj.current += (obj.new - obj.current) / 5;
    obj.speed = obj.last - obj.current;
    obj.smooth += obj.speed / 4;
    obj.smooth /= (1 + 0.05 * shift);
    obj.smooth = Math.max(obj.smooth, -100);
    obj.smooth = Math.min(obj.smooth, 100);
    obj.scale = obj.smooth / 100;

    if (obj.scale < 0.001 && obj.scale > -0.001) {
      this._update(0);
      this._isNeedUpdate = false;
      return;
    }
    this._isNeedUpdate = true;

    this._update(obj.scale);
  }

  private _update(scale: number) {
    if (this._scrollDown) {
      if (this._vw >= 1280 && this._isNeedUpdate) {
        if (this._sliderPercent > 0) {
          this._section.style.setProperty(`--slider`, `${this._sliderPercent * 100}%`);
          this._line.style.setProperty(`--opacity`, `${this._sliderPercent}`);
          this._section.style.setProperty('--deg', `${-scale * 10}deg`);
        } else {
          this._section.style.setProperty('--deg', `${-scale * 2}deg`);
        }
      }
    }
  }

  set onChange(func: OnChange) {
    this._onChange = func;
  }

  private _change(vw: number, showBtn: boolean) {
    this._onChange(vw, showBtn);
  }

  private _createLines() {
    this._lineWidth = this._oneElem.getBoundingClientRect().width;
    this._line.innerHTML = '';

    for (let i = 0; i < 3; i++) {
      const fragment = document.createDocumentFragment();
      let repeatTimes = Math.ceil(this._vw / this._lineWidth * 2) + 1;

      if (repeatTimes < 3) repeatTimes = 3;

      for (let i = 0; i < repeatTimes; i++) {
        fragment.appendChild(this._oneElem.cloneNode(true));
      }

      const newDiv = document.createElement('div');
      newDiv.appendChild(fragment);
      this._line.appendChild(newDiv);
    }

    //if (this._vw < 1280) {
    this._line.style.setProperty(`--duration`, `${this._vw / 50}s`);
    this._line.style.setProperty(`--offset`, `-${this._lineWidth}px`);
    // }
  }
}
