import React, {HTMLAttributes} from 'react';
import {DropdownLink} from 'TYPES/types';
import {classNames} from 'a-utils';
import css from './Droplist.module.scss'
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';

type Props = HTMLAttributes<HTMLUListElement> & {
  list?: DropdownLink[];
  isActive: boolean;
}

const DropList = ({className, list, isActive}: Props) => {

  return (
    <div className={classNames(className, css.drop, isActive && css.active)}>
      <div className={css.wrap}>
        {list?.map((item, index) => {
          return <SimpleLink key={`item-${index}`} href={item.link}><span>{item.text}</span></SimpleLink>
        })}
      </div>
    </div>
  );
};

export default DropList;
