import React from 'react';
import {SlideProps} from 'TYPES/types';
import Image from 'next/image';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import css from './Slide.module.scss'
import {classNames} from 'a-utils';
import {NewsCard} from 'COMPONENTS/pages-components/common/NewsCard/NewsCard';

export const Slide = ({title, description, image, hoverImg, date, link, parent }: SlideProps) => {

  return (
    <>
      {parent && parent === 'stuff' &&
        <div className={classNames(css.wrap, css.stuff)}>
          <div className={css.imageWrap}>
            {image.src && <Image
              src={image.src}
              alt={image.alt}
              width={image.width}
              height={image.height}
              priority={false}
              quality={90}
            />}
          </div>

          {title || description ?
            <div className={css.content}>
              {title && <h5 className={css.title}>{title}</h5>}
              {description && <p className={css.description}>{description}</p>}
            </div>
            :
            <></>
          }
        </div>
      }
      {parent && parent === 'games'  &&
        <SimpleLink href={link ? link : '#' } className={classNames( css.games)}>
          <div className={classNames(css.wrap, css.gamesWrap)}>
            <div className={css.gamesImage}>
              {image.src && <Image
                src={image.src}
                alt={image.alt}
                width={image.width}
                height={image.height}
                priority={false}
                quality={90}
              />}
            </div>
            <div className={css.content}>
              {title && <h5 className={css.title}>{title}</h5>}
              {description && <p className={css.description}>{description}</p>}
            </div>
          </div>
        </SimpleLink>
      }
      {parent && parent === 'screens' &&
        <div className={classNames(css.wrap, css.screens)}>
          {image.src && <Image className={classNames(hoverImg && css.imageMain)} src={image.src} alt={image.alt} width={image.width} height={image.height} priority={false}/>}
        </div>
      }

      {parent && parent === 'news' &&
        <NewsCard
          image={image}
          title={title}
          text={description}
          link={link}
          date={date}/>
      }
    </>
  );
};
