import React from 'react';
import css from './DetailedContacts.module.scss'
import {classNames} from 'a-utils';
import Icon from 'COMPONENTS/pages-components/common/Icon/Icon';
import {SimpleLink} from 'COMPONENTS/content/links/SimpleLink';
import Image, {ImageProps} from 'next/image';
import {JustLink} from 'TYPES/types';

export type Props = {
  avatar?: ImageProps,
  position?: string;
  email?: JustLink;
  button?: {
    link: string;
    text?: string;
    iconType: 'social-telegram'
  }
  anchor?: string;
}

export const DetailedContacts = ({avatar, position, email, button, anchor}: Props) => {


  return (
    <div id={anchor ? anchor : ''} className={css.block}>
      <div className={css.wrap}>
        {avatar && <div className={css.avatar}>
          {avatar.src && <Image
            priority={false}
            quality={90}
            src={avatar.src}
            alt={avatar.alt}
            width={avatar.width}
            height={avatar.height}
          />}
        </div>}
        <div className={css.info}>
          {position && <p className={css.position}>{position}</p>}
          {email && <SimpleLink href={email.link} className={css.mail}>{email.text}</SimpleLink>}
          {button &&
            <SimpleLink href={button.link} className={classNames(css.button, 'button-with-icon orange small')}>
              <span className={'button-text'}>{button.text}</span>
              <span className={'button-icon'}><Icon id={button.iconType}/></span>
            </SimpleLink>
          }
        </div>
      </div>
    </div>
  );
};
