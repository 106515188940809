import React, {useEffect, useState} from 'react';
import IMask, {AnyMaskedOptions, InputMask} from 'imask';
import {getMaskOptions, InputProps, T_InputType, T_MaskType} from './input-utils';


type MaskedInputProps = Omit<InputProps, 'onChange'> & {
  mask: T_MaskType;
  type: T_InputType;
  onChange: (value: string, isComplete: boolean) => void;
  onComplete: (isComplete: boolean) => void;
}

export const MaskedInput = ({onChange, onComplete, onBlur, value, mask, type, ...inputProps}: MaskedInputProps) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [maskInstanceRef, setMaskInstanceRef] = useState<InputMask<AnyMaskedOptions>>();


  useEffect(() => {
    if (!inputRef || !mask) return;

    const options = getMaskOptions(mask, type);
    const maskInstance = IMask(inputRef, options);
    maskInstance.on('complete', () => {
      onComplete(maskInstance.masked.isComplete);
    });
    maskInstance.on('accept', () => {
      const returnedValue = maskInstance.unmaskedValue;
      const isComplete = maskInstance.masked.isComplete;
      onChange(returnedValue, isComplete);
    });

    setMaskInstanceRef(maskInstance);

    return () => {
      maskInstance.destroy();
    }
  }, [inputRef, mask, onChange, onComplete, type]);

  useEffect(() => {
    if (!inputRef || !maskInstanceRef) return;

    if (maskInstanceRef.unmaskedValue !== value) {
      inputRef.value = value || '';
      maskInstanceRef.updateValue();
    }
  }, [value, maskInstanceRef, inputRef]);

  const onInputBlur = () => {
    if (maskInstanceRef) {
      // console.log('!!!blur', maskInstanceRef.masked.isComplete)
      onComplete(maskInstanceRef.masked.isComplete);
    }
    onBlur && onBlur();
  }

  return <input {...inputProps} ref={setInputRef} onBlur={onInputBlur} type='text'/>
}
