import React from 'react';
import {default as NextHead} from 'next/head';
import {MetaData} from 'COMPONENTS/content/meta/meta';

export type Props = {
  data?: MetaData;
}

export const MetaHead = ({data = []}: Props) => {
  let title = process.env.NEXT_PUBLIC_PAGE_TITLE;

  const metatags = data.map((tagData) => {
    if (tagData.type === 'title') {
      title = tagData.content;
      return;
    }
    const props = {
      [tagData.nameType || 'name']: tagData.name,
      content: tagData.content,
    }

    return <meta {...props} key={tagData.name}/>
  })

  return <NextHead>
    <title>{title}</title>

    <meta name="viewport" content="user-scalable=no, width=device-width, height=device-height, initial-scale=1"/>
    {metatags}
    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
    <link rel="manifest" href="/site.webmanifest"/>
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#F05125"/>
  </NextHead>
}
