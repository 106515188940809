import React, {useEffect, useState} from 'react';
import css from './SquirrelVideo.module.scss';
import useVideoFormat from 'HOOKS/useVideoFormat';
import {classNames} from 'a-utils';
import {JSInstance} from './JSInstance';


export type SquirrelVideoProps = {
	name: string;
  hide?: number;
  className?: string;
};

export const SquirrelVideo = ({ name, hide = 0, className  }: SquirrelVideoProps) => {
  const isNotWebp = useVideoFormat();
  const [instanceJS, setInstanceJS] = useState<JSInstance | null>(null);
  const [width, setWidth] = useState(320);

  useEffect(() => {
    const instance = new JSInstance();
    setInstanceJS(instance)
    setWidth(window.innerWidth)
    instance.init();

    return () => {
      instance.destroy();
    };
  }, []);


  useEffect(() => {
    if (!instanceJS) return;

    instanceJS.onChange = ((vw) => {
      setWidth(vw)
    });
  }, [instanceJS]);

  return (
    width > hide ?
      <video
        src={isNotWebp ? `/videos/${name}.mov` : `/videos/${name}.webm`}
        playsInline={true}
        className={classNames(css.video, className)}
        preload='metadata'
        autoPlay={true}
        loop={true}
        muted={true}
        poster={`/videos/${name}.png`}
      />
      :
      <></>
  );
};
